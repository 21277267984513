/* 11:49:06 */


/* ./src/less/root.less*/ 
html, body {
	height:100%;
	box-sizing: border-box;
}
body {
	padding:0;
	margin:0;
	.ff-text-mixin();
	background-color: fade(@clr-main-bg,15%);
}

/* ./src/less/elements/a.less*/ 
a {
	color:cadetblue;
}

a.like-button {
	display: inline-block;
	padding: 4px;
	> i {
		padding: 0 5px;
	}
	color: #444;
	border-radius: 4px;
	&:Hover {
		background-color: rgba(0,0,0,.1);
		color: cadetblue;
	}
	&.block {
		display: block;
	}
}
/* ./src/less/elements/app-reset.less*/ 
label { margin:0 }
ul { margin:0; padding:0; list-style: none; }
a {
	text-decoration:none;
}

div, section, body, html, form, ul, li, header, article, span, a, label, b, i, strong, textarea {
	box-sizing: border-box;
	&:before, &:after{
		box-sizing: border-box;
	}
}


textarea,
pre {
    -moz-tab-size : 2;
      -o-tab-size : 2;
         tab-size : 2;
}
/* ./src/less/elements/auth-href.less*/ 
[auth-href]:Hover {
	cursor: pointer;
}

/* ./src/less/elements/blinker.less*/ 
.blinker() {
	animation: blinker 1s linear infinite;
}
  
@keyframes blinker {
	50% {
		opacity: .5;
	}
}

/* ./src/less/elements/board-column.less*/ 
.board-column {
	background-color:rgba(0,0,0,0.1);
	border-radius:4px;
	> header {
		padding:4px;
		.ff-header();
	}


	&.bad-entries {
		background-color: rgba(139, 0, 0, 0.4);
	}

	&.board-column header + * {
		height:~"calc(100% + 60px)";
	}

}

/* ./src/less/elements/body.less*/ 
body {
	&.blur-not-last {
		> *:not(:last-child) {
			filter:blur(20px);
		}
	}
}

/* ./src/less/elements/checkbox-list.less*/ 
.checkbox-list {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	.checkbox-list-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		border-bottom: 2px dotted rgba(0,0,0,.1);
		opacity:.7;
		i {
			.icon-b('square-o');
			margin-right: .4em;
		}
		&:Hover {
			border-bottom-style: solid;
			opacity: 1;
		}
		&.active {
			opacity:1;
			border-bottom-style: solid;
			border-bottom-color: @clr-main-bg;
			i {
				.icon-b('check-square-o');
			}
			color: @clr-main-bg;
		}
	}
	&.small {
		.checkbox-list-item {
			i {
				font-size: 10px;
			}
			span {
				font-size: 10px;
				text-transform: lowercase;
			}
		}	
	}
}

/* ./src/less/elements/collection-controls.less*/ 
.properties-collection-controls {
	background-color: cornflowerblue;
	justify-content: flex-end;
	button {
		
		&.remove {
			background-color: #ccc;
			color: white;
			padding: 3px;
			> i {
				.icon('times');
			}
		}
		// &:not(:last-child) {
			// 	border-top-left-radius: 0;
			// }
			
	}
	.card > &.card-line:first-child {
		background-color: #efefef;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-top-right-radius: 3px;
		button {
			border-radius: 0;
			padding: 0 (@gutter / 2);

			&:last-child {
				border-top-right-radius: 3px;
			}
		}
	}
}

/* ./src/less/elements/common-buttons.less*/ 
button.remove-button i {
    .icon('remove');
    color: darkred;
}
/* ./src/less/elements/data-placeholder.less*/ 
[data-placeholder] {
	position: relative;
	&:before {
		content: attr(data-placeholder);
		position: absolute;
		display: inline-block;
		//width: 100%;
		bottom: 0px;
		padding-left: (@gutter / 2) + @border-size-input;
		z-index: 0;
		color:rgba(0,0,0,.5);
		.ff-system(10px,500);		
	}

	&.as-input {
		.as-input();
		&:before {
			line-height:initial;
			bottom:-2px;
			padding-left:0;
		}
	}

	> input:not([type="radio"]):not([type="checkbox"]) {
			width:100%;
	}

}
span[data-placeholder]{
	display:inline-block;
}

/* ./src/less/elements/data-scrollable.less*/ 
[data-scrollable]{
	height: ~"calc(100% + @{gutter})";
	margin: (-@gutter / 2);
	padding: (@gutter / 2);
	overflow:auto;
	.scrollable-mixin(@gutter / 2);
}

/* ./src/less/elements/directions.less*/ 

/* ./src/less/elements/dl.less*/ 
dl {
    margin:0;
    &.dl-in-a-row {
        display: flex;
        flex-direction: row;
        margin: auto;
        > * {
            text-align: center;
            padding:0 @gutter;
            &:not(:first-child) {
                border-left: 1px solid rgba(0,0,0,.1);
            }
        }
        dt {
            margin:0;
        }
        dd {
            margin:0;
        }
    }
}
/* ./src/less/elements/flex.less*/ 
.flex-column-auto {
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.overflow-auto {
    overflow: auto;
}
/* ./src/less/elements/form-container.less*/ 
.form-container{
	input:not([type="radio"]):not([type="checkbox"]){
		width:100%;
	}
	&.vertical{
		 > *:not(button) {
			 width:100%;
		 }
		 > * + * {
			 margin-top: (@gutter / 2);
		 }
	}
}

/* ./src/less/elements/help-links.less*/ 
.help-links{
	a {
		.ff-system();
		color: @clr-blue;
	}

	&.horizontal{
		a {
			display:inline-block;
			margin-right: @gutter;
		}
	}

}

/* ./src/less/elements/image-in-modal.less*/ 
.yat-modal-wrapper.image-in-modal {
	.yat-modal-content-wrapper {
		height:100%;
		max-width:unset;
		.yat-modal-box {
			width:100%;
			height:100%;
			max-width: unset;
			.yat-modal-content {
				width:100%;
				height:100%;
				padding:0;
				max-height: unset;
				.in-modal {
					width:100%;
					height:100%;
					display: flex;
					padding: @gutter;
					background-color: rgba(0,0,0,.1);					
					img {
						display: block;
						margin: auto;
						max-width:100%;
						max-height:100%;
					}
					a {
						position: absolute;
						bottom: 10px;
						left: 10px;
						display: inline-block;
						background-color: royalblue;
						padding: 2px 5px;
						color: white;
						border-radius: 3px;
					}
				}
			}
		}
	}
}

/* ./src/less/elements/input.less*/ 
input:not([type="radio"]):not([type="checkbox"]), input.as-input{
	.as-input();
}


.as-input(){
	display:inline-block;
	vertical-align: middle;
	font-size:20px;
	line-height: (@font-size-base * 1.4);
	margin:0;
	border: @border-size-input solid rgba(0,0,0,.2);
	border-radius: @border-radius-input;
	padding: (@gutter / 2);
	box-sizing: border-box;
	background-color:#fff;
	color:teal;
	height: 42px;
}

input[type=number] {
	&::-webkit-inner-spin-button, 
	&::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}	
}

select {
	.as-input();
}
/* ./src/less/elements/managed-table-view.less*/ 
.managed-table-view {
    
    .page-content &.full-screen {
        max-width: unset;
        width: calc(100% + 30px);
        height:calc(100% + 30px);
        margin-right: -15px;
        margin-top: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
    }


    display: flex;
    flex-direction: row;

    .manager {
        flex-grow: 0;
        flex-shrink: 0;
        width: 300px;
        height: 100%;
    }
    .entries {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        > :first-child {
            padding: 0;
            margin: 0;
            height: 100%;
            display:flex;
            flex-direction:column;
            overflow:auto;
        }
    }

}
/* ./src/less/elements/modals.less*/ 
[data-role="modal-wrapper"]{
	position:fixed;
	z-index:3;
}

.yat-preloader-wrapper{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(255,255,255,.8);
	.yat-preloader-content{
		position:absolute;
		top:35%;
		left:50%;
		color: rgba(0,0,0,.2);
	}
}


.yat-modal-wrapper{
	top:0;
	left:0;
	width:100%;
	height:100%;

	// display: flex;
	// flex-direction: column;
    // align-items: center;

	.yat-modal-box {
		position:relative;
	}
	.yat-modal-close {
		font-size: 24px;
		position:absolute;
		right:0;
		border-width:0;
		line-height: 0.5;
		width: 50px;
		height: 50px;
		border-bottom-right-radius: 0;
		border-top-left-radius: 0;
	}

	.yat-modal-bg{
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color:rgba(0,0,0,.4);
	}

	.yat-modal-content-wrapper {

		// flex-grow: 1;
		// flex-shrink: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		padding: @gutter;
		//margin:0 auto;
		position:relative;
		
		align-items: center;
		justify-content: center;

	}
	.yat-modal-box {
		
		.width-flex-max(800px);

		background-color:#fefefe;
		//max-height: ~"calc(100vh - 30px)";
		overflow: auto;
		box-shadow:0 0 20px 5px rgba(0, 0, 0, 0.2);
		border-radius:3px;

		// flex-grow: 1;
		// flex-shrink: 1;
		display: flex;
		flex-direction: column;


	}
	.yat-modal-content {
		//.card-mixin();
		//padding: 0 @gutter;
		//max-height: ~"calc(100vh - 130px)";
		overflow-y:auto;
		.scrollable-mixin();

		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;

		> :first-child:not(.edit-value-control):not(.yandex-map) {
			padding: 0 @gutter;
		}

	}
	.yat-modal-header{
		.ff-header();
		font-weight:500;
		font-size:24px;
		min-height: 50px;
		line-height: 1;

		padding: (@gutter / 2) 50px @gutter @gutter;

		flex-grow: 0;
		flex-shrink: 0;

		//padding-right:40px;
	}

	.yat-modal-actions {
		padding: (@gutter / 2);
		background-color:fade(slateblue, 10);
		.yat-modal-resolve {
			.button-color(@clr-success, #fff);
		}
	}

	&.confirm {
		.yat-modal-content {
			//padding: @gutter;
			.ff-header();
			font-weight:500;
			font-size:18px;
		}
		.yat-modal-actions{
			padding: @gutter;
			.yat-modal-resolve{
				background-color: @clr-success;
				color:#fff;
				&:Hover{
					background-color:darken(@clr-success,10);
				}
			}
		}
	}


	@media(max-width:800px), (max-height:800px){
		.yat-modal-header{
			font-size: 20px;
			padding-bottom: (@gutter / 2);
		}
		.yat-modal-content-wrapper {
			padding:0;
		}
		.yat-modal-content {
			max-height: unset;
		}
	};

}



.flex-next-modal-wrapper {

	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	padding: 30px;

	.yat-modal-bg {
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}

	.yat-modal-content-wrapper {
		// margin:auto;
		// max-height: 90%;
		// max-width: 90%;
		// overflow: auto;
	}
	.yat-modal-close {
		position: absolute;
		right: 0;
		top: 0;
	}

	.yat-modal-box {
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		//height: 100%;
	}

	.yat-modal-header {
		flex-grow: 0;
		flex-shrink: 0;
	}

	.yat-modal-content {
		flex-grow: 1;
		flex-shrink: 1;
		// display: flex;
		// flex-direction: column;
		// overflow: hidden;
	}

	.yat-modal-content > :first-child {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	

	.edit-value-control.in-modal {
		> :first-child {
			flex-grow: 1;
			flex-shrink: 1;
		}
		> .edit-actions {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}


}
/* ./src/less/elements/party-identifier.less*/ 
.party-identifier, .batch-identifier {
    background-color: cornsilk;
		color: black;
    font-family: monospace;
    white-space: pre;
}
/* ./src/less/elements/popover-info.less*/ 
/*
.popover-info{
	.card-mixin();
	.popover-color(#fed);
	padding:0;
	> *:first-child {
		min-width:32px;
		min-height:32px;
	}
}
*/

/* ./src/less/elements/popup-disclaimer.less*/ 
.with-popup-disclaimer {
    position: relative;
    &:Hover {
        .popup-disclaimer {
            display: block;
        }
    }
}
.popup-disclaimer {
    position: fixed;
    display:none;
    max-width: 300px;
    border-radius: 3px;
    background-color: white;
    border:1px solid rgba(0,0,0,.1);
    box-shadow: 0 0 5px 3px rgba(0,0,0,.5);
    padding: 5px;
    font-weight: 400;
    transform: translateY(15px);
    text-align: left;
}
/* ./src/less/elements/prefix-postfix.less*/ 
.value-postfix {
	margin-left: 5px;	
	.ff-small();
	font-size: 12px;
	font-weight: 400;
}
.value-prefix {
	margin-right: 5px;	
	.ff-small();
	font-size: 12px;
	font-weight: 400;
}
/* ./src/less/elements/provide-comment-control.less*/ 
.provide-comment-control {
	.comment-holder {
		padding: @gutter;
	}
}
/* ./src/less/elements/radio-check.less*/ 
.radio-check{
	input {
		display:none;
	}
	input[type=radio] ~ i {
		.icon('circle-thin');
	}
	input[type=radio]:checked ~ i {
		.icon('check');
	}
	input[type=checkbox] ~ i {
		.icon('square-o');
	}
	input[type=checkbox]:checked ~ i {
		.icon('check-square-o');
	}	
	span {
		.ff-header();
		font-weight:400;
	}
	&:Hover{
		cursor:pointer;
		border-bottom:1px dotted rgba(0,0,0,.4);
	}
}

.buttons-group{
	display: inline-block;	
	//border-color:rgba(0,0,0,.1);
	> div {
		display:flex;
		flex-wrap:wrap;
		> header {
			width:100%;
			.ff-header();
		}
	}
	//.button-border();
	.radio-check {
		.button();
		position:relative;
		background-color: transparent;
		border: none;
		//border-radius: 0;
		display: inline-block;
		padding: 0;
		margin-right:4px;
		margin-bottom:4px;
		z-index:1;
		&:Hover {
			z-index:2;
		}
		i {
			display:none;
		}
		span {
			display:block;
			padding: (@gutter / 4) @gutter;
			border:2px solid rgba(0,0,0,.2);
			background-color:#fff;			
			border-radius:4px;
			&:Hover{
				background-color:rgba(0,0,0,.1);
				z-index:2;
			}
		}
		input:checked ~ span {
			position:relative;
			background-color: @clr-blue;
			color: #fff;
			border:2px solid darken(@clr-blue,10);
			z-index:2;
		}


	}

	&.h41px {
		.radio-check span {			
			height:41px;
			line-height: (41px - (@gutter + 4px));
			display:flex;
			align-items:center;
		}
	}

	&.show-selected-icon .radio-check {
		border:none;
	}
	&.show-selected-icon .radio-check input:not(:checked) {
		& ~ i {
			display:none;
		}
	}
	&.show-selected-icon .radio-check input:checked {
		& ~ span {
			padding-left: (@gutter + 4px);
			padding-right: (@gutter - 4px);
		}
		& ~ i {
			.icon('check');
			position:absolute;
			display:inline-block;
			top: ~"calc(50% - 6px)";
			left: 4px;
			z-index:3;
			color:#fff;
		}

	}
}



.radio-label {
	cursor: pointer;
	input {
		cursor: pointer;
	}
	&:Hover {
		input:not(:checked) + span {
			color: darken(@clr-blue, 20);
			text-decoration: underline dotted;
		}
	}
	input:checked + span {
		color: @clr-blue;
	}
}
/* ./src/less/elements/table.less*/ 
table {
    td  {
        word-break: normal;
        vertical-align: top;
    }
}
/* ./src/less/elements/tree.less*/ 
.tree-container {
	position: relative;
	.tree-item {
		position: relative;
		> *:first-child{
			position:relative;
			z-index:1;
		}		
	}

	> .tree-item {

		.tree-item-children {
			position: relative;
			padding-left: 30px;
			.tree-item{
				&:after, &:before{
					content: "";
					position: absolute;
					left: -12px;
					z-index:0;
				}
				&:before{
					border-top: 2px solid #337ab7;
					top: 22px;
					width: 16px;
					height: 0;
				}
				&:after {
					border-left: 2px solid #337ab7;
					height: ~"calc(100% + 10px)";
					width: 0px;
					top: -18px;
				}
				&:last-child::after{
					height: 42px;
				}
			}
		}
	}
}

/* ./src/less/elements/ui-list.less*/ 
.ui-list-border-mixin(@c: rgba(0,0,0,.05), @w:1px) {
	@BRDCLR: @w solid @c;
	position: relative;
	border: @BRDCLR;
	> :not(.ui-list) {
		.ui-list-item-border-mixin(@c, @w);
	}
}

.ui-list-item-border-mixin(@c: rgba(0,0,0,.05), @w:1px){

	@BRDCLR: @w solid @c;
	position: relative;
	border-right: @BRDCLR;
	border-bottom: @BRDCLR;
	// top: @w;
	// left: @w;

	// &:not(:first-of-type){
	// 	border-top: @w solid @c;
	// }


	// &:first-child{
	// 	border-top:none;
	// }

	// &.half + .half{
	// 	border-left: @w solid @c;
	// }

	// &:not(.half):nth-child(2n + 1) + .half ~ .half:nth-child(2n){
	// 	border-left:none;
	// }
	// &:not(.half):nth-child(2n) + .half ~ .half:nth-child(2n + 1){
	// 	border-left:none;
	// }	




}

.ui-list-item-border2-mixin(){

	&:not(.half):nth-child(2n) + .half ~ .half:nth-of-type(2n) {
		 border-right:none;
	}

	&:not(.half):nth-child(2n-1) + .half ~ .half:nth-of-type(2n-1){
		border-right:none;
	}

	&:not(.half):nth-child(2n-1) + .half.half:nth-child(2n){
		border-right:1px solid rgba(0,0,0,.1);
	}
	&:not(.half):nth-child(2n) + .half.half:nth-child(2n+1){
		border-right:1px solid rgba(0,0,0,.1);
	}

	&.half{
		// border-left:none;
		border-right:1px solid rgba(0,0,0,.1);
	}
	&.half:first-child + .half{
		border-right:none;
	}



	border-top:1px solid rgba(0,0,0,.1);
	&:first-child {
		border-top:none;
		&.half + .half{
			border-top:none;
		}
	}


}
.ui-list-item-color(@cl, @hc: @cl){
	* {
		color: @cl
	}
	> div > span {
		color: @cl;
	}
	&:Hover{
		> div > span {
			color: @hc;
		}
	}
}
.ui-list-item-hover-before(){
	&.not-action:Hover{
		cursor: default;
		background-color:inherit;
	}
	&:not(.not-action):not(.disabled):Hover{
		cursor:pointer;
		> span {
			color:rgba(0,0,0,.9);
		}
	}
}
.ui-list-item-btn-mixin(){

	&:not(.disabled) > div, &:not(.disabled) > span {
		.ui-list-item-hover-before();
	}

}
.ui-list-item-mixin(){
	display:flex;
	position:relative;
	
	&:not(.auto):not(.half):not(.third){
		width:100%;
		> div, > span {
			overflow: unset;
			white-space: normal;
			> span {
				overflow: unset;
				white-space: normal;
			}
		}
	}

	> .fixed {
		flex-grow: 0;
		flex-shrink: 0;
		width: unset;
	}

	&.half{
		width:50%;
	}

	&.third{
		width:~"calc(100% / 3)";
	}

	> i {
		flex-grow:1;
		padding: (@gutter / 2) (@gutter / 3 * 2);
		color:rgba(0,0,0,.3);
		font-size:24px;	
		& + div, & + span {
			padding-left:3px;
		}		
	}

	> button {
		flex-grow:0;
		border-width:0;
		border-color:transparent;
		border-radius:0;
		padding:(@gutter / 2) (@gutter / 3 * 2);
		.ui-list-item-hover-before();

		i {
			font-weight:100;
		}

		&.checker {
			i {
				.icon-b('square-o');
				color:rgba(0,0,0,.2);
			}
		}

		&.selection {
			i {
				.icon-b('check-square-o');
				font-size:18px;
				color:rgba(0,0,0,.2);
			}
		}
		&.close {
			i {
				.icon-b('times');
				font-size:18px;
				color:rgba(0,0,0,.2);
			}
		}
		&.options {
			i {
				.icon-b('ellipsis-v');
				font-size:18px;
				color:rgba(0,0,0,.2);
			}
		}
		&.edit {
			i {
				.icon-b('pencil-square-o');
				font-size:18px;
				color:rgba(0,0,0,.2);
			}
		}
		&.info {
			i {
				.icon-b('info-circle');
				font-size:18px;
				color:rgba(0,100,255,.2);
			}
		}
		&.plus {
			i {
				.icon-b('plus');
				font-size:18px;
				color:#5cb85c;
			}
		}
		&.primary {
			i {
				.icon-b('hand-o-right');
				font-size:18px;
				color:#5cb85c;
			}
		}

		&.phone {
			i {
				.icon-b('phone');
				font-size:18px;
				color:rgba(0,100,255,.2);
				font-weight:100;
			}
		}
		&.mobilePhone {
			i {
				.icon-b('mobile');
				font-size:18px;
				color:rgba(0,100,255,.2);
				font-weight:100;
			}
		}
		&.email {
			i {
				.icon-b('envelope-o');
				font-size:18px;
				color:rgba(0,100,255,.2);
				font-weight:100;
			}
		}

		& + div, & + span {
			padding-left:3px;
		}
	}
	&.one-line {
		> div, > span {
			overflow-x: hidden!important;	
		}	
	}
	> div, > span {
		flex-grow:2;
		//position:relative;
		width:100%;
		display:flex;
		flex-flow: column;
		padding-left: (@gutter / 2);
		padding-right:3px;
		overflow-x: hidden;
		> small {
			//padding-bottom: 15px;
			display: block;
			position: relative;
			//top: -12px;
			//padding-bottom:5px;
			.ff-header();
			font-weight:400;
			color:#777;
			line-height:1;
			padding-bottom: 2px;
    	margin-top: -3px;
		}
		> span, > div {
			//display:inline-block;
			//vertical-align:middle;
			width:100%;
			display:block;
			padding:15px 0;
			line-height:1;
			overflow-x:hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			.ff-header();
			font-weight:700;
			color:rgba(0,0,0,.7);
			box-sizing: border-box;
		}
		> i, b{
			//position:absolute;
			font-style: normal;
			font-weight:normal;
			font-size:10px;
			//left: (@gutter / 2);
			.ff-header-mixin();
			//color:rgba(0,0,0,.8);
			font-weight:500;

			width:100%;
			
			> span.half {
				display:block;
				width:50%;
				float:left;
			}			
		}
		> i {
			min-height:12px;
			order:0;
			top:4px;
			color:rgba(0, 100, 200, 0.6);
			text-transform:uppercase;
		}
		> b {
			min-height:10px;
			order:10;
			bottom: 3px;
			color:rgba(0,0,0,.7);
		}

		> i ~ span, > i ~ div {
			margin-top:-12px;
		}
		> b ~ span, > b ~ div {
			margin-bottom:-12px;
		}
		> i + span + small, > i + div + small {
			margin-top:-12px;
		}
		> span:first-child + small, > div:first-child + small {
			margin-top:-14px;
		}
	}

	&.with-second-line {
		> div, > span {
			> span, > div {
				display: flex;
				flex-direction: column;
			}
		}
		.second-line {
			font-weight: 400;
		}
		dl.second-line {
			font-size: 14px;
			dd {
				font-weight: bold;
				color: #555;
			}
		}
		.first-line {
			padding-bottom: 10px;
		}
	}

	&.text-preserve-white-space.text-preserve-white-space.text-preserve-white-space.text-preserve-white-space.text-preserve-white-space {
		> div > span, > span > span {
			white-space: pre-wrap;
			word-break: break-word;
		}
	}

	&.text-column-2 {
		> div > span > *, > span > span > * {
			display:inline-block;
			width:50%;
			vertical-align: middle;
		}
	}

	.with-border & {
		.ui-list-item-border-mixin();
	}


	&.whole-btn, &.whole-btn-bg {
		//border:3px solid transparent;
		&:not(.disabled):Hover {
			//background-color: lighten(#6060d0,30%);
			//border-color:fade(#609060,70);
			// box-shadow: 0px 0px 0px 4px rgba(60,90,60, .3);
			// border-radius:3px!important;			
			> div, > span {
				cursor:pointer;
			}
		}
	}

}
.ui-list-mixin(){
	display:flex;
	flex-wrap:wrap;
	&.with-border {
		.ui-list-border-mixin();
		// > * {
		// 	.ui-list-item-border-mixin();
		// }
	}
	> * {
		.ui-list-item;
	}
	//padding:4px;
}
.ui-actionslist-mixin(){
	display:flex;
	flex-wrap:wrap;
	&.with-border {
		.ui-list-border-mixin();
		// > * {
		// 	.ui-list-item-border-mixin();
		// }
	}
	> * {
		.ui-list-action;
	}
}
.ui-list{
	.ui-list-mixin();
}

.ui-list-item {
	.cards & {
		padding:0;
	}
	// .with-border {
	// 	.ui-list-item-border-mixin();
	// }
	.ui-list-item-mixin();
	&.li-btn {
		.ui-list-item-btn-mixin();
	}
}
.ui-list-action {
	.ui-list-item-mixin();
	.ui-list-item-btn-mixin();
}

/* ./src/less/fontawesome.less*/ 
@import "~font-awesome/less/font-awesome.less";
@font-face {
	font-family: 'FontAwesome';
	src: url('@{fa-font-path}/fontawesome-webfont.eot?v=@{fa-version}');
	src: url('@{fa-font-path}/fontawesome-webfont.eot?#iefix&v=@{fa-version}') format('embedded-opentype'),
	  //url('@{fa-font-path}/fontawesome-webfont.woff2?v=@{fa-version}') format('woff2'),
	  //url('@{fa-font-path}/fontawesome-webfont.woff?v=@{fa-version}') format('woff'),
	  url('@{fa-font-path}/fontawesome-webfont.ttf?v=@{fa-version}') format('truetype'),
	  url('@{fa-font-path}/fontawesome-webfont.svg?v=@{fa-version}#fontawesomeregular') format('svg');
	// src: url('@{fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
	font-weight: normal;
	font-style: normal;
  }

// @fa-font-path:        "/assets/fonts";

.icon(@icon, @d:inline-block){
	.myfa-icon(@d);
	@var: "fa-var-@{icon}";
	&:before {
		content: @@var;
	}
}
.icon-a(@icon, @d:inline-block){
	.myfa-icon(@d);
	@var: "fa-var-@{icon}";
	&:after {
		content: @@var;
	}
}

.icon-add(@icon, @b:inline-block, @a: inline-block)
{
	@var: "fa-var-@{icon}";
	&:before{
		display: @b;
	}
	&:after {
		content: @@var;
		display: @a;
	}	
}


.icon-b(@icon){
	.icon(@icon,block);
}


.myfa-icon(@d:inline-block) {
	display: @d;
	font-family: FontAwesome;
	text-rendering: auto;
	text-decoration:none;
	font-style:normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;	
}

/* ./src/less/fonts.less*/ 

@ff-header: @fira-family, sans-serif;
@ff-text: sans-serif;
@ff-small: @play-family;

.ff-header-mixin(@w: 400) {
	font-family: @ff-header;
	font-weight: @w;
}
.ff-header-thin {
	.ff-header-mixin(200);
}
.ff-header-default {
	.ff-header-mixin(300);
}
.ff-header-regular {
	.ff-header-mixin(400);
}
.ff-header {
	.ff-header-mixin(500);
}
.ff-action {
	.ff-header-mixin(500);
	font-size: 10px;
	text-transform: uppercase;
}

/* chansges  22222222222 */


.ff-text-mixin(@fs: @font-size-base, @ff: @ff-text){
	font-size: @fs;
	font-family: @ff;
	
}

.ff-system(@fs: @font-size-base * .7, @fw: 400){
	.ff-header-mixin(@fw);
	font-size: @fs;
	text-transform:uppercase;
}

.ff-small()
{
	.ff-text-mixin(10px, @ff-small);
	font-weight: 700;
}

/* ./src/less/global-vars.less*/ 
@gutter: 15px;
@gutter2: (@gutter * 2);
@gutterhalf: (@gutter / 2);
@gutter-half: (@gutter / 2);

@border-radius:3px;
@border-radius-input: @border-radius;
@border-size-input: 2px;

@font-size-base: 16px;

@clr-main-bg:#337ab7;
@clr-main-back:#337ab7;
//@clr-main-back:#5cb85c; 
@clr-main-back-text: #fff;
@clr-blue: #337ab7;
@clr-link: @clr-blue;
@clr-light-yellow: #fffaf0;

@clr-success: #28a745;

@clr-bg-purchase: lighten(lightgreen, 15);
@clr-green: lighten(lightgreen, 20);

@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

@screen-phone-max: 		@screen-xs-max;
@screen-phone:          @screen-xs-min;

.hidden-for-mobile(){
	@media (max-width: @screen-phone-max) {
		display:none;
	}
}

.hidden-for-others(){
	@media (min-width: @screen-tablet) {
		display:none;
	}
}

.mobile-rule(@rule){
	@media(max-width: @screen-phone-max) {
		@rule();
	}
}
.others-rule(@rule){
	@media(min-width: @screen-tablet) {
		@rule();
	}
}

/* ./src/less/import-fonts.less*/ 
//@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:200,300,400,500,700|Play:400,700&subset=cyrillic-ext');

@font-path:        "/assets/fonts";
@font-path:        "../fonts";
@fira-path: '@{font-path}/FiraSansExtraCondensed';
@fira-family: 'Fira Sans Extra Condensed';

@play-path: '@{font-path}/Play';
@play-family: 'Play';

@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}


@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}


@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}


@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}



@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: @fira-family;
	src: url('@{fira-path}-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}


@font-face {
	font-family: @play-family;
	src: url('@{play-path}-Regular.ttf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: @play-family;
	src: url('@{play-path}-Bold.ttf') format('truetype');
	font-weight: bold;
}

/* ./src/less/layout.less*/ 
@page-breakpoint-max:1260px;
@content-width: 1030px;

.layout-container(@w: @content-width){
	max-width: @w;
	margin-left: auto;
	margin-right: auto;
}

/* ./src/less/mixins/border-radius.less*/ 
.border-radius(){
	border-radius: @border-radius;
}

/* ./src/less/mixins/buttons.less*/ 
.reset-button(){
	background-color:transparent;
	border-color:transparent;
	outline:none;
	&:focus, &:active {
		outline:none;
	}
}

.as-button-mixin(@c:rgba(0,0,0,.1)) {
	&[disabled], &.disabled {
		cursor: not-allowed;
	}
	&:not([disabled]):not(.disabled):Hover{
		cursor:pointer;
		background-color: @c;
	}
	&:focus{
		outline: none;
	}
	&[disabled]{
		opacity:.5;
		cursor:not-allowed,
	}
}

.as-button{
	.as-button-mixin();
}

.flat-button(){
	background-color:transparent;
	border:none;
	.as-button-mixin();
}

.button-color(@bg, @color, @border: darken(@bg, 10)){
	color: @color;
	background-color: @bg;
	border-color: @border;
	&:Hover{		
		background-color: darken(@bg, 10);
		border-color: darken(@border, 10);
	}
}
.button-border(){
	border-style: solid;
	border-width: @border-size-input;
	border-radius: @border-radius-input;
}
.button-mixin(){
	.button-border();
	.ff-system();
	font-weight: 600;
	padding: (@gutter / 2) @gutter;
	vertical-align: middle;
	&:not([disabled]):Hover{
		cursor:pointer;
	}
	&[disabled]{
		opacity:.5;
		cursor:not-allowed,
	}

	&.sign-plus {
		padding: 4px 10px;
		> i {
			.icon-b('plus');
		}
	}

	&.add-item {
		.button-color(@clr-success, white, darken(@clr-success, 10));	
	}
}

.button(@bg: #fff, @color: #444, @border: fade(#444,20)){
	.button-mixin();
	.button-color(@bg, @color, @border);
}

button {
	.reset-button();
	.button();
	line-height: (@font-size-base * 1.4);
	//.button();
}

/* ./src/less/mixins/clearfix.less*/ 
.clearfix() {
	&:before,
	&:after {
	  content: " "; // 1
	  display: table; // 2
	}
	&:after {
	  clear: both;
	}
  }

/* ./src/less/mixins/colors.less*/ 
.color-invert(@c){
	color:hsl(hue(@c), saturation(@c), 100 - lightness(@c));
}


.bg-main-dark.bg-main-dark.bg-main-dark.bg-main-dark {
	background-color: darken(@clr-main-back, 20%);
	color: @clr-main-back-text
}

.bg-light-yellow.bg-light-yellow.bg-light-yellow.bg-light-yellow.bg-light-yellow{
	background-color: @clr-light-yellow;
}

.bg-blue.bg-blue.bg-blue.bg-blue.bg-blue.bg-blue{
	background-color: @clr-main-bg;
	color:#fff;
}

/* ./src/less/mixins/expand.less*/ 
.expand-both-15{
	margin: -@gutter;	
}

/* ./src/less/mixins/flex-dynamic-columns.less*/ 
.flex-dynamic-column {
	padding:5px;
	display: flex;
	flex-flow:column;
	flex-wrap: wrap;
	height:100%;

	align-content: flex-start;
	overflow-x:auto;
	.scrollable-mixin();

	&.expand-both-15{
		height:~"calc(100% + 30px)";
	}


	> * {
		width:320px;

		&.whole-column {
			display:flex;
			//flex-flow: column;
			flex-direction: column;
			height:calc(100% - 15px);
			> .card.lined {
				margin: 0;
			}
		}

		&.max-whole-column {
			max-height:calc(100% - 15px);
		}

		&.card {
			display:flex;
			//flex-flow: column;
			flex-direction: column;

			header {
				height:35px;
				flex-grow:0;
				& + * {
					overflow:auto;
					max-height:~"calc(100vh - 156px)";
					height:100%;
					.scrollable-mixin();
					.search-control + * {
						max-height:~"calc(100vh - 226px)";
						overflow:auto;
						.scrollable-mixin();
					}
				}
			}
			> *:last-child{
				flex:grow;
			}
		}

	}

}

.page-content {
	> .fdc-container:first-child {
		max-width:unset!important;
		margin-left:-@gutter;
		margin-right:-@gutter;
		margin-bottom:-@gutter;
		margin-top:-@gutter;
		height:~"calc(100% + @{gutter2})";
		padding:0 @gutter;
		> *:first-child {
			.layout-container();
			padding-bottom: 0px!important;
		}

		.flex-dynamic-column
		{
			> * {
				min-width:300px;
				margin: (@gutter / 2)!important;
				&.minus-header-and-footer{
					height:100%;
					align-content: normal;
					> div.plain, > ul {
						height:~"calc(100% - 71px)";
					}
					> div.plain {
						overflow:hidden;
					}
				}
				&.double-width {
					.others-rule({
						width: 600px;
					})
				}
			}

			> .column-fullheight {
				height: 100%;

				header + .column-scrollable{
					max-height: ~"calc(100% - 60px)";
					overflow-y: auto;					
				}
				.column-scrollable{
					overflow-y: auto;
					max-height: ~"calc(100% - 15px)";
					.scrollable-mixin();
				}

				&.shrink-if-empty {
					min-width:unset;
					max-height:unset;
					height:auto;
					width:auto;
					//transform: rotate(90deg);
					> *:not(header) {
						display:none;
					}
					header {
						white-space:nowrap;
						color:rgba(0,0,0,.4);
					}
				}
				&.hide-if-empty{
					display:none;
				}
			}

		}

	}



}


/* ./src/less/mixins/flex-modals.less*/ 
.flex-modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	justify-content: center;
	align-items: center;

	padding: (@gutter / 2);

	.flex-modal-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #00000080;
		z-index:0;
	}
	.flex-modal-content-wrapper {
		// max-width: 800px;
		// width: 100%;
		// margin: 0 auto;
		z-index: 1;
		background-color: #fff;
		box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
		border-radius: 3px;
		// max-height: 90%;
		// flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		// justify-content: center;
		// margin: auto;


		.flex-modal-box {
			position: relative;
			// height: 100%;

			// flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.flex-modal-close {
				position: absolute;
				top: 0;
				right: 0;
				padding:0 5px;
				font-size: 32px;
				border: none;
				width: 40px;
				height: 40px;
				&:not(:hover) {
					background-color: transparent;
				}
				&:hover {
					background-color: #00000010;
				}
			}

			.flex-modal-header {
				padding: 5px @gutter;
				padding-right: 40px;
				height: 40px;
				.ff-header();
				color: #444;
				font-size: 24px;
				flex-grow: 0;
				flex-shrink: 0;
				background-color: rgba(51, 122, 183, 0.05);
				flex-grow: 0;
				flex-shrink: 0;
			}
			.flex-modal-actions {
				flex-grow: 0;
				flex-shrink: 0;
				padding: (@gutter / 2) @gutter;
				background-color: rgba(51, 122, 183, 0.05);
				.flex-modal-resolve {
					background-color: @clr-success;
					color: #fff;
					&:disabled {
						background-color: fade(lighten(@clr-success, 15), 40);
					}
				}
			}
			.flex-modal-content {

				// flex-grow: 1;
				flex-shrink: 1;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				> .in-modal {
					display: flex;
					flex-direction: column;
					flex-grow:1;
					flex-shrink:1;
					overflow: hidden;
					.tabs {
						flex-grow: 0;
						flex-shrink: 0;
						background-color: rgba(51, 122, 183, 0.05);
					}
					.content {
						flex-grow: 1;
						overflow-y: auto;
					}
				}
			}
			.common-tab-triggers {
				padding: 0 @gutter;
			}
			.tabs + .content {
				padding: @gutter;
			}
		}
	}

	.media-tablet-down({
		.flex-modal-content-wrapper {
			width: 100%;
			height: 100%;
		}
		.flex-modal-content-wrapper, .flex-modal-box, .flex-modal-content {
			flex-grow: 1;
		}
	});

	
	.media-tablet-up({
			.flex-modal-content-wrapper {
				min-width: 600px;
				min-height: 500px;
			}
			.flex-modal-content, .flex-modal-box {
				flex-grow: 1;
			}
		});	
	.media-desktop-up({
		.flex-modal-content-wrapper {
			min-width: 800px;
			min-height: 500px;
		}
	});	

}

/* ./src/less/mixins/hover.less*/ 
.hover(){
	.hover-dark();
}
.hover-dark(){
	.hover-color(rgba(0,0,0,.1));
}
.hover-light(){
	.hover-color(rgba(255,255,255,.1));
}
.hover-color(@bg, @c:inherit){
	&:Hover{
		cursor:pointer;
		background-color: @bg;
		color: @c;
	}
}

/* ./src/less/mixins/markdown-it.less*/ 
.markdown-it() {
	
	img {
		width: 100%;
	}

	ul {
		list-style: disc;
		padding-left: 13px;
		font-family: @play-family;
	}
	ol {
		margin:0;
		padding:0;
		padding-left: 18px;
		font-family: @play-family;
		> li::marker {
			color: red;
			font-style: normal;
			font-size: 80%;
			font-weight: bold;
		}
	}
	ul, ol {
		margin-bottom: 5px;
	}
	li {
		font-size: 90%;
		font-style: italic;
		color: #444466;		
	}
	blockquote {
		margin:0;
		padding: (@gutter / 2) (@gutter / 4);
		//padding-top: (@gutter / 4);
		margin-top: 5px;
		border-left: 6px solid slateblue;
		background-color: rgba(255,255,255,.7);
		//margin-left: -6px;
		& > *:last-child {
			margin-bottom: 0;
		}
	}
	pre {
		width: 100%;
		overflow-x: auto;
		box-sizing: border-box;
	}
	h1 {
		.ff-header();
		font-size: 19px;
		color: darken(teal, 10);
		margin: 4px;
		margin-left: @gutter;
		&:not(:first-child) {
			margin-top: @gutter;
		}
	}
	h2 {
		.ff-header();
		font-size: 16px;
		color: teal;
		margin: 4px;
		margin-left: @gutter;
	}
	h3 {
		.ff-header();
		font-size: 13px;
		color: lighten(teal, 5);
		margin: 4px;
		margin-left: @gutter;
	}
	h4, h5, h6 {
		.ff-header();
		font-size: 12px;
		color: lighten(teal, 10);
		margin: 4px;
		margin-left: @gutter;
	}

	p {
		margin:0;
		margin-bottom: 5px;
	}

	code {
		border-radius: 3px;
		padding: 2px;
		background-color: rgba(0,0,0,.1);
		color: crimson;
		line-height: 1;
		display: inline-block;
	}
	pre > code {
		display: block;
		padding: 4px;
		color: beige;
		background-color: #444;
	}
}

/* ./src/less/mixins/media.less*/ 
@device-width-desktop: 1200px;
@device-width-tablet: 800px;
@device-width-mobile: 600px;

.media-up(@width, @rule) {
    @media (min-width: @width) {
        @rule();
    }
}

.media-down(@width, @rule) {
    @media (max-width: @width) {
        @rule();
    }
}


.device-width() {
	@desktop: 1200px;
	@tablet: 800px;
	@mobile: 600px;
}

.media-properties-by-direction() {
	@up: min-width;
	@down: max-width;
}

.media-mixin(@prop, @width, @rule) {
  @query: ~"(@{prop}: @{width})";
  @media @query {
  @rule()
  }
}

.media-device(@device, @dir, @rule) {
	@width: .device-width()[@@device];
	@prop: .media-properties-by-direction()[@@dir];
	.media-mixin(@prop, @width, @rule);
}

.media-desktop-up(@rule) {
	.media-device(desktop, up, @rule);
}

.media-desktop-down(@rule) {
	.media-device(desktop, down, @rule);
}

.media-tablet-up(@rule) {
	.media-device(tablet, up, @rule);
}

.media-tablet-down(@rule) {
	.media-device(tablet, down, @rule);
}
/* ./src/less/mixins/offsets.less*/ 
.margin-mixin-l(@px: @gutter){
	margin-left: @px;
}
.margin-mixin-r(@px: @gutter){
	margin-right: @px;
}
.margin-mixin-t(@px: @gutter){
	margin-top: @px;
}
.margin-mixin-b(@px: @gutter){
	margin-bottom: @px;
}
.margin-mixin-h(@px: @gutter){
	.margin-mixin-l(@px);
	.margin-mixin-r(@px);
}
.margin-mixin-v(@px: @gutter){
	.margin-mixin-t(@px);
	.margin-mixin-b(@px);
}
.margin-l{ .margin-mixin-l(); }
.margin-r{ .margin-mixin-r(); }
.margin-b{ .margin-mixin-b(); }
.margin-t{ .margin-mixin-t(); }
.margin-v{ .margin-mixin-v(); }
.margin-h{ .margin-mixin-h(); }
.margin-vh { .margin-mixin-h(); .margin-mixin-v(); }
.margin-all{ .margin-mixin-h(); .margin-mixin-v(); }

.with-b-margin{
	> * {
		.margin-mixin-b();
	}
}



.padding-mixin-l(@px: @gutter){
	padding-left: @px;
}
.padding-mixin-r(@px: @gutter){
	padding-right: @px;
}
.padding-mixin-t(@px: @gutter){
	padding-top: @px;
}
.padding-mixin-b(@px: @gutter){
	padding-bottom: @px;
}
.padding-mixin-h(@px: @gutter){
	.padding-mixin-l(@px);
	.padding-mixin-r(@px);
}
.padding-mixin-v(@px: @gutter){
	.padding-mixin-t(@px);
	.padding-mixin-b(@px);
}
.padding-l{ .padding-mixin-l(); }
.padding-r{ .padding-mixin-r(); }
.padding-b{ .padding-mixin-b(); }
.padding-t{ .padding-mixin-t(); }
.padding-v{ .padding-mixin-v(); }
.padding-h{ .padding-mixin-h(); }
.padding-all, .padding-vh {
	.padding-mixin-h();
	.padding-mixin-v();
}

/* ./src/less/mixins/scrollable-mixin.less*/ 
.scrollable-mixin(@w:8px){


	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar{
		//left:-20px;
		height: @w;	
		width: @w;
		//background-color:transparent;
	} 
	&::-webkit-scrollbar-track{
		/*height:50%;
		width:4px;*/
		background-color:transparent;
	} 
	&::-webkit-scrollbar-thumb{
	//background-color:rgba(0,0,0,.3);
	background-color: @clr-main-back;
	border-radius:2px;
	width:5px;
	}
	&::-webkit-scrollbar-button:end{height: 8px; }
	&::-webkit-scrollbar-button:start{height: 8px; }

}

.scrollable {
	.scrollable-mixin();
	&.scroll-y {
		overflow-y: auto;
	}
}

/* ./src/less/mixins/sizes.less*/ 
.maxw-mixin(@w){
	max-width: @w;
}

.maxw600px.maxw600px.maxw600px{
	.maxw-mixin(600px);
}

.w100p{
	width:100%;
}

.width-flex-max(@mw: 600px){
	width:100%;
	max-width: @mw;
}

.h100p{
	height:100%;
}

/* ./src/less/mixins/tabs-triggers.less*/ 
.tabs-triggers-mixin(
	@triger-selector,
	@trigger-active-selector,
	@tab-bg-color: #fff,

	@tab-active-color: cornflowerblue,
	@tab-active-border-color: #ddd,
	@tab-active-bg-color: @tab-bg-color,
	
	@tab-passive-color: #aaa,
	@tab-passive-bg-color: transparent,
	@tab-passive-border-color: @tab-passive-bg-color,
	
	@tab-passive-hover-color: #444,
	@tab-passive-hover-bg-color: #f0f0f0,
	@tab-passive-hover-border-color: @tab-passive-hover-bg-color
) {
	position: relative;
	border-bottom: 2px solid @tab-active-border-color;

	@{triger-selector} {
		position: relative;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		display: inline-block;

		&:not(@{trigger-active-selector}) {
			bottom: -1px;
			border-bottom-width: 0;

			background-color: @tab-passive-bg-color;
			color: @tab-passive-color;
			border-color: @tab-passive-border-color;

			&:Hover {
				background-color: @tab-passive-hover-bg-color;
				border-color: @tab-passive-hover-border-color;
				color: @tab-passive-hover-color;
				cursor: pointer;
			}

		}

		&@{trigger-active-selector} {
			bottom: -2px;
			background-color: @tab-active-bg-color;
			border-color: @tab-active-border-color;
			color: @tab-active-color;

			border-bottom-color: @tab-bg-color;
		}
		
	}
}

/* ./src/less/mixins/text.less*/ 
.text-left{
	text-align:left;
}
.text-right{
	text-align:right;
}
.text-center {
	text-align:center;
}

/* ./src/js/app/layout/content.less*/ 
.page-content{
	//height:~"calc(100% - @{header-height})";
	height: 100%;
	padding: @gutter;
	padding-top: @header-height + @gutter;
	box-sizing: border-box;	
	.menu-region.on + & {
		padding-left: @menu-width + @gutter;
	}
	// > *:first-child{
	// 	height:100%; ////////
	// }

	> *:first-child {

		.layout-container();
		
	}

}

/* ./src/js/app/layout/header.less*/ 
@header-height:50px;
.page-header{
	font-size:0;
	display:flex;
	flex-direction: row;
	width:100%;
	overflow-x:hidden;
	height: @header-height;
	background-color: @clr-main-back;
	color: @clr-main-back-text;
	position:fixed;
	top:0;
	left:0;
	z-index:2;
	box-shadow:0px 0px 5px 5px rgba(0, 0, 0, 0.2);

	 * {
		color: @clr-main-back-text;
	 }

	> button {
		position:relative;
		font-size:16px;
		flex-grow: 1;
		width: @header-height;
		max-width: @header-height;
		.flat-button();
		border-right: 1px solid rgba(255,255,255,.1);
		border-left: 1px solid rgba(0,0,0,.1);		
		&.app-menu{
			i {
				font-size:24px;
				.icon-b('ellipsis-v');
			}
		} 
		&.page-back {
			i{
				.icon-b('chevron-left');
				
			}
		}
		&.notifications {
			i { 
				.icon-b('bell'); 
				font-size:24px;
			}
			b {
				position:absolute;
				font-size:10px;
				padding: 3px 4px;
				line-height: 10px;
				border-radius: 50%;
				right: 2px;
				bottom: 4px;
				background-color: #c44;
				color: #ffa;
			}
			&.no-badge b { display:none; }
		}
		&.user {
			i { 
				.icon-b('user-circle'); 
				font-size:24px;
			}
		}
		&.processes {
			i {
				.icon-b('random');
				font-weight:normal;
				font-size:24px;
			}
		}
		&.tasks {
			i {
				.icon-b('clock-o');
				font-weight:normal;
				font-size:24px;
			}
		}		
	}

	&.no-menu .app-menu {
		display:none!important;
	}

	&.no-back .page-back {
		display:none!important;
	}			

	&.no-notifications .notifications {
		display:none!important;
	}

	&.no-user .user {
		display:none!important;
	}


	.header{		
		flex-grow: 2;
		.ff-header();
		font-size:32px;
		text-transform: uppercase;
		white-space: nowrap;
		//width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		align-self: center;
		
		
		padding-left: @gutter;

		// @media (min-width: @page-breakpoint-max){
		// 	padding-left:0;
		// 	.layout-container();
		// }

	}
}

/* ./src/js/app/layout/menu.less*/ 
@menu-width:300px;

.region-item-header-mixin() {
	.ff-header();
	text-transform:uppercase;
	border-bottom: 1px solid rgba(255,255,255,.1);
	padding-bottom: 4px;				
	* {
		color:darken(@clr-light-yellow,15%);
		font-size:11px;

	}
}
.region-item-mixin() {
	header {
		.region-item-header-mixin();
	}
}


body > .menu-region{
	display:none;
	&.on {
		display: flex;
		flex-direction: column;
		top: @header-height;
		bottom:0;
		left:0;
		width: @menu-width;
		position:fixed;
		z-index:1;
		box-shadow:0px 6px 5px 5px rgba(0, 0, 0, 0.2);
			
		background-color: @clr-main-back;
		color: @clr-main-back-text;
		* {
			color: @clr-main-back-text;
			
		}

		*:not(.tab-content) {
			flex-grow: 0;
			flex-shrink: 0;
		}
		.tab-content {
			flex-grow: 1;
			flex-shrink:1;
			overflow-y: auto;
			padding: 10px;
			header {
				.region-item-header-mixin();
			}
		}
		.app-version {
			.ff-header();
			font-size:10px;
			font-weight:700;
			color:#fff;
			z-index:3;
			padding: 0 10px;
			background-color:darken(@clr-main-back, 20);
		}
	}

	ul {
		margin:0;
		padding:0;
		list-style:none;
		ul {
			padding-left:10px;
		}
	}

	a{
		text-decoration:none;
		.ff-header();
		display:block;
		line-height:32px;
	}

	li.active{
		> a {
			color:lightpink;
		}
	}

	.version {
		.ff-header();
		font-size:10px;
		font-weight:700;
		color:#fff;
		z-index:3;
		position:absolute;
		bottom:5px;
		left:5px;
	}

	.menu-switcher{
		font-size:0;
		background-color: rgba(0,0,0,0.4);
		box-shadow: 3px 0 8px 2px rgba(0,0,0,.2) inset;

		button {
			.flat-button();
			color:#fff;
			font-size:20px;
			.ff-header-mixin(100);
			//width:50%;
			display:inline-block;
			//background-color:rgba(0,0,0,.2);
			padding:5px @gutter;
			border-radius:0;
			i { color: rgba(255,255,255,.4);}
			&.menu i { .icon-b('share-alt'); }
			&.page i { .icon-b('file-o'); }
			&.favorites i { .icon-b('star'); }
			&:Hover {
				i {
					color:#fff;
				}
			}
			&.active {
				background-color: @clr-main-back;
				i { color:#fff; }
				&:Hover { background-color: @clr-main-back; }
			}
		}
	}



	.tree-region{
		display:none;
		ul > li {
			padding-left:10px;
			> ul {
				padding-left:0;
			}
		}
		.region-item-mixin();
		padding: @gutter;
		section {
			margin:0 -@gutter;
		}
	}

	.page-region{
		display:none;
		> * > * {
			padding: @gutter;
		}

		.region-item.not-empty ~ .region-item.not-empty{
			border-top:1px solid rgba(0,0,0,.1);
		}

		.region-item{
			.region-item-mixin();
		}


		ul > li {
			margin:0 -@gutter;
			padding:0;
			a {
				display:block;
				padding:5px @gutter;
				.hover-light();
				&:first-letter {
					text-transform: uppercase;
				}
			}
		}

		// .model-actions.not-empty + .page-children, .page-children.not-empty + .page-sibblings{
		// 	ul {
		// 		border-top:1px solid rgba(0,0,0,.1);
		// 	}
		// }

	}

	.favorites-region{
		display:none;
	}

	&.on[data-show="page"]{
		.page-region{ display:block; }
		.menu-switcher button.page {
			background-color: @clr-main-back;
			i { color:#fff; }
		}
	}

	&.on[data-show="favorites"]{
		.favorites-region{ display:block; }
		.menu-switcher button.favorites {
			background-color: @clr-main-back;
			i { color:#fff; }
		}
	}

	&.on[data-show="menu"]{
		.tree-region{ display:block; }
		.menu-switcher button.menu {
			background-color: @clr-main-back;
			i { color:#fff; }
		}
	}

	// &:not(.tree) .tree-region{
	// 	display:none;
	// }
	// &.tree .page-region{
	// 	display:none;
	// }

	.tab-content-inner {
		.division {
			margin-bottom: @gutter2;
			> header {
				margin-bottom: 5px;
			}
			.item {
				padding: 0px 4px;
				border-radius: 4px;
				&:Hover {
					background-color: #ffffff20;
				}
			}
		}
	}

}

/* ./src/js/base/modern/fetchable-container.less*/ 
.fetchable-container {
	display: flex;
	&:not(.horizontal) {
		flex-direction: column;
		overflow-y: auto;
	}
	flex-grow: 1;
	flex-shrink: 1;
}
/* ./src/js/base/print--preview.less*/ 
.print-preview {
	&.full-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: #ffffff;
		z-index: 3;
		.close.top-icon {
			position: absolute;
			top: 0;
			right: 0;
			> i {
				.icon('times');
			}
		}
		.controls-area {
			flex-grow: 0;
			flex-shrink: 0;
			height: 50px;
			padding: @gutter;
			border-top: 1px solid #00000010;
		}
		.printable-area {
			flex-grow: 1;
		}
	}
}

/* ./src/js/behaviors/foldable-sibblings.less*/ 
.foldable-sibblings {
	padding-bottom: @gutter;
	&:Hover {
		cursor: pointer;
	}

	&:not(.folded) ~ * {
		display: none;
	}
	&.with-caret {
		&:not(.folded) {
			> i {
				.icon('caret-right');
			}
		}
		&.folded > i {
			.icon('caret-down');
		}

	}
}

/* ./src/js/behaviors/popover.less*/ 
.popover-color(@bg:#fffaf0){
	background-color: @bg;
	&[x-placement^="bottom"] .popover-arrow { border-bottom-color: @bg; }
	&[x-placement^="top"] .popover-arrow { border-top-color: @bg; }
	&[x-placement^="left"] .popover-arrow { border-left-color: @bg; }
	&[x-placement^="right"] .popover-arrow { border-right-color: @bg; }
}
.popover-border-color(@border: darkseagreen, @bg:honeydew){
	background-color: @bg;
	border:1px solid @border;
	&[x-placement^="bottom"] .popover-arrow { border-bottom-color: @border; }
	&[x-placement^="top"] .popover-arrow { border-top-color: @border; }
	&[x-placement^="left"] .popover-arrow { border-left-color: @border; }
	&[x-placement^="right"] .popover-arrow { border-right-color: @border; }
}



.popover-wrapper.popover-wrapper.popover-wrapper.popover-wrapper.popover-wrapper.popover-wrapper.popover-wrapper.popover-wrapper{
	position:absolute;
	z-index:3;
	&[x-out-of-boundaries]{
		display:none;
	}
	.popover-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
	}
	

	&[x-placement^="bottom"] {
		margin-top: 5px;
		.popover-arrow {
			border-width: 0 5px 5px 5px;
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="top"] {
	  margin-bottom: 5px;
	  .popover-arrow {
		border-width: 5px 5px 0 5px;
		border-bottom-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
		bottom: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	  }
	}

	&[x-placement^="right"] {
	  margin-left: 5px;
	  .popover-arrow {
		border-width: 5px 5px 5px 0;
		border-top-color: transparent;
		border-left-color: transparent;
		border-bottom-color: transparent;
		left: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	  }
	}
	&[x-placement^="left"] {
	  margin-right: 5px;
	  .popover-arrow {
		border-width: 5px 0 5px 5px;
		border-top-color: transparent;
		border-bottom-color: transparent;
		border-right-color: transparent;
		right: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	  }
	}

	&.popover-info {
		//.popover-border-color(#f4f4ff);
		.card-mixin();
		padding:0;
		.popover-border-color();
		width:300px;
		> div:first-child {
			padding:5px;
		}
		.as-button.action {
			background-color:fade(darken(honeydew,20%),20);
			&:Hover{
				background-color:fade(darken(honeydew,20%),40);
			}
		}
	}

	&.max-width-400px {
		max-width: 400px;
	}

	&.popover-common {
		.popover-color(lighten(orange, 30));
		border-radius: 3px;
		box-shadow:0 0 5px 2px rgba(0,0,0,.1);
		> div {
			//color: white;
		}
	}

}

/* ./src/js/components/.ui-contacts/contact-person.less*/ 
.contact-person{
	> i { 
		.icon('user');

	}
}

/* ./src/js/components/.ui-contacts/popover-contact-values.less*/ 
.popover-info.popover-contact-values{
	> ul {
		flex-flow:column;
	}
}

/* ./src/js/components/actions/popover-actions.less*/ 
.popover-actions.popover-actions.popover-actions.popover-actions.popover-actions.popover-actions.popover-actions{
	.card-mixin();
	.popover-color(@clr-main-back);
	max-width:250px;
	padding:0;
	> .actions {
		> header {
			padding: 7.5px;
			color:#fed;
			.ff-header();
			font-size: 14px;
			font-weight: 400;
			//text-transform: uppercase;
		}
		.ui-actionslist-mixin();
		padding: 7.5px 0;
		> * {
			.ui-list-item-color(#fff, #fed);
			> div {
				padding-right: (@gutter / 2);
				> span {
					padding:5px 0;
					min-width:100px;
					font-weight:400;
				}
			}
		}
	}
}

/* ./src/js/components/batch-transfer-log/transaction-log-entry.less*/ 
.transaction-log-entry {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	.ui2-atom {
		> span {
			.ff-header();
		}
	}

	&.ui2-block.with-wrapper {
		.wrapper {
			box-shadow: 0 0 5px 2px #00000010;
			border: 1px solid #00000020;
			flex-wrap: nowrap;
		}
	}

	.ui2-molecule {
		flex-wrap: nowrap;
		align-items: center;
		.table-cell {
			&.created, &.arrow, &.source, &.destination, &.actor, &.amount  {
				flex-grow: 0;
				flex-shrink: 0;
			}
			&.product {
				width: 300px;
				> span {
					color: darkslateblue;
				}
			}
			&.amount {
				width: 200px;
				text-align:center;
				> span {
					//text-align:right;
					font-size: 18px;
				}
				// > i, 
				// > b {
				// 	color: #777;
				// }
			}
			&.source-destination {
				width: 350px;
				// flex-shrink: 0;
				.fa.fa-arrow-right {
					margin: 4px;
				}
				.store {
					color: brown;
				}
				.contragent {
					color: darkolivegreen;
				}
				> small {
					color: tomato;
					font-size: 16px;
					font-style: italic;
				}

			}
			&:not(.fixed) {
				min-width: 200px;
			}
			&.actor {
				width: 150px;
				min-width: unset
			}

		}

	}

	.type-icon {
		flex-grow: 0;
		flex-shrink: 0;
		i {
			display: block;
			font-style: normal;
			font-size: 18px;
			color: #aaa;
			text-align:center;
			width: 30px;
		}
	}

	@clr-in: seagreen;
	@clr-out: cornflowerblue;

	&.with-wrapper {
		&.purchaseAccept {
			.wrapper {
				background-color: lighten(greenyellow, 38);
			}
			.type-icon i {
				.icon('truck');
				color: @clr-in;
			}
		}
	
		&.produced {
			.wrapper {
				background-color: lighten(#906090, 35);
			}
			.type-icon i {
				.icon('wrench');
				color: #906090;
				color: @clr-in;
			}
		}
	
		&.defect {
			.wrapper {
				background-color: lighten(tomato, 28);
			}
			.type-icon i {
				.icon('exclamation-triangle');
			}
		}
	
		&.issueForProduction {
			.wrapper {
				background-color: white;
			}	
			.type-icon i {
				.icon('sign-out');
				color: @clr-out;
			}
		}
	
		&.revokeFromProduction {
			
			.wrapper {
				background-color: lighten(cornsilk, 3);
			}	
			.type-icon i {
				.icon('sign-in');
				transform: rotate(180deg);
				color: @clr-in;
			}
		}
		
		&.usedForProduction {
			.wrapper {
				background-color: lighten(#906090, 48);
			}		
			.type-icon i {
				.icon('cogs');
			}
		}
		&.manualCorrection {
			.wrapper {
				background-color: darksalmon;
			}
			.type-icon i {
				.icon('magic');
				color: white;
			}
			&.increment {
				.type-icon i {
				color: @clr-in;
				}
			}
			&.decrement {
				.type-icon i {
				color: @clr-out;
				}
			}
		}
	}


}


.printable-table.full-screen {
	.transaction-log-entry {
		
		.ui2-molecule {
			.table-cell {

					width: unset;
					min-width: unset;

					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: 20%;

					&.created, &.actor, &.amount {
						flex-basis: 5%;
					}

					&.amount.amount.amount {
						b { display: none; }
					}
			}
		}





	}
}
/* ./src/js/components/board/board.less*/ 
@keyframes BLINK-BG {
	50% {
		background-color: transparent;
	}
}


@board-list-width: 300px;
@board-border-radius: 5px;
@board-list-gutter: 5px;
.board {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	height: 100%;
	overflow-x: auto;
	position: relative;

	> .board-list {
		touch-action: none;
		display: inline-block;
		vertical-align: top;
		width: @board-list-width;
		height: 100%;
		padding: (@gutter / 2);

		.fdc-container:first-child > .flex-dynamic-column& {
			margin:0!important;
			min-width: unset;
		}

		&.swapping {
			.board-list-content {
				box-shadow:0 0 0px 3px lightseagreen;
				opacity: .6;
			}
		}

		button.add-column {
			background-color: rgba(0,0,0,.1);
			border-radius: @board-border-radius;
			width: @board-list-width;
			border-color: transparent;
			&:Hover {
				background-color: rgba(0,0,0,.2);
			}
		}

		.board-list-content {
			border-radius: @board-border-radius;
			height: 100%;
			> section {
				background-color: rgba(0,0,0,.1);
				padding: 5px;
				max-height: ~"calc(100% - 50px)";
				overflow: auto;
				.scrollable-mixin();
			}
			> header {
				user-select: none;
				//padding: 0 @gutter;
				background-color: rgba(0,0,0,.1);
				border-top-left-radius: @board-border-radius;
				border-top-right-radius: @board-border-radius;
				.ff-header();
				b, i {
					font-weight: normal;
					font-style: normal;
					width: 32px;
					text-align:center;
				}

				display: table;
				> * {
					display:table-cell;
					height: 24px;
					line-height: 24px;
				}
				> span {
					width: 100%;
				}

				.actions {
					i {
						.icon-b('ellipsis-v')
					}
				}
				.sortable-anchor {
					touch-action: none;
					-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
					i {
						.icon-b('arrows-h')
					}
				}
				> b {
					&:Hover {
						cursor: pointer;
						background-color: rgba(0,0,0,.1);
					}
					&:first-child {
						border-top-left-radius: @board-border-radius;
					}
					&:last-child {
						border-top-right-radius: @board-border-radius;
					}
				}
			}
			> footer {
				padding: 0 @gutter;
				background-color: rgba(0,0,0,.1);
				border-bottom-left-radius: @board-border-radius;
				border-bottom-right-radius: @board-border-radius;
				.ff-header();
				height: 24px;
				line-height: 24px;
				&.as-button:Hover {
					cursor: pointer;
					background-color: rgba(0,0,0,.2);
				}				
			}

		}
	}


	.board-list-items {
		&.ready-for-drop {
			//background-color: rgba(200,0,0,.3);
			position:relative;
			&:before {
				content: '';
				border-radius: 5px;
				height: 5px;
				background-color: lightseagreen;
				display: block;
				top: -5px;
				position: absolute;
				width: 100%;
				left: 0px;	
				animation: BLINK-BG .3s infinite;
			}
		}
		&:not(.ready-for-drop).drop-available {
			//animation: BLINK-BG 2s infinite;
		}
		.empty-item {
			//margin: 5px;
			height:75px;
			padding:5px;
			color: rgba(0,0,0,.3);
		}
	}


	.board-list-item {
		touch-action: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		//margin: 5px;
		//border-radius: @board-border-radius;
		//background-color: #f0f0f0;
		//box-shadow: 0 0 3px 1px rgba(0,0,0,.1);
		//padding:5px;
		user-select: none;
		min-width: unset!important;
		& + * {
			margin-top: 5px;
		}
		&.swapping {
			//background-color: beige;
			//box-shadow: 0 0 3px 1px rgba(100,0,0,.4);
			opacity:.7;
		}
		&.ghost {
			opacity:1;			
			transform: scale(.90) rotate(2deg);
		}

		&.ready-for-drop {
			//background-color: ;
			position:relative;
			&:before {
				content: '';
				border-radius: 5px;
				height: 5px;
				background-color: lightseagreen;
				display: block;
				top: -5px;
				position: absolute;
				width: 100%;
				left: 0px;	
				animation: BLINK-BG .3s infinite;
			}
		}
		// &:not(.ready-for-drop).drop-available {
		// 	animation: BLINK-BG 2s infinite;
		// }		
	}


}

/* ./src/js/components/cards/card-mixin.less*/ 
.card-shadow-mixin(){
	box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
	&.shadowless-top {
		box-shadow: 0px 20px 10px 5px rgba(0,0,0,.05);
		&.transparent-header {
			box-shadow: 0px 40px 10px 5px rgba(0,0,0,.05);
		}
	}
}

.card-border-mixin(){
	border-radius:3px;

	> *:not(header):first-child {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	> *:not(header):last-child {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	
	> .card-line:first-child{
		> *:first-child {
			border-top-left-radius: 3px;
		}
		> *:last-child {
			border-top-right-radius: 3px;
		}
	}
	> .card-line:last-child{
		> *:first-child {
			border-bottom-left-radius: 3px;
		}
		> *:last-child {
			border-bottom-right-radius: 3px;
		}
	}

	li button {
		border-radius:0;
	}

}

.card-mixin(@bg: #fff){
	align-content: flex-start;
	button:not(.not-action) {
		&:Hover{
			background-color:lightblue;
			color:#fff!important;
			* {
				color:#fff!important;
			}
		}
		&.danger:Hover{
			background-color:indianred;
		}
	}

	* {
		background-color:transparent;
	}



	.card-border-mixin();

	.card-shadow-mixin();
	padding: @gutter;

	background-color: @bg;
	
	> header, .card-header {
		.ff-header;
		margin: -@gutter;
		padding: (@gutter / 2) @gutter;
		margin-bottom: (@gutter / 2);
		height:35px!important;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}	


	> ul, > .plain > ul {
		.ui-list-mixin();
	}
	.plain {
		display:block;
		width:100%;
	}

	&:not(.whole-btn) .whole-btn:not(.ui-list-item) {
		&:Hover {
			box-shadow: 0px 0px 0px 4px rgba(60,90,60, .3);
			// border-radius:3px!important;			
		}
	}

	&.whole-btn {
		&:Hover {
			box-shadow: 0px 0px 0px 4px rgba(60,90,60, .3);
			cursor:pointer;
		}
	}

	.whole-btn.ui-list-item, .whole-btn-bg {
		&:Hover {
			border-color:transparent;
			background-color:rgba(60,90,60, .1);
		}
	}	


	&.full-column.full-column {
		display: flex;
		flex-direction: column;
		height: 100%;

		> header, .card-header, > footer, .card-footer {
			flex-grow: 0;
			flex-shrink: 0;
		}

		> button, > .as-button {
			flex-grow: 0;
			flex-shrink: 0;
		}

		> header + * {
			max-height: unset;
		}

		> :not(header):not(footer):not(button):not(.as-button) {
			flex-grow: 1;
			flex-shrink: 1;
		}

	}

}

/* ./src/js/components/cards/card.less*/ 
.card {
	.card-mixin();
}

/* ./src/js/components/cards/cards.less*/ 
.cards {
	> * {
		.card-mixin();
	}
}

/* ./src/js/components/comments/comment.less*/ 
.comment-color(@bg:#fffaf0, @size: 5px){
	background-color: @bg;
	margin-top: @size;
	.arrow { 
		top: -@size;
		border-bottom-color: @bg; 
		border-width: 0 @size @size @size;
	}
}
.comment-border-color(@border: darkseagreen, @bg:honeydew, @size: 5px){
	background-color: @bg;
	border:1px solid @border;
	margin-top: @size;
	.arrow { 
		top: -(@size + 1);
		border-bottom-color: @border; 
		border-width: 0 @size @size @size;
	}
}


.comment{
	//margin-bottom: @gutter;
	.authored {
		display:flex;
		align-items: center;
		.two-line-date{
			> big {
				padding-bottom:0;
			}
			> small {
				padding-top:0;
			}
		}
	}

	.comment-text {
		position:relative;
		margin-left: 5px;
		margin-right: 5px;
		border-radius: 4px;


		.arrow {
			position:absolute;
			border-style: solid;
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			//left: 60px;
			margin-top: 0;
			margin-bottom: 0;
		}

		.text {
			padding: @gutter (@gutter / 2);
			font-size: 12px;
			white-space: pre-wrap;
		}

	}

	&.my {
		.comment-text{ 
			.comment-color(fade(darkseagreen,15), 7px);
			.arrow {
				left:65px;
			}
		}
		.authored {
			justify-content: flex-start;
		}
	}
	&:not(.my){
		.comment-text{ 
			.comment-color(fade(cornflowerblue,15), 7px);
			.arrow {
				right:65px;
			}
		}
		.authored {
			flex-flow: row-reverse;
			min-height:26px;
		}
	}

}

/* ./src/js/components/comments/nested-comments.less*/ 
.nested-comments{
	background-color:white;
	border-radius: 4px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	> header {
		.ff-header();
		font-size:12px;
		text-transform:uppercase;
		color: @clr-link;
		border-bottom:1px solid fade(darken(@clr-light-yellow,10),60);
		padding: @gutter (@gutter / 2);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		&:not(:hover){
			background-color: fade(@clr-light-yellow,60%);
		}
		.as-button();
	}
	> .column-scrollable {
		padding: @gutter 0;
	}

	.empty-view {
		padding: @gutter;
		.ff-header();
		font-weight:300;
		background:transparent;
		border:none;
		padding-top:0;
	}
}

/* ./src/js/components/contacts/views/person-in-list.less*/ 
.person-in-list {
	> div, > span {
		> span {
			margin-top:5px;
		}
		> small {
			font-weight:700!important;
			font-size:18px;
		}
	}
}

/* ./src/js/components/content-tabs/common-tab-triggers.less*/ 
.common-tab-triggers {
	.tabs-triggers-mixin(~".tab", ~".active");
	.tab {
		padding: (@gutter / 2);
		border: 2px solid #ddd;
		border-radius: 4px;
	}
}

/* ./src/js/components/controls/boolean-select/boolean-select.less*/ 
.boolean-select{
	//background-color:rgba(0,0,0,.7);
	border-radius:4px;
	width:100%;
	font-size:0;
	input {
		display:none;
	}
	label {
		display:inline-block;
		font-size:0;
		width:50%;
		padding:3px;
	}
	input + span {
		font-size:16px;
		display:inline-block;
		background-color:transparent;
		padding: (@gutter - 3px);
		width:100%;
		text-align:center;
		.ff-header();
		font-weight:500;
		border-radius:4px;
		&:Hover {
			background-color:rgba(0,120,0,.1);
			cursor:pointer;
		}
	}
	input:checked + span {
		background-color:rgba(0,120,0,.3);
	}
}
.edit-value-control .boolean-select {
	//margin-bottom: @gutter;
	padding: @gutter;
}

/* ./src/js/components/controls/contact-value/c-contact-value.less*/ 
.address-input {
	> div {
		display: flex;
		input {
			flex-grow: 1;
		}
		.map-icon {
			padding: 3px;
			padding-top: 5px;
			> i {
				.icon('globe');
				font-size: 27px;
				line-height: 27px;
			}
		}
		.contragent-icon {
			.icon('user-secret');
			font-size: 27px;
			line-height: 27px;
		}
	}
}
.c-contact-value {
	.in-modal > & {
		padding: @gutter;
	}
	.c-cv-line {
		display:flex;
		> * {
			flex-grow:1;
			margin:2px;
			&:first-child{
				margin-left:0;
			}
			&:last-child{
				margin-right:0;
			}
		}
	}

	.c-cv-primary{

		flex-basis:60px;
		flex-grow:0;
		


		[data-placeholder]{
			width:100%;
			&:before{
				padding:0;
				text-align:center;
				width:100%;
				left:0;				
			}
			input {
				margin:0;
			}
			&:hover{
				cursor:pointer;
				* {
					cursor:pointer!important;
				}
				background-color:rgba(0,0,0,.1)!important;
			}			
		}
	}

	input {
		width:100%;
	}

	&:not(.phone) {
		.c-cv-add{
			display:none;
		}
	}

}

/* ./src/js/components/controls/contact-value/phone/c-phone.less*/ 
.c-phone{
	display:flex;

	.c-phone-code, .c-phone-country, .c-phone-number{
		&:before{
			padding:0;
			text-align:center;
			width:100%;
		}
		input {
			border-left:none;
			border-right:none;
			border-radius:0;
			text-align:center;
		}
	}
	
	.c-phone-country{
		flex-grow:1;
		flex-basis:3em;
		input {
			text-align:center;
			border-bottom-left-radius: 3px;
			border-top-left-radius: 3px;
			border-left: 2px solid rgba(0,0,0,.2);
			//border-right:none;
		}
	}
	.c-phone-code{
		flex-grow:1;
		flex-basis:4em;
	}
	
	// .c-phone-add{
	// 	flex-grow:1;
	// 	flex-basis:5em;

	// }
	.c-phone-number{
		flex-grow:2;
		//flex-grow:3;
		input {
			text-align:center;
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
			border-right: 2px solid rgba(0,0,0,.2);
			//border-right:none;
		}		
	}

	input[type=number] {
		width:100%;
	}
}

/* ./src/js/components/controls/datetime/datetime.less*/ 
.c-datetime {

	@w-seven: (100% / 7);
	@w-seven2: @w-seven * 2;
	@w-seven3: @w-seven * 3;

	@w-third: (100% / 3);

	.flex-cnt(@c: @w-seven){
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		> * {
			.flex-item(@c);
		}
	}

	.flex-item(@w: @w-seven){
		flex-grow: 0;
		text-align: center;
		padding:2px;
		height: 40px;
		line-height:32px;
		flex-basis: @w;
		border:2px solid transparent;
	}

	.dt-button(){
		&:Hover{
			cursor:pointer;
			border-radius:4px;
			border-color:rgba(0,0,0,.1);
			background-color:transparent;
		}
	}


	.c-dt-month-days, .c-dt-month-weekdays {
		.flex-cnt();
	}

	.c-dt-years {
		.flex-cnt(20%);
	}

	.c-dt-months {
		.flex-cnt(@w-third);
	}

	.c-dt-controls{

		.flex-cnt();


		.c-dt-go-right > i { .icon('chevron-right'); }
		.c-dt-go-left > i { .icon('chevron-left'); }

		.c-dt-c-year {
			flex-basis: @w-seven2;
			.dt-button();
		}
		.c-dt-c-month {
			flex-basis: @w-seven3;
			.dt-button();
		}
		.c-dt-c-years{
			flex-grow:1;
			flex-basis:auto;
		}
		button {
			.dt-button();
			> i {
				font-style: normal;
			}
		}		
	}

	.c-dt-years-selection {
		.c-dt-controls {
			button {
				flex-basis: 20%;
			}
			.c-dt-years {
				flex-basis: 60%;
				text-align: center;
			}
		}
	}

	.c-dt-month-weekdays {
		> b {
			font-size:8px;
			text-transform: uppercase;
			font-weight:700;
			color:rgba(0,0,0,.7);
		}
	}

	// .c-dt-years{
	// 	> * {
	// 		flex-basis: 20%;
	// 	}
	// }

	.c-dt-months{
		> * {
			flex-basis: 100% / 3;
		}		
	}


	.c-dt-month-days, .c-dt-years, .c-dt-months {
		> * {
			.dt-button();
		}
	}

	.current {
		font-weight:900;
		color:royalblue;
	}
	.c-dt-day {
		&:not(.current){
			&.holiday {
				color: tomato;
				&.out-of-month{
					color: fade(tomato, 25);
				}
			}
			&.out-of-month {
				color: rgba(0,0,0,.25);
			}
		}
		&.selected {
			background-color:royalblue;
			&.out-of-month {
				background-color:fade(royalblue,40);
			}
			color:#fff;
			&.t.l{
				border-top-left-radius:4px;
			}
			&.t.r{
				border-top-right-radius:4px;
			}
			&.b.r{
				border-bottom-right-radius:4px;
			}
			&.b.l{
				border-bottom-left-radius:4px;
			}

		}
		
	}

	.c-dt-selected {
		display:flex;
		align-items:center;
		
		flex-wrap:wrap;
		.label{
			flex-basis:100%;
			text-align:center;
		}


		//margin:0 -5px;
		padding: @gutter 5px;
		background-color:fade(tomato, 10);
		font-size:20px;
		.ff-header();
		font-weight:400;

		input {
			background-color:transparent!important;
			padding:0!important;
			width:30px;	
			font-size:20px;
			.ff-header();
			font-weight:400;
		}

		.c-time {
			padding-right: (@gutter / 2);
			i {
				color:rgba(0,0,0,.2);			
			}
		}

		span.empty{
			line-height:62px;
			display:inline-block;
		}

		& .from, & .to {
			flex-grow:1;
			flex-basis:50%;
			text-align:center;
			min-height:62px;
		}
		.value {
			margin: 0 auto;
		}
		.c-dt-selected-date{
			display:flex;
			align-items:center;
			justify-content: center;
			&.single-value{
				margin: 0 auto;
			}

			button.clear {
				border-color:transparent;
				background-color:transparent;
				padding: (@gutter / 2);
				margin-left:4px;
				font-size:24px;
				color:tomato;
				&:focus, &:active{
					outline:none;
				}
				&:Hover{
					border-radius:4px;
					background-color:rgba(0,0,0,.1);
					cursor:pointer;
				}
			}


		}


	}

	.c-dt-predefined{
		margin: @gutter 0;
		border-bottom:1px solid rgba(0,0,0,.1);
		> *:first-child{
			.ff-header();
			color:brown;
		}
	}


	.c-dt-month {
		// display: block;
		// // flex-direction: column;


		// .c-dt-controls {
		// 	> button {
		// 		flex-basis: 20%;
		// 	}
		// 	> span {
		// 		flex-basis: 30%;
		// 	}
		// }

	}

	> * {
		padding-left: @gutter;
		padding-right: @gutter;
	}


	@media(max-width:800px), (max-height:800px){
		.c-dt-selected {
			font-size: 15px;
			padding: (@gutter / 4);
		}

		.c-dt-day {
			font-size:12px;
			line-height:20px;
			height: 28px;
		}

	};

}

/* ./src/js/components/controls/departments-select/select-departments.less*/ 
[data-role="modal-content"] .c-select-departments {
	max-height:~"calc(100vh - 152px)";
	.search-control + div {
		max-height:~"calc(100vh - 220px)";
		overflow:auto;
	}
}


/* ./src/js/components/controls/employees-select/select-employees.less*/ 
[data-role="modal-content"] {

	.c-select-employees {
		padding-bottom: 0;
		//max-height:~"calc(100vh - 222px)";
		.search-control + div {
			//max-height:~"calc(100vh - 290px)";
			overflow:auto;
			.scrollable-mixin();
		}
	}

}
/**/
.edit-value-control.in-modal {
	> .c-select-employees.c-select-employees.c-select-employees.c-select-employees,
	> .c-select-departments.c-select-departments.c-select-departments.c-select-departments {
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
		.c-select-segments {
			padding: 5px @gutter;
			flex-grow: 0;
			flex-shrink: 1;
		}

		.select-single, .select-multiple {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.search-control {
				padding: 5px @gutter;
			}

			> :not(:last-child) {
				flex-grow: 0;
				flex-shrink: 0;
			}
			> :last-child {
				flex-grow: 1;
				flex-shrink: 1;
				display: flex;
				flex-direction: column;
				overflow: auto;
			}
			//overflow: auto;
		}
	}
}
/* ./src/js/components/controls/entity-person/c-econtact.less*/ 
.c-econtact {
	.in-modal & {
		padding: @gutter;
	}
	> * {
		margin-bottom: @gutter;
		header {
			.ff-header();
			margin-bottom:5px;
		}
		.ui-list-item.as-button.action {
			display:inline-block;
			width:auto;
			.button-mixin();
			.button-color(#f0f0f0, teal);
			> div {
				padding:0;
				> span {
					padding:0;
				}
			}
		}
	}
}

/* ./src/js/components/controls/groups-select/select-groups.less*/ 
[data-role="modal-content"] .c-select-groups {
	max-height:~"calc(100vh - 152px)";
	.search-control + div {
		max-height:~"calc(100vh - 220px)";
		overflow:auto;
	}
}


/* ./src/js/components/controls/inline-select-trigger/inline-select-trigger.less*/ 
[data-role=inline-select-trigger]{
	&.as-text {
		display:inline-block;
		vertical-align: middle;
		padding:2px;
		border-bottom:1px dotted rgba(0,0,0,.4);
		margin-bottom:2px;
		.ff-header();
		&:Hover{
			cursor: pointer;
			border-bottom-color: rgba(0,0,0,1);
			i {
				opacity:1;
			}
		}
		i {
			font-style: normal;
			.icon('pencil');
			margin-left: 4px;
			font-size: 0.8em;
			display: inline-block;
			vertical-align: middle;		
			opacity:.4;
		}
	}
	&.as-button{
		.button();
		display:inline-block;
		vertical-align:middle;
		> span, > i {
			display: inline-block;
			vertical-align: middle;		
		}
		i {
			font-style: normal;
			.icon('caret-down');
			margin-left: (@gutter / 2);
			//font-size: 0.8em;
			opacity:.8;
		}		
	}
}

/* ./src/js/components/controls/input/input.less*/ 
.edit-value-control {
	> input {
		width:100%;
		margin-bottom: @gutter;
	}
	&.in-modal > input {
		width: calc(100% - 30px);
		margin: @gutter;
	}
}

/* ./src/js/components/controls/input-number-spiner/c-input-number.less*/ 
.c-input-number {
	i {
		font-style:normal;		
		&:first-child{
			.icon('chevron-up');
		}
		&:last-child{
			.icon('chevron-down');
		}
		&:Hover{
			cursor:pointer;
			color:#000!important;
		}
	}
}

/* ./src/js/components/controls/input-placeholdered/input.less*/ 
.edit-value-control {
	.placeholdered{
		width:100%;
		margin-bottom: @gutter;
		input {
			width:100%;
		}
	}
}
.placeholdered {
	input {
		width:100%;
	}
}

/* ./src/js/components/controls/person/name/c-contact-name.less*/ 
.c-contact-name{
	input {
		width:100%;
	}
}

/* ./src/js/components/controls/person/name/human/human-name.less*/ 
.c-human-name{
	display:flex;
	background-color:#fff;
	> * {
		flex-basis: 100%;
		&:before{
			bottom:-2px;
			left:-2px;
		}
		input {
			width:100%;
			border:none!important;
			border-radius:0!important;
		}
	}
	border-radius:3px;
	overflow:hidden;
	border:2px solid rgba(0,0,0,.2);
}

/* ./src/js/components/controls/person/name/person-name.less*/ 
.c-person-name{
	.buttons-group{
		position:relative;
		z-index:1;
		bottom:-2px;
	}
}

/* ./src/js/components/controls/person/person.less*/ 
.c-person {
	.c-p-line{
		display:flex;
		> * {
			flex-grow:1;
			margin:2px;
			input{
				width:100%;
			}
		}		
	};

	.c-p-primary{

		flex-basis:60px;
		flex-grow:0;	
		
	}
}

/* ./src/js/components/controls/search/search-control.less*/ 
.card .search-control {
	padding:4px (@gutter / 2);
}
.search-control{
	> .search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper.search-control-wrapper {
		width:100%;
		display:flex;
		flex-flow: nowrap;
		
		border:2px solid rgba(0, 0, 0, 0.2);
		border-radius:3px;
		padding:0!important;
		flex-grow:0;
		> button {
			flex-grow:0!important;
			flex-basis: 40px;
			width:40px;
			border:none!important;
			&.applySearch {
				> i {
					.icon('search')
				}
			}
			&.resetSearch {
				> i {
					.icon('times')
				}
			}
		}
		> input {
			flex-grow: 1;
			border:none!important;
			width:inherit;
		}
	}
}

/* ./src/js/components/controls/select/select.less*/ 
.edit-value-control {

	// .ui-list-item {
	// 	//border-bottom:1px dashed transparent;
	// }
	.ui-list-item:Hover {
		.selection {
			background-color:transparent;
		}
		cursor:pointer;
		//border-bottom:1px dashed #eee;
		> div > span, > span > span {
			color:#000;
		}
	}

	.select-multiple, .select-single {
		.ui-list-item + .ui-list-item {
			border-top: 1px solid #44444410;
			margin-top: 5px;
		}
	}

}


.select-multiple .ui-list-item {
	.selection i { .icon-b('square-o'); }
	&.selected {
		.selection i { .icon-b('check-square-o'); }
	}
}

.select-single .ui-list-item {
	button:not(:Hover) {
		background-color:transparent!important;
	}
	.selection i { .icon-b('circle-thin'); }

	&.selected {
		.selection i { .icon-b('circle'); color: @clr-success; }
		> div > span, > span > span { color: @clr-success }
	} 	
}

.select-multiple .ui-list-item {
	button:not(:Hover) {
		background-color:transparent!important;
	}		
	&.selected 	{
		> div > span, > span > span { color: @clr-success }
		.selection i { color: @clr-success; }
	}
}



.edit-value-control.in-modal {
	> .select-single, > .select-multiple {
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.search-control {
			padding: 5px @gutter;
		}

		> :not(:last-child) {
			flex-grow: 0;
			flex-shrink: 0;
		}
		> :last-child {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			flex-direction: column;
			overflow: auto;
		}
		//overflow: auto;
	}
}
/* ./src/js/components/controls/select-api/control-select-api.less*/ 
.control-select-api {
	.search-control + * {
		//max-height:~"calc(100% - 42px)";
		max-height: ~"calc(100vh - 243px)";
		overflow-y: auto;
		.scrollable-mixin();
	}
}

/* ./src/js/components/controls/select-segmented/c-select-segment.less*/ 
.c-select-segment{
	color:rgba(0,0,0,0.5);
	padding:4px (@gutter / 2);
	line-height:.7;
	border-bottom:3px solid rgba(0,0,0,.1);
	.ff-header();
	&.current {
		color: @clr-success;
		border-bottom-color: @clr-success;
	}

	.as-button();
	font-size:12px;
}

/* ./src/js/components/controls/select-segmented/c-select-segments.less*/ 
.c-select-segments{
	display:flex;
	margin-bottom: (@gutter / 2);
	align-items: flex-end;

}

/* ./src/js/components/controls/textarea/bigtext.less*/ 
textarea {
	border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}
textarea.bigtext {
	width:100%;
	min-height:200px;
}

.placeholdered {
	> textarea {
		width:100%;
		display:block;
	}
}

.edit-value-control.in-modal {
	> textarea {
		width: calc(100% - 30px);
		margin: @gutter;
		min-height: calc(100vh - 300px);
	}
}
/* ./src/js/components/controls/time/c-time.less*/ 
.c-time{
	display:flex;
	flex-flow:row;
	align-items:center;
	.c-input-number {
		display:flex;
		flex-flow:column;
	}
	input {
		border:none!important;
		&::-webkit-inner-spin-button, 
		&::-webkit-outer-spin-button { 
			-webkit-appearance: none; 
			margin: 0; 
		}
		width:40px;
		text-align:center;
	}
}

/* ./src/js/components/edit-value/edit-value-control.less*/ 
.edit-value-control.in-modal {

    display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;
	
	> :first-child:not(.edit-actions) {
		flex-grow: 1;
		flex-shrink: 1;
		overflow-y: hidden;
		padding-bottom: @gutter;
	}

	.control-region {
		//margin:0 -@gutter;
		padding:0 5px;
	}
	.edit-actions {

		flex-grow: 0;
		flex-shrink: 0;		

		padding: @gutter;
		//margin:0 -@gutter;
		background:fade(@clr-main-bg, 5);

		.apply {
			.button-color(@clr-success, #fff);
		}

		.reset.delete-button{
			//color:tomato
			.button-color(tomato, white);
			float:right;
		}
	}
	> .card.lined {
		// margin-left:-@gutter;
		// margin-right:-@gutter;
		box-shadow:none;
		.card-line.whole-btn {
			&:Hover{
				background-color:rgba(0,0,0,.1);
				//border:none;
				box-shadow:none;
			}
		}
	}

	.predefined-values {
		padding: @gutter 0;
		> div {
			display: block;
			width: 100%;
		}
		.predefined-value {
			display: block;
			width: 100%;
			padding: (@gutter / 4) 0;
			&:Hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.1);
			}
		}
	}

}

/* ./src/js/components/edit-value/view/edit-value-control.less*/ 
.edit-value-control {
	&.long-content {
		height: ~"calc(100vh - 130px)";
		> *:first-child {
			height: ~"calc(100% - 71px)";
			overflow-y: auto;
		}
	}
}

/* ./src/js/components/empty-view/empty-view.less*/ 
.empty-view{
	padding: @gutter;
	.ff-header();
	font-weight:500;
	font-size:24px;
	color:rgba(0,0,0,.2);
	background-color:rgba(0,0,0,.1);
	border:3px dashed rgba(0,0,0,.1);
}

/* ./src/js/components/entity-page-layout/entity-page-layout.less*/ 
// @import '~less/include.less';
@glbl-border-radius: 4px;
@glbl-margin: 8px;

.page-content > .entity-page-layout {
	//margin: -15px;
	max-width: unset;
	// margin-left: -30px;
	// margin-top: -15px;
	// margin-right: -30px;
	// width: calc(100% + 60px);
	// height: calc(100% + 30px);
	margin: -15px;
	width: calc(100% + 30px);
	height: calc(100% + 30px);
	// background-color: rgba(80,0,0,.3);

	display: flex;
	flex-direction: row;
	position: relative;
	> aside {
		position: relative;
		width: 330px;
		flex-grow: 0;
		flex-shrink: 0;
		// background-color: rgba(0, 80, 0,.3);

		display: flex;
		flex-direction: column;
		overflow: auto;

		transition: all .3s;

		.scrollable-mixin();

		.hide-trigger {
			border-radius: @glbl-border-radius;
			padding: @gutter-half;
			margin: @gutter-half;
			display: none;
			color: white;
			cursor: pointer;
			&:hover {
				background-color: rgba(255,255,255,.2);
			}
		}

	}
	> article {
		flex-grow: 1;
		flex-shrink: 1;
		// background-color: rgba(0, 0, 80,.3);

		display: flex;
		flex-direction: column;
		overflow: auto;
		.scrollable-mixin();

	}

	.media-tablet-down({
		> aside {
			position: absolute;
			overflow: auto;
			height: 100%;
			width: 100%;
			left: -100%;
			background-color: darken(@clr-main-back, 20);
			.hide-trigger {
				display: block;
			}
		}
		&.nav-on > aside {
			left: 0;
			z-index: 1;

		}
	});
	.media-tablet-up({
		> article > header {
			display: none;
		}
	});
}

#epl() {
	.button() {
		height: 37px;
		border-color: transparent;
		border-radius: @glbl-border-radius;
		box-shadow: 0 0 7px 3px rgba(0,0,0,.05);
	}
	.button-color(white) {

	}
	.button-color(blue) {
		background-color: @clr-main-back;
		color: white;
		&:Hover {
			background-color: lighten(@clr-main-back, 20);
			//border-color: @clr-main-back;
		}
	}
}


.buttons-container {
	@btncnt-border-radius: @glbl-border-radius;

	display: flex;
	flex-direction: column;
	border-radius: @btncnt-border-radius;
	margin: @gutter-half;
	
	box-shadow: 0 0 7px 3px rgba(0,0,0,.05);

	button {
		

		background-color:transparent;
		color: @clr-link;
		text-align: left;
		box-shadow: none;
		border: none;
		border-radius: 0;


		&:Hover {
			background-color: rgba(0,0,0,.1);
			cursor: pointer;
		}

		> i:first-child {
			margin-right: @gutter-half;
		}

		border-left: 3px solid rgba(0,0,0,.1);
		border-top: 1px solid rgba(0,0,0,.1);
		&:first-child {
			border-top-left-radius: @btncnt-border-radius;
			border-top-right-radius: @btncnt-border-radius;
			border-top: none;
		}
		&:last-child {
			border-bottom-left-radius: @btncnt-border-radius;
			border-bottom-right-radius: @btncnt-border-radius;
		}
	}

	.white > & {
		background-color: white;

	}
	.warn > & {
		background-color: tomato;
		button {
			color: white;
		}
	}

	.bold > & {
		button {
			font-weight: bold;
		}
	}



}

.menu-group {
	&.pages {
		.buttons-container {
			background-color: white;
			button.current {
				background-color: @clr-main-bg;
				color: white;
			}
		}
	}
	&.actions {
		.buttons-container {
			background-color: antiquewhite;

		}
	}
}


.entity-page-content {
	> *, > .card.lined {
		margin: @gutter-half;
		// &:not(:first-child) {
		// 	margin-top: 0;
		// }
	}

	> .card.lined {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex-wrap: nowrap;
		> :not(.scrolled-y) {
			flex-grow: 0;
			flex-shrink: 0;
		}
		> .scrolled-y {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			flex-direction: column;
			overflow: auto;
			flex-wrap: nowrap;
		}
	}

	> header {
		margin-bottom: 0;
		display: flex;
		flex-direction: row;
		border-radius: @glbl-border-radius;
		background-color: white;
		align-items: center;
		button {
			background-color: transparent;
			border: none;
			&:Hover {
				background-color: rgba(0,0,0,.1);
				cursor: pointer;
			}
		}

		> :not(div) {
			flex-grow: 0;
			flex-shrink: 0;			
		}
		> div {
			flex-grow: 1;
			flex-shrink: 1;
			padding: @gutter-half;
			.ff-header();
			text-transform: uppercase;
			color: #444;
		}
		> i {
			padding: @gutter-half;
		}
	}


	
	.separate-buttons {
		//@btncnt-border-radius: @glbl-border-radius;
		
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: 7.5px;
		column-gap: 7.5px;


		button {
			#epl.button();
			#epl.button-color(white);
		}

		&.blue {
			button {

				#epl.button-color(blue);


			}
		}

	}

}
/* ./src/js/components/entries-manager/entries-manager.less*/ 
.entries-manager.entries-manager {
	height:100%;
	> *:first-child{
		margin-top:0;
	}
	> .card.lined {
		margin-bottom: 15px;
	} 
}

.fast-filters {
	.filled {
		background-color: darken(@clr-light-yellow, 10%);
	}
	.filters-text-search{
		> div {
			padding:0;
			> span {
				padding:4px 0!important;
				padding-right:(@gutter / 2)!important;
			}
		}
	}
	.filter-item {
		&:Hover {
			cursor: pointer;
			background-color: @clr-light-yellow;
		}
	}
}

/* ./src/js/components/filters-v2/ui2-filters-list.less*/ 
.ui2-filters-list {
	.filter-item {
		&:not(:first-child) {
			border-top: 1px solid #00000020;
		}
	
		.ui2-atom {
			> span {
				.ff-header();
				&:last-child {
					padding-bottom: (@gutter / 2);
				}
			}
		}
	}
}
.yat-modal-wrapper .yat-modal-content-wrapper .yat-modal-box .yat-modal-content > .ui2-filters-list {
	padding-top: @gutter;
	padding-bottom: @gutter;
}
/* ./src/js/components/logs/nested-logs.less*/ 
.nested-logs{
	background-color:white;
	border-radius: 4px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	> header {
		.ff-header();
		font-size:12px;
		text-transform:uppercase;
		color: @clr-link;
		border-bottom:1px solid fade(darken(@clr-light-yellow,10),60);
		padding: @gutter (@gutter / 2);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		&:not(:hover){
			background-color: fade(@clr-light-yellow,60%);
		}
		.as-button();
	}
	> .column-scrollable {
		padding: @gutter 0;
	}

	.empty-view {
		padding: @gutter;
		.ff-header();
		font-weight:300;
		background:transparent;
		border:none;
		padding-top:0;
	}
}



.log-color(@bg:#fffaf0, @size: 5px){
	background-color: @bg;
	margin-top: @size;
	.arrow { 
		top: -@size;
		border-bottom-color: @bg; 
		border-width: 0 @size @size @size;
	}
}
.log-border-color(@border: darkseagreen, @bg:honeydew, @size: 5px){
	background-color: @bg;
	border:1px solid @border;
	margin-top: @size;
	.arrow { 
		top: -(@size + 1);
		border-bottom-color: @border; 
		border-width: 0 @size @size @size;
	}
}


.lllog-entry{

	margin-bottom: @gutter;

	display:flex;
	flex-flow: row nowrap;
	align-items: center;
	width:100%;

	> i {
		flex-basis: @gutter2;
		color:rgba(0,0,0,.5);
	}
	&.true > i, &.true header, &.true  article { color:forestgreen; }
	&.false > i, &.false header, &.false  article { color:tomato; }
	
	&.true > i.positive { .icon('thumbs-o-up');  }
	&.false > i.positive { .icon('thumbs-o-down'); }

	i.type {
		.icon('hashtag');
	}
	&.taskChanged > i.type { .icon('info-circle'); }
	&.taskDeadlineChanged > i.type { .icon('clock-o'); }

	.authored {
		display:flex;
		align-items: center;
		.two-line-date{
			> big {
				padding-bottom:0;
			}
			> small {
				padding-top:0;
			}
		}
	}

	.tttype {
		padding:0 @gutter;


		> i.icon {
			margin-right:(@gutter / 2);
			.icon('hashtag');
			flex-basis: 16px;
		}
		> span {
			.ff-header();
			//font-family:'Play';
			font-size:10px;
			font-weight:400;
		}

	}
	.comment-text {
		position:relative;
		margin-left: 5px;
		margin-right: 5px;
		border-radius: 4px;


		.arrow {
			position:absolute;
			border-style: solid;
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			//left: 60px;
			margin-top: 0;
			margin-bottom: 0;
		}

		.text {
			padding: @gutter (@gutter / 2);
			font-size: 12px;
			white-space: pre-wrap;
		}

	}

	&.my {
		.comment-text{ 
			.log-color(fade(darkseagreen,15), 7px);
			.arrow {
				left:65px;
			}
		}
		.authored {
			justify-content: flex-start;
		}
	}
	&:not(.my){
		.comment-text{ 
			.log-color(fade(cornflowerblue,15), 7px);
			.arrow {
				right:65px;
			}
		}
		.authored {
			flex-flow: row-reverse;
			min-height:26px;
		}
	}

	> section {
		flex-grow:1;
	}
	footer {
		font-family: 'Play';
		font-size: 10px;		
	}
	address {
		font-style: normal;
		font-size: 1.2em;
	}
	header {
		.ff-header();
		font-weight:400;
		font-size:10px;
	}
	article {
		.ff-header();
		font-weight:800;
	}

	&.taskDeadlineChanged {
		header, footer {
			display:none;
		}
	}
}


.log-entry {
	margin-bottom: @gutter;
	> div.info {
		display:flex;
		flex-flow:row nowrap;
		align-items:baseline;
		padding:0 (@gutter / 2);
		i {
			flex-basis: @gutter2;
			color:rgba(0,0,0,.5);
			min-width: @gutter2;
			padding: 0 2px;			
		}
		> div {
			flex-grow:1;
			> * {
				display:block;
			}
		}

	}
	> div.changes {
		> label {
			display:block;
			margin-left:45px;
			margin-top:5px;
		}		
	}
	//&.comment > div > i.type { display:none }
	&.true, &.created, &.added {
		> div i, label {
			color:forestgreen;
		}
		i.positive { .icon('thumbs-o-up');  }
	}
	&.false, &.deleted, &.removed {
		> div i, label {
			color:tomato;
		}
		i.positive { .icon('thumbs-o-down');  }
	}
	// &.true > div i, &.true label { color:forestgreen; }
	// &.false > div i, &.false label { color:tomato; }		
	// &.true i.positive { .icon('thumbs-o-up');  }
	// &.false i.positive { .icon('thumbs-o-down'); }

	label {
		.ff-header();
		font-weight:400;
		font-size:12px;
		color:#444;
		display:block;
		span:first-of-type {
			color:teal;
			font-weight: 500;
		}
		span:last-of-type {
			color:forestgreen;
			font-weight: 500;
		}
	}


	i.type { 
		.icon('hashtag');
		&:before, &:after {
			min-width:14px;
		}

		&.type-person {
			.icon('user-o');
		}
		&.type-time {
			.icon('clock-o');
		}
		&.type-comment {
			.icon('comment-o')
		}
		&.type-file-text {
			.icon('file-text-o')
		}		
		&.type-phone {
			.icon('phone');
		}
		&.type-employees {
			.icon('group');
		}

		&.action-edit {
			.icon-add('pencil');
		}
		&.action-delete {
			.icon-add('times');
		}		
		&.action-play {
			.icon-add('play');
		}
		&.action-pause {
			.icon-add('pause');
		}
		&.action-plus {
			.icon-add('plus');
		}
		&.action-checked {
			.icon-add('check');
		}	
	}

	address {
		font-family:'Play';
		font-size:10px;
		font-weight:400;
		font-style:normal;
		&:first-child {
			.ff-header();
			font-weight:400;
			font-size:12px;
			color:#444;
			display:block;			
		}
	}

	article {
		position:relative;
		margin-left: 5px;
		margin-right: 5px;
		border-radius: 4px;


		> i {
			position:absolute;
			border-style: solid;
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			left: 75px;
			margin-top: 0;
			margin-bottom: 0;
		}

		section {
			padding: @gutter (@gutter / 2);
			font-size: 12px;
			white-space: pre-wrap;
		}
		
		.log-text-color(fade(slateblue,15), 7px);
	}

	&.my article {
		
		.log-text-color(fade(darkseagreen,15), 7px);
	}
	h3 {
		//margin:0;
		padding:0;
		.ff-header();
		font-weight:400;
		font-size:10px;
		margin:0 (@gutter / 2);
		color:#555;
		i {
			font-style: normal;
			margin-right:2px;
		}
		.icon-contragent i {
			.icon('user-secret');
		}
		.icon-task i {
			.icon('clock-o');
		}
		span {
			margin-right: 5px;
		}
	}

	.text-subject {
		color: cadetblue;
		.ff-header();
		font-size:14px;
		padding: 0 (@gutter / 2);
		> span.task > i {
			.icon('clock-o');
			margin-right: (@gutter / 3);
		}
	}

}

.log-text-color(@bg:#fffaf0, @size: 5px){
	background-color: @bg;
	margin-top: @size;
	i { 
		top: -@size;
		border-bottom-color: @bg; 
		border-width: 0 @size @size @size;
	}
}


.yat-modal-wrapper.float-textarea {
	position:fixed;
	left:0;
	top:0;
	width:0;
	height:0;
	.yat-modal-content-wrapper {
		position:fixed;

		//height:100%;
	}
	.yat-modal-header {
		cursor: move;
	}
}

/* ./src/js/components/managed-collection/managed-collection.less*/ 
.managed-collection {
	display: flex;
	flex-direction: row;
	height: 100%;
	> .entries-manager {
		flex-grow: 0;
		flex-shrink: 0;
		width: 300px;
	}
	> .entries-list {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
	}
}
/* ./src/js/components/page-sub-division/page-with-divisions.less*/ 
@pwd-aside-width: 330px;

.page-content {
	&.page-with-divisions {

		* {
			.scrollable-mixin();
		}
		
		padding: 0;
		padding-top: 50px;
		display: flex;
		flex-direction: row;
		position: relative;

		.page-with-divisions_aside {
			position: absolute;
			transition: .3s all;
			border-right: 2px solid @clr-main-back;
			width: @pwd-aside-width;
			top: 50px;
			bottom: 0px;
			left: 0px;

			
			flex-grow: 0;
			flex-shrink: 0;
			&:first-child {
				max-width: unset;
				margin: unset;
			}

			.division-link {
				.ff-header();
				text-transform:uppercase;
				border-left: 5px solid #00000010;
				.ui2-atom {
					> i, > b {
						min-height: 10px;
					}
				}
				&.current {
					color: @clr-main-back;
					border-left-color: @clr-main-back;
					background-color: fade(@clr-main-back, 10);
					color: white;
					background-color: @clr-main-back;
					border-left-color: fade(white, 40);
				}
			}

			.divisions {
				> :not(:first-child) {
					border-top: 1px solid fade(@clr-main-back, 20);
				}
			}

			.toggle-aside {
				transition: .3s all;
				position: absolute;
				width: 50px;
				height: 50px;
				bottom: 0;
				right: -0.5px;
				background-color: @clr-main-back;
				border: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				color: white;
				padding: 0;
				i {
					.icon('caret-left');
					font-size: 40px;
					line-height: 50px;
				}
			}


			&.hidden {
				// position: absolute;
				left: -@pwd-aside-width;
				// overflow: hidden;
				// width: 0px;
				.toggle-aside {
					right: -50px;
					//right: -380px;
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
					z-index: 1;
					i {
						.icon('caret-right');
					}
				}
			}

		}

		.page-with-divisions_content_container {
			// background-color: yellow;
			// width:100%;
			// height: 100%;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			padding-left: @pwd-aside-width;
			transition: .3s all;
			overflow: auto;
			> .header {
				flex-grow: 0;
				flex-shrink: 0; 
			}
	
			> .content {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				flex-shrink: 1; 
				overflow: auto;
			}

		}


		.page-with-divisions_aside.hidden + .page-with-divisions_content_container {
			padding-left: 0px;
		}


		.block {
			margin: (@gutter / 2);
			border-radius: 3px;
			box-shadow: 0 0 10px 5px #00000010;
			> :first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
			> :last-child {
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
			}
			&.white {
				background-color: white;
			}
		}

	}
}
/* ./src/js/components/pages-block/subpages.less*/ 
.card {
    .sub-pages.ui-list {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .subpage-item {
            &:not(:last-child) {
                border-radius: 0;
            }
            &.active {
                background-color:aliceblue;
                &:Hover {
                    background-color:aliceblue;
                    cursor: default!important;
                    .ui-list-item-main {
                        cursor: default!important;
                    }
                }
            }
            &:not(.active) {
                .ui-list-item-main span {
                    
                    color: #1d4567;
                }
            }
        }
    }
}
/* ./src/js/components/promise-buttons/promise-buttons.less*/ 
.promise-buttons{
	padding: 7.5px;
	background-color: rgba(106, 90, 205, 0.1);
	justify-content: space-between;
	button.resolve.resolve.resolve.resolve.resolve.resolve {
		.button-mixin();
		.button-color(@clr-success, #fff);
		margin-right:(@gutter / 2);
	}

	button.reject.reject.reject.reject.reject.reject {
		.button-color(#ddd, #555);
		.button-mixin();
	}

	button.reset.reset.reset.reset.reset.reset {
		.button-color(#ddd, #555);
		.button-mixin();
		&.delete {
			.button-color(darken(tomato,10), #fff);			
		}
	}	


}

/* ./src/js/components/schema-table/table-content.less*/ 
.page-table-layout {
	.page-with-divisions_content_container {

		.header {
			display: flex;
			flex-direction: row;
			padding: (@gutter / 2);
			align-items: stretch;
			&.list-manager {
				@rds: 4px;
				> * {
					background-color: white;
					border: 2px solid #00000020;
					&:not(.ui2-molecule) {
						padding: (@gutter / 2);
					}
					border-right-width: 1px;
					border-left-width: 1px;
					.ff-header();
					&:first-child {
						border-top-left-radius: @rds;
						border-bottom-left-radius: @rds;
						border-left-width: 2px;
					}
					&:last-child {
						border-top-right-radius: @rds;
						border-bottom-right-radius: @rds;
						border-right-width: 2px;
					}
				}
				button {
					display: flex;
					flex-direction: row;
					align-items:center;
					i.fa {
						font-size: 18px;
						color: @clr-main-back;
					}
					&:active {
						> * {
							transform: translateY(1px);
						}
					}
				}
				.ui2-atom {
					justify-content: center;
				}
			}
		}

		.content {
			padding: (@gutter / 2);
			> .table-rows-list {
				overflow: auto;
			}
		}


	}
}




.yat-modal-wrapper.printable-table.full-screen {
	height: unset;
	position: absolute;
	min-height: 100%;
	background-color: white;

	.yat-modal-bg {
		display: none;
	}

	.yat-modal-content-wrapper {
		position: relative;
		padding: 0;
		overflow: auto;
		.yat-modal-box {
			width: 100%;
			height: 100%;
			max-width: unset;
			position: relative;
			padding: 30px;
			@media print {
				padding: 0;
			}
			.yat-modal-close {
				position: fixed;
				z-index: 2;
				@media print {
					display: none;
				}
			}

			.print-ui2-block-mixin() {
					// margin: auto;
					padding: 0;
					display: block;
					@media print {
						width: 100%;
					}
						
					&.with-wrapper {

						.wrapper {
							box-shadow: none;
							border-radius: 0;
							display: block;
							border: none;
							> .ui2-molecule {
								border-radius: 0;
								border-top: 1px solid black;
								border-right: 1px solid black;
								border-left: 1px solid black;
								align-items: stretch;
								> * {
									padding: 5px;
									justify-content: space-around;
									// display: table-cell;
									&:not(:first-child) {
										border-left: 1px solid black;
									}
								}
							}
						}

						&:last-child .wrapper > .ui2-molecule {
							border-bottom: 1px solid black;
						}

					}
			}

			.print-table-rows-list-item-mixin() {
				display: block;
				position: relative;
				break-inside: avoid;
				* {
					position: relative;
					break-inside: avoid;
				}
				> .wrapper {
					display: block;
					> .ui2-molecule {
						// display: table;
						> * {
							display: table-cell;
							> * {
								display: block
							}
						}
					}
				}
			}

			.table-rows-list, .table-rows-list-header  {
				
				display: block;
				position: relative;
				@media print {
					padding: 0;
					width: 100%;
				}

				.ui2-block {
					.print-ui2-block-mixin();
				}


				> * {
					.print-table-rows-list-item-mixin();
				}

			}

			

			.no-print {
				display: none!important;
			}


			.strict-table {
				width: 100%;
				border-spacing: 0;
				td {
					border: 1px solid #aaa;
				}
			}

		}
	}
}
/* ./src/js/components/ui-card/ui-card.less*/ 
.card-color(@bg, @color){
	color: @color;
	background-color: @bg;
	&:Hover{		
		background-color: darken(@bg, 10);
	}
	* {
		color: @color!important;
	}
}


.card{
	&.lined{
		padding:0;
		margin:5px;
		display: flex;
		flex-flow: wrap;		
		> header, .card-header {
			margin:0;
			padding-left: (@gutter / 2);
			> button {
				padding:0;
				margin: (-@gutter / 2) 0;
				margin-right: -@gutter;
				flex-shrink: 0;
			}
			&.bg-main-dark > button {
				color: antiquewhite;
				text-decoration: underline;
			}
		}
		> *:not(.plain):not(ul), > .plain > *, > ul > * {
			.ui-list-item-mixin();
			height:auto;
		}
		> ul {
			margin:0;
			padding:0;
			display: block;
			width: 100%;
			height: auto;
		}

		&.with-border:not(.border-forced) {
			.ui-list-border-mixin();
		}

		&.with-border.border-forced {
			@BRDCLR: 1px solid rgba(0,0,0,.05);

			position: relative;
			border: @BRDCLR;
			> * {
				position: relative;
				border-right: @BRDCLR;
				border-bottom: @BRDCLR;
				top: 1px;
				left: 1px;
			}

		}


		> .card-line {
			> .inline-item {
				display:inline-block;
			}
		}


	}


	&.btn.btn.btn.btn.btn.btn {
		cursor:pointer;
		&.blue {
			.card-color(@clr-main-bg, #fff);
		}
		&.light-yellow {
			.card-color(@clr-light-yellow, #444);
		}
	}


	&.bg-light-green {
		> * {
			background-color: #dbecd4;
		}
	}

	&.double-column {
		.others-rule({
			width: 655px;
		});
	}
}


/* ./src/js/components/ui-card-block/ui-card-block.less*/ 
.bottom-action() {
	font-size:12px;
	text-transform:uppercase;
	color: @clr-link;
	height: 43px;
	border-top:1px solid fade(darken(@clr-light-yellow,10),60);
	&:not(:hover){
		background-color: fade(@clr-light-yellow,60%);
	}
	> div > span {
		color: @clr-link;
		font-weight:500;
	}	
}

.ui-list-item.as-button.action {
	.bottom-action();
}


.card{
	&.lined{
		.ui-list-item.as-button.action {
			.bottom-action();
		}		
		.empty-ui-list-item-text.empty-ui-list-item-text {
			color: #ccc;
    		font-weight: normal;
		}
		> .ui-list-item.card-block-header {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			&:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
			> div > span {
				padding: 5px 0;
			}
			&.blue {
				background-color: @clr-blue;
				font-size: inherit;
				> div > span {
					color: white;
				}
			}
		}
	}



}

/* ./src/js/components/ui-list/action.less*/ 
/*
.ui-list-item.as-button.action{
	font-size:12px;
	text-transform:uppercase;
	color: @clr-link;
	border-top:1px solid fade(darken(@clr-light-yellow,10),60);

	&:not(:hover){
		background-color: fade(@clr-light-yellow,60%);
	}
	> div > span {
		color: @clr-link;
		font-weight:500;
	}
}

*/

/* ./src/js/components/ui-list/buttons.less*/ 
button.open-card i {
    .icon('eye');
}
/* ./src/js/components/ui2/molecule/ui2-molecule.less*/ 
.ui2-molecule {
	> button {
		background-color: transparent;
		border: none;
		min-width: 30px;
		&:Hover {
			background-color: #00000010;
			cursor: pointer;
		}
		&:active {
			> * {
				transform: translateY(1px);
			}
		}
	}


	> .select {
		> i {
			font-size: 16px;
		}
	}

	&:not(.selected) {
		> .select {
			> i {
				.icon('square-o', block);
			}
		}
	}
	&.selected {
		// border: 1px solid darkseagreen;
		> .select {
			> i {
				.icon('check-square', block);
				color: darkseagreen;
			}
		}
	}


	&.with-borders {
		&:not(.selected) {
			&:not(.wrapped), &.wrapped > .wrapper {
				border: 1px solid #00000010;
			}	
		}
		&.selected {
			&:not(.wrapped), &.wrapped > .wrapper {
				border: 1px solid darkseagreen;
			}	
		}
		&:not(.wrapped), &.wrapped > .wrapper {
			
			> :not(:first-child) {
				border-left: 1px solid #00000010;
			}
		}
	}
	// &.with-borders:not(.wrapped) {
	// 	> :not(:first-child) {
	// 		border-left: 1px solid #00000010;
	// 	}
	// }

}
/* ./src/js/components/ui2/schema-properties/schema-properties.less*/ 
.schema-properties-list {
	display: flex;
	flex-direction: column;
}

.schema-property {
	display: flex;
	flex-direction: row;
	.with-borders > & {
		&:not(:first-child) {
			border-top: 1px solid rgba(0,0,0,.1);
		}
	}
}

.schema-property-label-value {

	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 1;

	.media-tablet-down({
		flex-direction: column;
	});

	.media-tablet-up({
		flex-direction: row;
	});

}

.schema-property-label {
	.ff-small();
	font-weight: 400;
	font-size: 14px;
	color: #555;
	width: 300px;
	flex-shrink: 0;
	.media-tablet-down({
		padding: 0 (@gutter / 2);
		text-align: left;
	});

	.media-tablet-up({
		padding: (@gutter / 2);
		text-align: right;
	});


}

.schema-property-value {
	.ff-header();
	flex-grow: 1;
	flex-shrink: 1;
	padding: (@gutter / 2);
	// .media-tablet-down({
	// });
}
/* ./src/js/components/ui2/ui2.less*/ 

.wrapped-mixin(@rule) {
	&:not(.with-wrapper), &.with-wrapper > .wrapper {
		@rule();
	}
}

.ui2-block-mixin() {

	.wrapped-mixin({
		display: flex;
		flex-direction: column;
		background-color: white;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
		border-radius: 3px;
		> :first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		> :last-child {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	
		> header.tabs {
			display: flex;
			flex-direction: row;
			.header-tab {
	
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
	
				padding: (@gutter / 2);
				margin-left: 4px;
				margin-right: 4px;
				margin-top: 4px;
				.ff-header();	
				&:not(.active) {
					cursor: pointer;
				}
				&.active {
				}
			}
		}
	
		> header.just-text, > footer.just-text {
			padding: (@gutter / 2);
			.ff-header();
		}
	
		> header.right-buttons {
			display: flex;
			flex-direction: row;
			> span {
				flex-grow:1;
				flex-shrink:1;
				.ff-header();
				padding: (@gutter / 2);
			}
			> button {
				position: relative;
				flex-grow: 0;
				flex-shrink: 0;
				padding: 3px (@gutter / 2);
				border: none;
				background-color: transparent;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 0;
				&:last-child {
					border-top-right-radius: 3px;
				}
				> * {
					position: relative;
				}
				&:active {
					> * {
						top: 1px;
					}
				}
			}
		}
	
	
		> header.bg-main-dark {
			color: white;
	
			&.tabs {
				.header-tab {
					&:not(.active) {
						background-color: rgba(255,255,255,.2);
						color: #aaa;
						&:hover {
							background-color: rgba(255,255,255,.5);
							color: #eee;
						}
					}
					&.active {
						background-color: white;
						color: @clr-main-back;
					}
				}
			}
			&.right-buttons {
				> button {
					color: bisque;
					&:Hover {
						background-color: rgba(255,255,255,.2);
					}
				}
			}
		}
	
		> * {
			flex-grow: 0;
			flex-shrink: 0;
		}
	
		> .elastic-content {
			flex-grow: 1;
			flex-shrink: 1;
			overflow-y: auto;
		}
	
		&.double-column {
			.others-rule({
				width: 655px;
			});
		}
	
	});



}

.ui2-block-bg-mixin(@bg; @hoverbg: darken(@bg, 10)) {
	.wrapped-mixin({
		background-color: @bg;
	});
	&.clickable {
		.clickable-mixin(@hoverbg);
	}
}

.clickable-mixin(@bg: rgba(0,0,0,.05)) {
	&.clickable {
		.wrapped-mixin({
			&:Hover {
				cursor: pointer;
				background-color: @bg;
			}
		});
	}
}

.ui2-blocks {
	> .ui2-block {
		margin-bottom: @gutter;
	}
}

.ui2-block {
	.clickable-mixin(lighten(#337ab7, 40));
	.ui2-block-mixin();

	&.with-wrapper {
		padding: 4px;
	}
}

.ui2-horizontal-elements-mixin() {
	> *:not(button):not(.icon):not(.fixed), > .elastic {
		flex-grow: 1;
		flex-shrink: 1;
	}
	> button:not(.elastic), > .icon, > .fixed {
		flex-grow: 0;
		flex-shrink: 0;
	}
	
}

.ui2-inputLine {
	padding: 5px;
	> input:not([type="radio"]):not([type="checkbox"]) {
		width: 100%;
	}
}


.ui2-molecule {
	display: flex;
	flex-direction: row;
	.ui2-horizontal-elements-mixin();
	> * {
		padding: 4px;
	}
	&.clickable {
		&:Hover {
			cursor: pointer;
			background-color: #00000010;
		}
	}
	.with-borders > &:not(:first-child) {
		border-top: 1px solid rgba(0,0,0,.1);
	}
}


.ui2-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	.ui2-horizontal-elements-mixin();
}


.ui2-line-action {
	.ff-header();
	color: #337ab7;
	font-weight: 500;
	background-color: #fffaf0;
	border-left: 4px solid rgba(0,0,0,.2);
	&:Hover {
		background-color: darken(#fffaf0, 5);
		cursor:pointer;
	}
	&.just-text {
		padding: (@gutter / 2);
	}
}

.ui2-atom {
	display: flex;
	flex-direction: column;
	> i, > b {
		font-size: 12px;
		color: @clr-blue;
		.ff-system();
		font-style: normal;
	}
	> i {
		order: 1;
	}
	> span {
		order: 2;
		.ff-header();
	}
	> small {
		order: 3;
		.ff-small();
		font-weight: 400;
	}
	> b {
		order: 4;
	}
	&.clickable:not(:disabled):not(.disabled) {
		&:Hover {
			background-color: rgba(0,0,0,.1);
			cursor: pointer;
		}
	}
}

.ui2-box {
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	&.clickable {
		&:Hover {
			box-shadow: 0 0 0 4px fade(darkseagreen, 50);
			cursor: pointer;
		}
	}
}

.ui2-box-rounded {
	border-radius: 3px;
}

.ui2-bg-white {
	background-color: white;
}

.ui2-list-item-margined {
	margin: (@gutter / 2);
}


.ui2-box-item {
	.ui2-box;
	.ui2-box-rounded;
	.ui2-bg-white;

	.ui2-atom {
		> span {
			.ff-header();
		}
	}

	&.selected {
		background-color: #e5ffdc;
		// .ui2-atom {
		// 	> span {
		// 		//color: darkgreen;
		// 	}
		// }
	}

}


.ui2-flex-column {

	display: flex;
	flex-direction: column;
	max-height: 100%;
	
	> :not(.elastic) {
		flex-grow: 0;
		flex-shrink: 0;
	}
	> .elastic {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
	}
}


.entity-page-content .ui2-blocks {
	// margin-top: @gutter;
	// margin-bottom: @gutter;
}

.ui2-blocks.two-column-row-cards {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: @gutter;
	column-gap: @gutter;
	> .ui2-block {
		width: 600px;
		margin: 0;		
	}
}

.ui2-block.canonical {
	> header {
		padding: (@gutter/2) @gutter;
		.ff-header();
		text-transform: uppercase;
	}
}
/* ./src/js/components/yandex-map/modal-yandex-map.less*/ 
.yat-modal-wrapper.yandex-map.full-screen {
	.yat-modal-content-wrapper {
		padding: @gutter;
		width: ~"calc(100% - @{gutter})";
		height: ~"calc(100% - @{gutter})";
		max-width: unset;
		margin-top: 0;
		.yat-modal-box {
			width: 100%;
			height: 100%;
			.yat-modal-close { z-index: 1; }
			.yat-modal-content {
				width: 100%;
				height: 100%;
				max-height: unset;
				> div {
					width: 100%;
					height: 100%;
					> div {
						width: 100%;
						height: 100%;
					}
					> .overlay-address-info {
						position: absolute;
						z-index: 1;
						width: 315px;
						bottom: 40px;
						left: 10px;
						background-color: #ffffff;
						border-radius: 4px;
						box-shadow: 0 0 5px 3px #00000020;
						padding: 5px;
						header {
							font-size: 14px;
							font-style: italic;
							color: #555;
							font-weight: normal;
						}
						> div {
							display: flex;
							flex-direction: row;
							margin: 5px 0;
							> label {
								flex-grow: 0;
								padding-right: 15px;
								display:inline-block;
							}
							> span {
								flex-grow: 1;
							}
						}
						button {
							.button-color(teal, #fff);
						}
					}
					&.searching > .overlay-address-info {
						z-index: -1;
					}
					
				}
			}
		}
	}
}


/* ./src/js/helpers/date/twin.less*/ 
.two-line-date{
	display:flex;
	flex-flow:column nowrap;
	align-items: stretch;
	align-content: center;
	justify-content: center;
	min-width:45px;
	//width:100px;
	> * {
		//font-family:'play';
		.ff-header();
		font-size:15px;
		line-height:1;
		padding:2px 0;
		font-weight:500;
		//text-align:center;
		color:#777;
		&:last-child {
			padding-top:0;
		}
		white-space:nowrap;
	}
	big {
		//font-size:16px;
		line-height:.7;
		padding-top:5px;
	}

	&.small {
		width:60px;
		> * {
			font-size:10px;
		}
		> big {
			font-size:13px;
		}
	}
}

/* ./src/js/helpers/modals/confirm.less*/ 
.yat-modal-wrapper.confirm{
	.yat-modal-box, .yat-modal-actions {
		background-color:antiquewhite;
		color: #444;
	}
	.yat-modal-box {
		//background-color:steelblue;
		
		max-width:500px;
		margin:0 auto;
		border-radius:4px;
		box-shadow:0 0 20px 5px rgba(0,0,0,.1);
	}
	.yat-modal-content {
		.ff-header();
		padding: @gutter2 @gutter;
		font-weight:400;
		font-size:24px;
		
		.reason-container {
			margin-top: 15px;
			.reason-label {
				font-size: 14px;
				font-style: italic;
			}
			textarea {
				width: 100%;
				height: 100px;
			}
		}
	}
	.yat-modal-close {
		&:not(:Hover){
			background-color:transparent;
		}
		&:Hover{
			background-color:rgba(0,0,0,.1);
			border-radius:0;
			border-top-right-radius: 4px;			
		}
	}
	.yat-modal-actions {
		.yat-modal-resolve {
			margin-right: @gutter;
		}
	}
}

/* ./src/js/helpers/modals/error.less*/ 
.yat-modal-wrapper.error{
	.yat-modal-box {
		background-color:lightcoral;
		color: #fff;
		max-width:600px;
		margin:0 auto;
		border-radius:4px;
		box-shadow:0 0 20px 5px rgba(0,0,0,.1);
	}
	.yat-modal-content {
		.ff-header();
		padding: @gutter;
		font-weight:400;
		font-size:20px;
	}
	.yat-modal-close {
		&:not(:Hover){
			background-color:transparent;
		}
		&:Hover{
			background-color:rgba(0,0,0,.1);
			border-radius:0;
			border-top-right-radius: 4px;			
		}
	}
}

/* ./src/js/helpers/modals/flex-modal.less*/ 
[data-role="modal-wrapper"].flex-modal {
    .yat-modal-content-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content:center;
        .yat-modal-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            > header {
                flex-grow:0;
                flex-shrink:0;
            }
            .yat-modal-content {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
/* ./src/js/helpers/modals/message.less*/ 
.yat-modal-wrapper.message{
	.yat-modal-box {
		//background-color:lightcoral;
		//color: #fff;
		max-width: 600px;
		margin:0 auto;
		border-radius:4px;
		box-shadow:0 0 20px 5px rgba(0,0,0,.1);
	}
	.yat-modal-content {
		.ff-header();
		padding: @gutter;
		font-weight:400;
		font-size:20px;
	}
	.yat-modal-close {
		&:not(:Hover){
			background-color:transparent;
		}
		&:Hover{
			background-color:rgba(0,0,0,.1);
			border-radius:0;
			border-top-right-radius: 4px;			
		}
	}
}

/* ./src/js/helpers/modals/preloader.less*/ 
.overlayed-preloader{
	background-color:rgba(255,255,255,.8);
	text-align:center;
	> i {
		position:relative;
		font-style: normal;
		font-family: 'FontAwesome'!important;
		top:50%!important;
		margin-top:-12.5px;
		color:rgba(0,0,0,.2)!important;
		font-size:24px!important;
	}
}

/* ./src/js/helpers/notifier/fast-notifies.less*/ 
@defRule: {

};

.fast-notifies{
	&[data-scrollable]{
		padding:0;
		margin:0;
		padding-left:5px;
	}
	position: fixed;
	max-width: 260px;
	right: 0;
	top: 50px;
	max-height: ~"calc(100% - 60px)";
	z-index: 1000;
	> * {
		width:250px;
		margin-left:auto;
	}

	> .card.lined.fast-notify {

		.card-icon(@icon, @color: inherit, @rule: @defRule){
			> .card-line.itemLine > i > i {
				.icon(@icon);
				color: @color;
				@rule();
			}
		}
		.card-colors(@bg, @txt){
			color: @txt;
			background-color: @bg;
			* {
				color: @txt;
			}
			.cardline-top({ color: @txt });
			.cardline-bottom({ color: @txt });
			.cardline-text({ color: @txt });
			.cardline-small({ color: @txt });
		}
		.cardline-top(@rule){
			> .card-line.itemLine {
				> div, > span {
					> i {
						@rule();
					}
				}
			}
		}
		.cardline-bottom(@rule){
			> .card-line.itemLine {
				> div, > span {
					> b {
						@rule();
					}
				}
			}
		}
		.cardline-text(@rule){
			> .card-line.itemLine {
				> div, > span {
					> span {
						@rule();
					}
				}
			}
		}
		.cardline-small(@rule){
			> .card-line.itemLine {
				> div, > span {
					> small {
						@rule();
					}
				}
			}
		}				

		position:relative;
		margin-left:auto;
		box-shadow: 0 0 3px 1px rgba(0,0,0,.5);

		> .card-line.itemLine {
			position:relative;
			> i {
				font-size:18px;
				padding-left:(@gutter / 4);
				padding-right:(@gutter / 4);
				min-width:25px;
				max-width:25px;
				padding-top:12px;
			}
			> div > span {
				line-height: 0.8;
				font-weight: 300;
				padding-bottom: 20px;			
			}
			> div > b {
				font-weight: 500;
			}
			> div > i {
				font-weight: 500;
				text-transform:none;
				top:1px;
			}
			> div > span, > div > small {
				font-weight:500;
			}

			> div > i + span {
				padding-bottom:(@gutter / 2);
				line-height:1.1em;
			}
		}

		&.comment {
			.card-icon('comment', cadetblue);
			.card-colors(floralwhite, #444);
			.cardline-top({
				color: cadetblue;
				* { color: cadetblue; }
			});
		}
		&.task {
			.card-icon('tasks');			
		}
		&.taskCreated {
			.card-colors(cornflowerblue, #fff);
		}
		&.taskChecking {
			//color:
			.card-colors(cadetblue, white);
		}

		&.system {
			.cardline-text({
				font-size:80%;
			});
			&.error {
				.card-icon('exclamation-triangle', tomato);
				.card-colors(firebrick, white);
				.cardline-top({
					color: tomato;
					* { color: tomato; }
				});				
			}
			&.warning {
				.card-colors(darken(darkorange,10), white);
				.card-icon('exclamation',orange);
				.cardline-top({
					color: orange;
					* { color: orange; }
				});					
			}
			&.message {
				.card-icon('info',cadetblue);
			}
			&.wait {
				.card-icon('spinner', cornflowerblue, {
					animation: fa-spin 2s infinite linear;
				});
				// .fa-spin;
				// > .card-line.itemLine > i {
				// 	.icon(@icon);
				// 	color: @color;
				// }				
			}
		}
	}

	> .card {
		&.viewed {
			> .card-line.itemLine .close > i {
				.icon('times');
			}
		}
		> .card-line.itemLine .close > i {
			.icon('eye-slash');
		}		
	}


	> .card.lined.fast-notify.upload-session {
		> .itemLine.card-line {
			> div {
				> small {
					margin-bottom:3px;
					color: teal;
					word-break: break-all;
				}
				> b {
					progress {
						width: 100%;
					}
				}
			}
		}
	}

	.fast-notify.ver-2 {

		.card-icon(@icon, @color, @rule: @defRule) {
			& > .icon {
				> i {
					.icon-b(@icon);
					color: @color;
					@rule();
				}
			}
		}
		.cardline-text(@rule){
			@rule();
		}
		.card-colors(@c1, @c2){

		}
		.cardline-top(@rule){
			@rule();
		}

		&.system {
			.cardline-text({
				font-size:80%;
			});
			&.error {
				.card-icon('exclamation-triangle', tomato);
				.card-colors(firebrick, white);
				.cardline-top({
					color: tomato;
					* { color: tomato; }
				});				
			}
			&.warning {
				.card-colors(darken(darkorange,10), white);
				.card-icon('exclamation',orange);
				.cardline-top({
					color: orange;
					* { color: orange; }
				});					
			}
			&.message {
				.card-icon('info',cadetblue);
			}
			&.wait {
				.card-icon('spinner', cornflowerblue, {
					animation: fa-spin 2s infinite linear;
				});
				// .fa-spin;
				// > .card-line.itemLine > i {
				// 	.icon(@icon);
				// 	color: @color;
				// }				
			}			
		}
	}

}

/* ./src/js/modules/contragents/views/contragent.less*/ 
.contragent.contragent.contragent.contragent.contragent.contragent.contragent {
	> button.info {
		line-height:1;
		i {
			.icon-b('user-secret');
			color:#aaa;
			font-weight:100;
			font-size:24px;
		}
	}
}

/* ./src/js/modules/logs/views/activity-list-item.less*/ 

.entries-color-mixin(@bg, @c: #444)
{
	.entries-wrapper {
		color: @c;
		.angle {
			border-bottom-color: @bg;
		}
		.comment-entries {
			background-color: @bg;
		}
		button {
			color: @c;
		}
	}
}

.activity-list-item {
	
    padding: 10px 0;
    border-bottom: 1px dashed #ddd;
    margin: 0;

	> .info {
		display: flex;
		flex-flow: row;
		width: 100%;
		align-items: center;
		padding: 4px;
		:not(.author) {
			flex-grow: 0;
			flex-shrink: 0;
		}
		.date {
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
		.author-entity {
			flex-grow: 1;
			flex-shrink: 1;
			.ff-header();
			padding: 0 5px;
		}
		.icon {
			font-size: 12px;
			width:20px;
			text-align:center;
		}		

	}

	&.not-comment {
		.entries-color-mixin(#f4f4f4);
		> .info {
			font-size: 12px;
			.icon {
				> i {
					.icon-b('hashtag');
				}
			}
		}
	}
	&.comment {

		&.my {
			.entries-color-mixin(fade(seagreen,10));
		}
		&.not-my.even {
			.entries-color-mixin(fade(royalblue,10));
		}
		&.not-my.odd {
			.entries-color-mixin(fade(pink,30));
		}		
		> .info {
			.icon {
				> i {
					.icon-b('comment');
				}
			}
		}

		table {
			box-sizing: border-box;
			//border-collapse: collapse;
			border-spacing: 0;
		}

		tr:not(:last-child) {
			td {
				border-bottom:1px solid rgba(0,0,0,.1);
			}
		}

		td {
			box-sizing: border-box;
			padding: 7.5px;
		}

	}

	button {
		border: none;
		width: auto;
		padding: 0 (@gutter / 2);
		margin: 0;
	}


	.entity {
		&.task {			
			a {
				color: teal;
				.ff-header();
				font-size: 14px;
				> i {
					.icon('clock-o');
					width: 20px;
					text-align: left;
				}
			}
		}
	}

	.entries-wrapper {
		position: relative;
		padding: 4px;
		.angle {
			position: absolute;
			border-style: solid;
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			left: 75px;
			margin-top: 0;
			margin-bottom: 0;
			
			top: -4px;
			
			border-width: 0 8px 8px;			
		}
	}
	.comment-entries {

		padding: 4px;
		border-radius: 3px;

	}
	.comment-entry {
		
		[data-action] {
			cursor: pointer;
		}
		audio {
			width: 100%;
		}
		display: flex;
		flex-flow: row;
		align-items: flex-start;
		.ff-header();

		.date-time {
			font-size: 12px;
			flex-grow: 0;
			flex-shrink: 0;
			width: 40px;
		}
		.text-wrapper {
			flex-grow: 1;
			flex-shrink: 1;
			overflow-x: auto;

			header {
				font-size: 12px;
				font-style: italic;
			}
		}
		.text {
			font-size: 14px;
			font-weight: 400;
			word-break: break-all;
			word-break: break-word;
			.markdown-it();
			// white-space: pre-wrap;

			a {
				color: crimson;
				&:Hover {
					text-decoration: underline;
				}
			}

			.file-link {
				.icon.media {
					margin-right: 3px;
					.icon('file-o');
					&.image {
						.icon('file-image-o');
					}
					&.audio {
						.icon('file-audio-o');
					}
					&.video {
						.icon('file-video-o');
					}
				}
			}

			.accent {
				color: darkslateblue;
			}
			.line {
				display: block;
				> label {
					display: block;
					.ff-small();
				}
				.old-value, .new-value {
					display:inline-block;
					vertical-align: top;
					font-size: 10px;
					font-weight: 600;
				}
				.old-value {
					color: darkgray;
				}
				.new-value {
					color: darkcyan;
				}
				.old-value:after {
					display:inline-block;
					vertical-align: top;
					content: '\2192';
					color: #aaa;
				}
			}

			img {
				max-width: 100px;
			}


		}
		.options {
			flex-grow: 0;
			flex-shrink: 0;
			box-sizing: border-box;
			button {
				box-sizing: border-box;
				font-weight: normal;
				padding:0 4px;
				background-color: transparent;
				&:Hover {
					background-color: rgba(0,0,0,.2);
				}
				i {
					.icon-b('ellipsis-h');
				}
			}
		}


		&.comment {
			margin-bottom: 0px;
			&.incorrect {
				opacity:.5;
				.text{
					text-decoration: line-through;
				}
			}			
		}

		&.positive {
			header {
				color: seagreen;
			}
		}
		&.negative {
			header {
				color: tomato;
			}
		}

		.markers {
			> * {
				padding: 3px;
				font-size: 12px;
				color: teal;
			}
			.important {
				.icon('exclamation-circle');
			}
			.protocol {
				.icon('list');
			}
			.propagate {
				.icon('hand-o-up');
			}
		}

	}

}

/* ./src/js/modules/logs/views/entity-activity.less*/ 

.entity-activity {
	.card-shadow-mixin();
	background-color: #fff;
	border-radius:3px;
	display:flex;
	flex-flow: column;
	.others-rule({
		width: 655px;
	});


	height: ~"calc(100% - 15px)";
	//height:100%;
	> header, > footer {
		flex-grow:0;
		flex-shrink:0;
	}
	> section {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
		.scrollable-mixin();
	}

}

.fast-comment-layout {
	display: flex;
	flex-flow: row;
	width: 100%;
	min-height: 50px;
	box-sizing: border-box;
	padding: 0;
	border-top: 1px solid rgba(0,0,0,.1);
	> * {
		margin:0;
		padding:0;
	}
	> .helpers, > .send-message  {
		flex-grow: 0;
		flex-shrink: 0;
		width: unset;
	}
	button {
		display:block;
		padding: 1px;
		margin: 1px;
		width: auto;
		border: none;
		> i {
			display: block;
			font-size: 18px;
			color: #aaa;
		}
	}
	.to-compose-mode {
		> i {
			.icon-b('pencil-square-o');
		}
	}
	.markdown-help {
		> i {
			.icon-b('question-circle');
		}
	}
	.send {
		> i {
			.icon-b('paper-plane-o');
		}
	}
	textarea {
		.scrollable-mixin();
		border: none;
		resize: none;
		height: 100%;
		width: 100%;
		margin: 0;
		padding:0;
		&:focus {
			outline: none;
		}
		&:not(.autoresize-disabled) {
			overflow: hidden;
		}
		&.autoresize-disabled {
			overflow-y:scroll;
		}
	}
	> .message {
		flex-grow:1;
		flex-shrink:1;
		padding-top: 6px;
		padding-left: 6px;
	}
}

.activity-list-filters {
	padding: 3px;
	border-bottom: 1px solid rgba(0,0,0, .1);
	button {
		display:inline-block;
		vertical-align: middle;
		margin:0;
		padding:3px;
		line-height: 1;
		font-size: 18px;
		i {
			font-style: normal;
		}
		&.comment {
			i { .icon-b('comments'); }
		}
		&.file {
			i { .icon-b('file-o'); }
		}		
		& + button {
			margin-left: 3px;
		}
		&.labeled {
			font-size: 12px;
			line-height: 18px;
		}
		&.active {
			background-color: cornflowerblue;
			border-color: cornflowerblue;
			color: white;
		}
	}
	input:not([type="radio"]):not([type="checkbox"]) {
		display:inline-block;
		vertical-align: middle;
		margin:0;
		margin-left: 3px;
		padding:3px;
		line-height: 1;
		font-size: 18px;
		height:28px;
		width: 150px;
	}
}


.modal-markdown-help {
	border: 1px solid rgba(0,0,0,.1);
	border-bottom: none;
	.markdown-item {
		font-size: 14px;
		border-bottom: 1px solid rgba(0,0,0,.1);
		//.ff-text();
		> * {
			display: inline-block;
			width: 50%;
			vertical-align:top;
			padding: 2px;
			text-align:center;
			& + * {
				border-left: 1px solid rgba(0,0,0,.1);
				background-color:beige;
				.markdown-it();
			}
		}
	}
}

/* ./src/js/modules/logs/views/extended-edit/extended-comment.edit.less*/ 


// @tab-bg-color: #fff;

// @tab-active-color: cornflowerblue;
// @tab-active-border-color: #ddd;
// @tab-active-bg-color: @tab-bg-color;

// @tab-passive-color: #aaa;
// @tab-passive-bg-color: transparent;
// @tab-passive-border-color: @tab-passive-bg-color;

// @tab-passive-hover-color: #444;
// @tab-passive-hover-bg-color: #f0f0f0;
// @tab-passive-hover-border-color: @tab-passive-hover-bg-color;





//@tab-color: #aaa;

.extended-comment-edit {

	display: flex;
	flex-direction: column;
	overflow-y: auto;

	height: calc(100vh - 66px);

	.tab-triggers {
		@ece-sel: button;
		@ece-active-sel: .active;
		.tabs-triggers-mixin(@ece-sel, @ece-active-sel);
		padding:0 @gutter;
		flex-grow:0;
		flex-shrink:0;
	}

	.tab-content {
		padding: @gutter 0;
		flex-grow:1;
		// height: ~"calc(100vh - 260px)";
		// @media(max-height: 800px) {
		// 	height: ~"calc(100vh - 225px)";
		// }
		overflow-y: auto;
		textarea {
			width: 100%;
			height: 100%;
			resize: none;
		}
		.edit-view, .preview-view {
			height: 100%;
			width: 100%;
		}
		.preview-view {
			overflow-y: auto;
			article {
				width: 100%;
				font-size: 14px;
				padding: @gutter;
				background-color: #eee;
				border-radius: 4px;
				.markdown-it();
			}
		}
	}
	.action-buttons {
		padding-top: @gutter;
		flex-grow:0;
		flex-shrink:0;
	} 
}

/* ./src/js/modules/processes/buy/buyprocess-products-block.less*/ 
.buyprocess-products-block {


	header.header-with-buttons {
		display: flex;
		flex-direction: row;
		
		align-items: center;
		padding:0;
		//padding:0;

		> div {
			flex-grow: 1;
			flex-shrink: 1;
			> span {
				padding:0;
			}
		}
		> button {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0;
		}
		> a {
			flex-grow: 0;
			flex-shrink: 0;

			cursor: pointer;
			border-radius: 4px;
			padding:0 4px;
			&:Hover {
				background-color: #00000010;
			}
		}
	}

	.products-items {
		display: flex;
		flex-direction:column;
		.ui-list-item.ui-list-item {
			display: flex;
			flex-direction:row!important;
			padding-left: 0;

			> button {
				flex-grow: 0;
				flex-shrink: 0;
			}
			> div {
				flex-grow: 1;
				flex-shrink: 1;
				> small {
					> .small-items {
						display: flex;
						flex-direction: row;
						width: 100%;
						> .small-item {
							padding: 4px 0;
							flex-grow: 1;
							flex-shrink: 1;
							font-size: 16px;
							label  {
								display: block;
								font-weight:bold;
								font-size: 10px;								
								margin-bottom: 2px;
							}
						}
					}
				}
			}

		}

		.buy-product-item.buy-product-item {

			.amount {
				flex-grow: 0;
				flex-shrink: 0;
				width: 80px;
				text-align: right;
				b {
					color: @clr-blue;
				}
			}
			.name {
				b {
					color: @clr-blue;
				}
			}

			&.completely-accepted {
				.name span, .amount span {
					text-decoration:line-through;
					color: #aaa;
				}
			}

		}

	}
	> footer {
		background-color: fade(lighten(royalblue, 35), 50);
		border-top-left-radius: 0!important;
		border-top-right-radius: 0!important;
		> div {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			&:last-child {
				text-align: right;
			}
		}
	}
}



.accept-process-product-dialogue 
{

	.info-by-lines {

		&.top-border {
			border-top: 1px solid rgba(0,0,0,.1);
		}
		&.bottom-border {
			border-bottom: 1px solid rgba(0,0,0,.1);
		}

		li {
			border-bottom: 1px solid rgba(0,0,0,.1);
			display: flex;
			flex-direction: row;			
			> div {
				padding: @gutter;
				.ff-header-mixin(500);
			}
			> dl {
				.ff-header-mixin(500);
				margin:0;
				display: flex;
				flex-direction: row;
				> * {
					//margin:0 5px;
					// padding: (@gutter / 2);
					// &:not(:first-child) {
					// 	border-left: 1px solid rgba(0,0,0,.1);
					// }
				}
			}
			&:last-child {
				border-bottom-color: transparent;
			}
			&.centered {
				justify-content: center;
				// > div {
				// 	text-align:center;
				// }
				> dl {
					justify-content: center;
				}
			}
			dd {
				color: #337ab7;
				margin:0;
			}
			dt {
				color: #464;
				margin:0;
			}


			.cell {
				padding: @gutter;
				&:not(:first-child) {
					border-left: 1px solid rgba(0,0,0,.1);
				}

			}
		}

		.color-red {
			color: tomato;
		}
		.color-green {
			color: darkolivegreen;
		}
		.two {
			width: 100%;
			> .cell {
				width: 50%;
				&:first-child {
					text-align: right;
				}
			}
		}
		.four {
			width: 100%;
			> .cell {
				width: 25%;
				text-align: center;
				&:first-child {
					text-align: right;
				}
				&:last-child {
					text-align: left;
				}
			}

		}
		.three {
			width: 100%;
			> .cell {
				width: calc(100% / 3);
				text-align: center;
				&:first-child {
					text-align: right;
				}
				&:last-child {
					text-align: left;
				}
			}

		}
	}

	.parties-list {
		width: auto;
		.party-item {
			display: flex;
			flex-direction: row;
			padding: (@gutter / 2);
			justify-content: center;
			width:auto!important;
			&:nth-child(2n+1) {
				background-color: rgba(0,255,128,.1);
			}
			&:nth-child(2n) {
				background-color: rgba(0,128,255,.1);
			}

			> * {
				padding:0;
				margin:5px;
				flex-grow:0;
				flex-shrink:0;
				&:last-child {
					width: 40px;
				}
			}


			> .edit-box {
				input {
					width: 100%;
					height: 42px;
					&:focus {
						outline: none;
					}
				}
				&.has-error {
					input {
						border-color: lighten(tomato, 20);
					}
				}
				&.edit-number-box {
					width: 120px;
				}
				&.edit-party-box {
					width: 200px;
				}
			}

			.remove-button {
				height: 42px;
				align-self: flex-end;
				//margin-top: 18px;
				border-radius: 3px;
				background-color: transparent;
				&:Hover {
					border: 1px solid rgba(0,0,0,.1);
					background-color: rgba(0,0,0,.1);
				}
			}
		}
	}
	.add-party-box {
		border-bottom: 1px solid rgba(0,0,0,.1);
		> div {
			padding: @gutter;
			align-items: center;
			text-align: center;
		}
		button {
			margin: auto;
			flex-grow: 0;
			flex-shrink:0;
			padding: 5px 15px;
			&:not(:hover) {
				border-color: transparent;
			}
		}
	}


	.flex-row {
		display: flex;
		flex-direction: row;
	}
}
/* ./src/js/modules/processes/common/views/create.less*/ 
.new-process-dialogue {
	display: flex;
	flex-direction: column;
	overflow: auto;
	> .new-process-main-form {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		column-gap: @gutter-half;
		padding: @gutter 0;
		> .column {
			width: 50%;
			display: flex;
			flex-direction: column;
			>.card.lined.with-border {
				margin: 0;
			}
			> .nested-employees {
				flex-grow: 1;
				flex-shrink: 1;		
				display: flex;
				flex-direction: column;
				overflow: auto;
				box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
				> :not(.plain) {
					flex-grow: 0;
					flex-shrink: 0;		
				}
				> .plain {
					overflow: auto;
					flex-grow: 1;
					flex-shrink: 1;		
					display: flex;
					flex-direction: column;
					> :not(.entity-employees) {
						flex-grow: 0;
						flex-shrink: 0;		
					}
					.entity-employees {
						overflow: auto;
						flex-grow: 1;
						flex-shrink: 1;		
						.scrollable-mixin();
					}
				}
			}
		}
	}
	> footer {
		flex-grow: 0;
		flex-shrink: 0;
		padding: @gutter-half 0;
	}
}
/* ./src/js/modules/processes/common/views/nested-processes.less*/ 
.page-content > .fdc-container:first-child .flex-dynamic-column > *.minus-header-and-footer > div.plain, .page-content > .fdc-container:first-child .flex-dynamic-column > *.minus-header-and-footer > ul {
    //height: calc(100% - 35px)
}
/* ./src/js/modules/processes/common/views/process-card.less*/ 
.mixin-process-item() {

	&.process-buy {
		&.notCompleted {
			background-color: @clr-bg-purchase;
			> button {
				background-color: @clr-bg-purchase;
			}
		}
		button.info > i {
			.icon('shopping-cart');	
			color: darken(@clr-bg-purchase, 50);
		}
	}

	&.process-productionLine {
		&.notCompleted {
			background-color: #f0e0f0;
			> button {
				background-color: #f0e0f0;
			}
		}
		button.info {
			> i {
				.icon('industry');
				color: #906090;
			}
		}
	}

}
.card.lined.process-card {

	border: 1px solid #00000030;

	.mixin-process-item();



	> .process {
		&.process-process {

			> button.info {
				padding-top:0;
				padding-bottom:0;
				> i {
					.icon('random');
				}
			}
		
		}

		&.process-buy {

			> button.info {
				padding-top:0;
				padding-bottom:0;
				> i {
					.icon('shopping-cart');	
					color: darken(@clr-bg-purchase, 50);
				}
			}
		
		}		

		&.failured {
			> div > span {
				color: fade(darken(darkred,10),80);
			}					
			> button.info {
				> i {
					color: tomato;
					.icon-add('thumbs-o-down');
				}
			}
		}
		&.successed {
			> div > span {
				color: @clr-success;
			}				
			> button.info {
				> i {
					color: @clr-success;
					.icon-add('thumbs-o-up');
				}
			}
		}
		&.canceled {
			> div > span {
				color: rgba(0,0,0, .4);
			}
			> button.info {
				> i {
					color: rgba(0,0,0, .1);
					.icon-add('ban');
				}
			}
		}	

	}
}


.ui-list-item.process {
	padding: 3px 0;
	.mixin-process-item();
	&:not(:first-child) {
		border-top: 1px solid #00000010;
	}
}
/* ./src/js/modules/processes/common/views/team-list.less*/ 
.entity-employees {
	.process-team.editMinor {
		background-color: azure;
		> * {
			background-color: azure;
		}
	}
	.process-team.admin {
		background-color: bisque;
		> * {
			background-color: bisque;
		}
	}
	.process-team.editMajor {
		background-color: beige;
		> * {
			background-color: beige;
		}
	}

}

/* ./src/js/modules/processes/goals/process-goal-plate.less*/ 
.process-goal-plate {
	
}

/* ./src/js/modules/product-production/builds/views/production-build.less*/ 
.build-card {
	.build {
		overflow: auto;
		.created {
			text-align: right;
			> b {
				.ff-small();
			}
		}
		.amount {
			width: 100px;
			flex-grow: 0;
			flex-shrink: 0;
		}
		.prod.fixed {
			max-width: 300px;
		}
		// .prod.fixed {
		// 	flex-grow: 2;
		// 	flex-shrink: 2;
		// }
		// .batch.elastic {
		// 	flex-grow: 1;
		// 	flex-shrink: 1;
		// }
	}
}
/* ./src/js/modules/product-production/cycles/views/cycle-list-item.less*/ 
.cycle-list-item {
	.type, .state {
		width: 150px;
	}
	.started {
		width: 85px;
	}
}
/* ./src/js/modules/reports/report-module.less*/ 
.report-module {

    .manager .entries-manager{
        padding: 15px 5px;        
    }



    .entries {
        padding: 15px 0;
    }


    .entries-container {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        overflow:auto;
    }


    .types-switcher {
        flex-grow: 0;
        flex-shrink: 0;
        padding-top: 15px;
        //padding-bottom: 1px;
        button {
            height: 46px;
            border: none;
            border-radius: 0;
            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &.active {
                background-color: @clr-main-bg;
                color: white;
            }
        }
    }


    .report-entries-list {
        position: relative;
        padding-top: 32px!important;
        background-color: white;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        flex-grow: 1;
    }

    .report-entry-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        > * {
            padding:5px;
            .ff-system();
            flex-shrink:0;
            width: 150px;
            &:not(.clean) {
                small {
                    color: #777;
                    padding-left: 5px;
                }
                big {
                    color: royalblue;
                }
                &.negative {
                    big {
                        color: tomato;
                    }
                }
                &.positive {
                    big {
                        color: seagreen;
                    }
                }
            }
        }

        > .value-with-unit {
            small {
                color: #777;
                padding-left: 5px;
            }
            &.negative {
                big {
                    color: tomato;
                }
            }
            &.positive {
                big {
                    color: seagreen;
                }
            }
        }



        

        .product-fullName {
            width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow:hidden;
        }


        .product-origin.purchase {
            i {                
                .icon-b('truck');
                color: tomato;
            }
        }
        .product-origin.production {
            i {
                .icon-b('industry');
                color: darkolivegreen;
            }
        }

        .product-purpose.sale:not(.production) {
            i {
                .icon-b('money');
                color: orange;
            }
        }
        .product-purpose.production {
            i {
                .icon-b('industry');
                color: darkolivegreen;
            }
            &.sale {
                i {
                    .icon-a('money');
                    display: block;
                    position: relative;
                    &:after {
                        position: absolute;
                        color: orange;
                        bottom: 0;
                        left: 8px;
                        line-height: 16px;
                        width: 16px;
                    }
                    &:before {
                        position: absolute;
                        color: darkolivegreen;
                        top: 0;
                        left: 8px;
                        line-height: 16px;
                        width: 16px;
                    }
                }
            }
        }        

        .product-origin, .product-purpose {
            width: 32px;
            i {
                width: 32px;
                height: 32px;
                display: block;
                line-height: 32px;
                padding: 0;
            }
        }

        &:not(.header) {
            .product-origin, .product-purpose {
                padding: 0;
            }


            .valuable-store-change {
                background-color:lighten(darkseagreen, 30);
            }

        }

        .amount {
            width: 120px;
            //font-weight: 700;
            big {
                color: royalblue;
            }
            small {
                color: #777;
                padding-left: 5px;
            }
        }

        > * {
            height: 32px;
            line-height: 22px;
        }

        &:not(:first-child) {
            > * {
                border-top: 1px dotted rgba(0,0,0,.3);
            }
        }

        &:hover {
            cursor: pointer;
            > * {
                background-color: rgba(0,0,0, .1)!important;
            }
        }

        &.no-batches {
            > * {
                background-color: #90609010;
            }
        }

    }

    .report-entry-item.header {
        // position: fixed;
        // top: 53px;
        position: absolute;
        top: 0px;
        background-color: darken(#337ab7,15);
        color: white;
        border-radius: 3px;
        .ff-system();
        font-weight:700;
        .purpose, .origin {
            text-align:center;
        }
    }
}
/* ./src/js/modules/resources/production/products/views/add-party.less*/ 
.bg-lightblue-mixin() {
    background-color: lighten(royalblue, 40);
}
.bg-lightorange-mixin() {
    background-color: lighten(orange, 40);
}

.gray-text-mixin() {
    color: #678;
    i, span {
        color: #678;
    }
}

.big-text-mixin()
{
    span {
        font-size: 22px;
    }
}

.card.lined.with-border.add-product-contragent {
    .bg-lightblue {
        .bg-lightblue-mixin();
    }
    .bg-lightorange {
        .bg-lightorange-mixin();
    }    
    .gray-text.gray-text.gray-text.gray-text {
        .gray-text-mixin();
    }
    .big-text.big-text.big-text.big-text.big-text {
        .big-text-mixin();
    }
    .bg-white-separator {
        background-color: white;
        color: white;
        * {
            color: white;
        }
    }
}
/* ./src/js/modules/resources/production/products/views/product-contragent.less*/ 
.product-contragent.product-contragent.product-contragent.product-contragent.product-contragent {
	&.contragent {
		.info > i {
			.icon('user-secret');
		}
	}
	&.product {
		.info > i {
			.icon('shopping-bag');
		}
	}
	> span {
		flex-grow:0;
		flex-shrink:0;
		width:auto;
	}
	> div {
		flex-grow:1;
	}
}

/* ./src/js/modules/resources/production/products/views/product-list-item.less*/ 
.modelView.card-line.whole-btn.production-item {
	&.abstract {
		background-color: fade(#906090, 40);
	}
	&.notmeasured {
		background-color: fade(tomato, 10);
	}

	&.sale {
		background-color: fade(cornflowerblue, 10);
	}

	&.archived {
		background-color: #aaa!important;
		color: #fff;
		* {
			background-color: #aaa!important;
			color: #fff!important;	
		}
	}
	&.deleted {
		background-color: #444!important;
		color: #777;
		* {
			background-color: #444!important;
			color: #777!important;	
		}
	}

	> .ui-list-item-main {
		> span {
			i {
				font-size: 10px;
				vertical-align: middle;
			}
		}
	}

	&.production {
		> .ui-list-item-main {
			> span {
				i {
					.icon('industry');
					color: darkolivegreen;
				}
			}
		}
	}
	&.purchase {
		> .ui-list-item-main {
			> span {
				i {
					.icon('truck');
					color: tomato;
				}
			}
		}
	}

}

/* ./src/js/modules/staff/emps/views/attach-user.less*/ 
.attach-user-to-employee {
	font-size: 0;
	> div {
		display:inline-block;
		width:50%!important;
		font-size: 16px;
	}
	.select-one {
		text-align:right;
		.ff-header();
	}
}

/* ./src/js/modules/staff/emps/views/employee-card.less*/ 

.card .employee {	
	
	//border-radius:4px;

	// > *:first-child{
	// 	border-top-left-radius: 4px!important;
	// 	border-bottom-left-radius: 4px!important;
	// }

	// > *:last-child{
	// 	border-top-right-radius: 4px!important;
	// 	border-bottom-right-radius: 4px!important;
	// }

	> *:not(:Hover) {
		background-color:transparent!important;
	}


}

.employee.employee.employee.employee {

	> button.info {
		i {
			font-weight:400;
			.icon('user-circle-o');
		}
	}


	> div > span {
		color: @clr-blue;
	}



	&.virtual {

		> div > span {
			color:#888;
		}
		> button.info i {
			.icon('info');
		}
	}

	&.intern {
		> button.info i {
			.icon('user-o');
		}
	}

	&.fired {
		background-color:fade(tomato,10);
		> div {
			> * {
				text-decoration: line-through;
			}
		}
		> button.info i {
			.icon('times');
		}
	}

	&.off {
		background-color:fade(tomato,10);
	}


	.ui-list-item-main {
		> span {
			&::first-letter {
				//color: #73baf7;
				color: #048;
			}
		}
	}


	/*
	&.employee.employee > div > span {
		color: @clr-blue;
	}
	button.info.info.info {
		&:Hover{
			background-color:transparent;
			cursor:default;
		}
		i {
			color: @clr-blue;
			.icon('user-circle-o');
		}
	}
	&.virtual {
		&.employee.employee > div > span {
			color:rgba(0,0,0,.4);
		}		
		button.info.info.info i {
			.icon('user-o');
			color:rgba(0,0,0,.1);
			font-weight:100;
		}
		> div > span {
			font-weight:100;
		}
	}
	&.intern {
		button.info.info.info i {
			font-weight:100;
			color:rgba(0,0,0,.4);
		}
		> div > span {
			font-weight:400;
		}		
	}
	&.off {
		background-color:rgba(100,0,0,.1);
	}
	&.fired {
		> div * {
			
			text-decoration: line-through;
		}		
	}
	*/
}

/* ./src/js/modules/staff/emps/views/entity-employees.less*/ 

.nested-employees {
	display:flex;
	flex-direction: column;

	background-color: #fff;
	> header {
		flex-grow:0;
		flex-shrink:0;
		.ff-header();
		padding: (@gutter / 2);
		height: 35px;
		border-top-right-radius: 4px;
    	border-top-left-radius: 4px;
	}
	> .plain {
		flex-grow:1;
		flex-shrink:1;
	}
	> .action {
		flex-grow:0;
		flex-shrink:0;
	}
}

.entity-employees {
	overflow-y: auto;
	height: 100%;
	.scrollable-mixin();
	.employee.lead {
		background-color: @clr-light-yellow;
	}
}

/* ./src/js/modules/staff/emps/views/inline-employee.less*/ 
.inline-employee {
	display: inline-block;
	.ff-header();
	font-weight:500;
	text-transform: uppercase;
	font-size:10px;

	padding:5px;
	padding-bottom:2px;
	border-radius: 2px;


}

/* ./src/js/modules/tasks/views/create-task.less*/ 
.card.lined.create-task.in-modal{
	box-shadow:none;
	//margin:0 -@gutter;
	.card-line {
		&.whole-btn{
			&:Hover{
				//border:none;
				background-color:rgba(0,0,0,.1);
				box-shadow: none;
				border-radius:0;
			}
		}
		padding:0 (@gutter / 2);
	}
}

.create-task {
	// > * {
	// 	margin-bottom: @gutter;
	// }
	.content {
		padding: @gutter 0;
	}
	.desc-place {
		margin-bottom: @gutter;
	}
	.checklist-place {
		> .card.lined {
			margin:0;
		}
	}
	.main-tab-place {
		> * {
			margin:0;
			margin-bottom: @gutter;
		}
	}

	.props-place {
		margin:0;
		margin-bottom: @gutter;
	}

	.flex-line {
		display: flex;
		width: 100%;

		.type-place {
			flex-grow:0;
			flex-shrink:0;
		}
		.todo-place {
			flex-grow:1;
			flex-shrink:1;
		}

	}
}

.edit-transport-details.in-modal{
	//padding: @gutter;
	> div {
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
	.props-place.card.lined {
		margin: @gutter;
	}
	.sign-plus.add-item { margin: @gutter; }
}

.flex-modal-wrapper.task-create-modal {
	.create-task.in-modal {

		// .media-desktop-down({
		// 	width: 100%;
		// 	height: 100%;
		// });
		// .media-desktop-up({
		// 	min-width: 800px;
		// 	min-height: 500px;
		// });		

	}
}
/* ./src/js/modules/tasks/views/list-item.less*/ 
@keyframes BLINKCOLOR {
	50% {
		color: rgba(0,0,0,.1);
	}
}

@keyframes BLINKBGCOLOR {
	50% {
		color: rgba(0,0,0,.2);
		background-color: rgba(0,0,0,.1);
	}
}


.task-list-item{
	&.task-list-item.task-list-item.common > .info > i { .icon-b('cog') }
	&.task-list-item.task-list-item.call .info i { .icon-b('phone') }
	&.task-list-item.task-list-item.email .info i { .icon-b('envelope') }
	&.task-list-item.task-list-item.outOfOffice .info i { .icon-b('car') }

	&.on-contragent.task-list-item.task-list-item .info i { .icon-add('user-secret', block); color:tomato; }
	&.on-process.task-list-item.task-list-item .info i { .icon-add('random', block); color:green; }

	&.past .two-line-date * { color: tomato; }
	&.today:not(.past) .two-line-date * { color: olivedrab; }
	&.near .two-line-date * { color: teal; }

	> div > b {
		text-transform: uppercase;
		font-size:9px!important;
	}
	> div {
		position:relative;
	}
	> div > i,  > div > b{
		width:~"calc(100% - @{gutterhalf})";
		display:flex;
		justify-content: space-between;
	}


	&:not(.actable){
		opacity:.4;
		background-color:rgba(0,0,0,.1);
	}

	&.my.my.my > div {
		> span {
			color: teal;
		}
		// > b {
		// 	color:teal;
		// }

	}
	&.checker.checker.checker > div > b {
		color:tomato;
	}
	&.strict {
		.two-line-date{
			.blinker();
		}
	}
	&.initial {
		background-color: antiquewhite;
	}
	&.onHold {
		background-color: gainsboro;
	}
}



.card.lined.task {

	&.initial {
		background-color: cornsilk;
	}

	&.my.strict-date:not(.closed) {
		> .system.card-line > .date {
			> * {
				animation: BLINKCOLOR .5s infinite;
			}
		}
	}


	&.inProcess.past {

		> .system.card-line > .date {
			color: tomato;
			> * {
				color: tomato;
			}
		}
	}

	> .card-line {

		> * {
			margin:2px;
			padding:0;
			box-sizing: border-box;
		}
		> .inline-item {
			width:auto;
		}
	}



	> .system.card-line {
		display:flex;
		flex-flow: row;
		flex-wrap: nowrap;
		align-items: center;
		&.system.system{
			width: auto;
		}
		> * {
			width: auto;

			//padding:2px;
			border-radius: 4px;
		}
		> .date {
			display:inline-block;
			flex-grow:0;
			flex-shrink:0;
			//height: 1em;
			//width: 80px;
			> * {
				width: auto;
				display:inline-block;
				padding:0;
				&.time {
					font-size: 13px;
					margin-left: 4px;
					//padding-bottom:1px;
				}
			}
		}
		> .responsible, > .checker, > .state, > .department, > .duration, > .priority {
			padding:2px 4px;
			background-color:rgba(128,128,128,.1);			
			.ff-header();
			font-size: 10px;
			font-weight: normal;
		}
		> .department {
			background-color:#603060;
			color: white;
			max-width: 50px;
			overflow: hidden!important;
			text-overflow: ellipsis;
			white-space: nowrap!important;
		}
		> .state {
			&.onHold {
				color: white;
				background-color: black;
			}
			&.closed.succeeded {
				background-color: darkseagreen;
				color: white;
			}
			&.onChecking {
				background-color: orange;
				color: white;
			}
			&.initial {
				background-color: bisque;
			}
		}
	}	

	> .entities.card-line {
		&.entities.card-line {
			flex-flow: row;
			flex-wrap: wrap;
		}
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		align-items: center;
		width:auto;

		> .task-entity {
			flex-grow: 0;
			padding:0;
			width: auto;
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			align-items: center;
			width:auto;
			border-radius: 4px;
			background-color: rgba(120,40,80,.1);
			align-items: center;
			max-width: ~"calc(50% - 6px)";
			.icon {
				width:15px;
			}
			.icon i {
				font-size: 10px;
				width:14px;
			}
			.icon.contragent i { .icon('user') }
			.icon.process i { .icon('cog') }

			&.checkerId {
				background-color: orange;
				i { .icon('user-circle-o'); }
			}
			&.creatorId {
				//background-color: orange;
				i { .icon('user-circle-o'); }
			}
			&.deadline {
				i { .icon('calendar-check-o'); }
			}
			> .name {
				display: inline-block;
				width: 100%;
				padding: 0;
				font-weight: normal;
				font-size: 12px;
				padding-right: 4px;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}

	&.my.strict-date {
		&.deadline-near {
			> .entities.card-line {
				> .task-entity {
					&.deadline.deadline {
						animation: BLINKBGCOLOR .5s infinite;
					}
				}
			}
	
		}
		> .entities.card-line {
			> .task-entity {
				&.deadline.deadline {
					background-color: tomato;
					color: white;
					animation: BLINKBGCOLOR .5s infinite;
					* { color: white }
					//i { .icon('calendar-check-o'); }
				}
			}
		}

	}


	.icon, .card-line .icon {
		width: 24px;
		flex-grow: 0;
		flex-shrink: 0;
		display:flex;
		align-items: center;
		padding: 0;
		i {
			width: 24px;
			//height: 24px;
			font-size: 16px;
			padding:2px;
			color: rgba(0,0,0,.2);
		}

	}


	> .process {
		.icon {
			//icon('process')
		}
		.process-name {
			line-height: 1;
			font-weight:bold;
			font-size: 12px;
			font-style: italic;
			color: #444;
		}
	}

	> .subject {
		.toDo {
			.ff-header();
			font-size: 14px;
		}
	}
	
	&.not-my > .subject > .toDo {
		color: #555;
	}
	&.my > .subject > .toDo {
		color: teal;
	}
	&.my .responsible {
		color: teal;
	}
	&.closed.succeeded {
		background-color: lighten(darkseagreen,30);
	}
	&.closed.failed {
		background-color: lighten(tomato,33);
	}
	&.closed.canceled {
		background-color: #eee;
	}

	> .lastComment {
		.lastComment-text {
			display: block;
			font-size:12px;
			font-style: italic;
			color: rgba(0,0,0,.6);
			em.author {
				display: inline-block;
				font-style: normal;
				color: black;
				font-size: 12px;
				.ff-header();
			}
			max-height: 2.3em;
			&.lastComment-text.lastComment-text {
				overflow: hidden;
			}
		}
	}

	.icon.options {
		i { 
			.icon('ellipsis-v'); padding:4px; 
		}
		&:Hover {
			background-color: rgba(0,0,0,.1);
			color: cornflowerblue;
		}
	}
	.icon.comment {
		i { .icon('comment'); }
	}
	.icon.type {
		&.common { i { .icon('cog'); } }
		&.outOfOffice { i { .icon('car'); } }
	}


	.task-list-item.whole-btn {
		&:Hover {
			box-shadow: none;
		}
	}
	.task-list-item {
		&.initial + *:not(.ui-list-item) {
			background-color: antiquewhite;
		}
		&.onHold + *:not(.ui-list-item) {
			background-color: gainsboro;
		}
		
	}


	.lastComment {
		> div > span {
			max-height: 42px;
			overflow: hidden!important;
			text-overflow: ellipsis!important;
			margin-bottom: 15px!important;
			padding:0!important;
			font-weight: 300!important;
			font-size: 13px!important;
		}
		> div > small {
			color: teal!important;
		}
	}	


	&.form-transportRequest {
		background-color: darken(aliceblue, 1);
		> .subject {
			> .type.icon > i {
				color: darken(darkblue, 20);
			}
			> .toDo {
				color: darken(darkblue, 20);
			}
		}
	}
	
	&.form-purchaseRequest {
		background-color: @clr-bg-purchase;
		> .subject {
			> .type.icon > i {
				color: darken(cornflowerblue, 20);
				.icon('shopping-cart');
			}
			> .toDo {
				color: darken(cornflowerblue, 20);
			}
		}
	}


	&.for-department.responsibless {
		background-color: lighten(deepskyblue, 30)
		//lighten(darkslateblue, 40)
		//lighten(darkolivegreen, 30)
	}

}




/* ./src/js/modules/tasks/views/purchaseRequest/in-task/purchase-block.less*/ 
.card.purchaserequest-container.transparent-header {
	> .purchase-request-header.purchase-request-header.purchase-request-header {
		height: 35px;
		padding: 0;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		background-color: fade(@clr-main-bg,15%);
		border-bottom: 2px solid @clr-main-bg;
		> button.header-button.header-button.header-button {

			padding: 0 (@gutter / 2);
			margin: 0;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			&.active {
				color: white;
				background-color: @clr-main-bg;
			}			
		}


	}
	> .purchase-processes-tab.plain {
		.processes-place {
			padding: @gutter 0;
			.ui-list-item {
				display: flex;
				border-radius: 3px;
			}
			.proc-item {
				.products {
					display: block;
					.product {
						font-size: 14px;
						display:flex;
						flex-direction: row;
						> button.close {
							flex-grow: 0;
							flex-shrink: 0;
							width: unset;
						}
						span {
							color: cornflowerblue;
						}
					}
				}
			}
		}
		.buttons-place {
			display: block;
			> div {
				display: block;
				.header-button.disabled {
					cursor: not-allowed;
					background-color: #eee;
					border-color: #eee;
					&:Hover {
						background-color: #eee;
						color: #444!important;
					}
				}
			}
		}
	}


	.purchaseitems {
		.empty-content {
			header {
				line-height: 35px;
				.ff-header();
			}
		}
		.purchaseitem-item {
			.props-place {
				.ui-list-item {
					flex-direction: row;
				}
			}
			.joined-contragents {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 4px;

				.join-info {
					// OLD:
					// display: inline-block;
					// padding: 2px 10px;
					// border-radius: 2px;
					// background-color: fade(darkcyan, 40);
					// color: white;
					// .ff-header();
					// cursor: pointer;
					// margin-right: 5px;
					// margin-bottom: 5px;
					// width: auto;


					// &.ui-list-item {
					// 	flex-direction: row;
					// 	border: none;
					// 	padding: 2px;
					// 	flex-grow: 0;
					// 	flex-wrap: nowrap;
					// 	width: auto;
					// 	margin: 2px;
					// 	background-color: fade(darkcyan, 40);
					// 	> span {
					// 		padding: 0;
					// 		flex-shrink: 0;
					// 	}
					// }


					&.item {
						position: relative;
						//display: inline-block;
						display: flex;
						flex-direction: row;
						flex-grow: 0;

						width: 100%;
						padding: 2px;
						margin: 2px;
						background-color: fade(darkcyan, 10);
						&:hover {
							background-color: fade(darkcyan, 20);
						}
						vertical-align: middle;
						border-radius: 3px;
						box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
						cursor: pointer;
						> span {
							position: relative;
							display: inline-block;
							padding: 0;
							width: unset;
							flex-grow: 1;
							&.info-entry {
								width: 70px;
								text-align:center;
								flex-grow: 0;
							}
							> i {
								width: 100%;
								font-style: normal;
								font-size: 10px;
								font-family: 'Fira Sans Extra Condensed', sans-serif;
								font-weight: 500;
								position: absolute;
								color: rgba(0, 100, 200, 0.6);
								top: -1px;
								display: block;
							}
							> b {
								width: 100%;
								font-style: normal;
								font-size: 10px;
								font-family: 'Fira Sans Extra Condensed', sans-serif;
								font-weight: 500;
								position: absolute;
								//color: white;
								bottom: -1px;
								display: block;
							}							
							> span {
								display: inline-block;
								padding: 10px 0;
							}
						}
					}
				}
			}
		}
	}
}

/* ./src/js/modules/tasks/views/purchaseRequest/purchaseitem-item.less*/ 
.purchaseitem-lineitem, .card.lined > *:not(.plain):not(ul) > .purchaseitem-lineitem {
	padding: 0 (@gutter / 2);
	

	.purchaseitem-item {
		display: flex;
		flex-direction: row;
		margin-left: 0;
		margin-right: 0;
		.joined-contragents {
			.join-info.item {
				.info-entry {
					> b {
						white-space: nowrap;
					}
				}
			}
		}
	}
}

// .transportpoint-item {
// 	padding: 0;
// }

// .transportpoint-props {
// 	.contact-value {
// 		font-size: 14px;
// 		.ff-header();
// 		display: inline-block;
// 		line-height: unset;
// 		margin-bottom: 4px;
// 		margin-right: 4px;
// 		padding: 3px;
// 		border-radius: 2px;
// 		background-color: lighten(cornflowerblue, 30);
// 	}
// }

/* ./src/js/modules/tasks/views/purchaseRequest/purchaserequest-container.less*/ 
.purchaserequest-container.minus-header-and-footer.double-width.card.lined.with-border {
	flex-direction: column;
	> * {
		flex-grow: 0;
		flex-shrink: 0;
		&.purchaseitems {
			flex-grow: 1;
			flex-shrink:1;
			display: flex;
			flex-direction: column;
			// align-items: flex-start;
			// justify-content: start;
			> .purchaseitem-lineitem {
				flex-grow: 0;
				flex-shrink: 1;
			}
		}
	}
}

/* ./src/js/modules/tasks/views/todo.less*/ 
.check-list-item {
	&.unchecked > button.checked > i {
		.icon('square-o');
	}
	&.checked > button.checked > i {
		.icon('check-square-o');
	}

	> button.orderer {
		color:#aaa;
		.icon('arrows-v');
		cursor:move!important;
	}

	> button.product {
		color: @clr-main-back;
		cursor: pointer;
		.icon('external-link-square');
	}

	&.checked > div > span {
		text-decoration: line-through;
		color:#aaa!important;
		font-weight: 300!important;
	}
	> div > b {
		> span + span {
			margin-left: 10px;
		}
	}
}

/* ./src/js/modules/tasks/views/transportRequest/transportpoint-item.less*/ 
.transportpoint-lineitem, .card.lined > *:not(.plain):not(ul) > .transportpoint-lineitem {
	padding: 0;
	.transportpoint-item {
		.transportpoint-where {
			//border-bottom: 1px solid rgba(0,0,0,.1);
			padding: (@gutter / 2);
			
			.ff-header();
		}
		.transportpoint-what.ui-list {
			.ui-list-item {
				border: none;
				> div {
					> i {
						font-weight: bold;
						font-size: 12px;
					}
				}
			}
		}
		.transportpoint-badges {
			padding: (@gutter / 2);
			.transportpoint-badge {
				display:inline-block;
				padding: 1px 4px;
				border-radius: 2px;
				.ff-header();
				font-size: 12px;

				&.workHours {
					background-color: darkcyan;
					color: #fff;
				}
				&.notifyFirst {
					background-color: brown;
					color: #fff;
				}
				& + * {
					margin-left: (@gutter / 2);
				}
			}
		}
	}
}

.transportpoint-item {
	padding: 0;
}

.transportpoint-props {
	.contact-value {
		font-size: 14px;
		.ff-header();
		display: inline-block;
		line-height: unset;
		margin-bottom: 4px;
		margin-right: 4px;
		padding: 3px;
		border-radius: 2px;
		background-color: lighten(cornflowerblue, 30);
	}
}

/* ./src/js/pages/contragents/search/contragents-list.less*/ 
.contragents{
	height:100%;
	
	.content-region{
		height:100%;
	}

	.contragents-list.flex-dynamic-column
	{
		> * {
			min-width:300px;
			margin:(@gutter / 2)!important;
		}
	}
}

/* ./src/js/pages/default/page-card.less*/ 
@page-card-width:300px;

.pages-cards {
	display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	margin:0 -@gutter;
}
.page-card, .pages-cards .card{
	.card-mixin();
	.as-button-mixin();
	min-width: (@page-card-width / 3 * 2);
	width: @page-card-width;
	margin: @gutter;
	&.my {
		background-color: lighten(darkseagreen, 25);
		background-color: lighten(antiquewhite, 4);
	}
}

/* ./src/js/pages/icons.less*/ 
.page-link-card {
	header {
		text-transform: uppercase;
		color: teal;
		font-size: 12px;
	}
	> i.icon {
		font-style:normal;
		width: 100%;
    display: block;
		font-size: 72px;
		color:teal;
		text-align:center;
		
		&.edit {
			.icon('edit');
		}

		&.sources {
			.icon('share-alt-square');
		}
		&.reports {
			.icon('columns');
		}
		&.requests {
			.icon('list-alt');
		}
		&.search{
			.icon('search');
		}
		&.create {
			.icon('plus');
		}
		&.board{
			.icon-a('trello');
		}
		&.contragents {
			.icon-a('user-secret');
		}
		&.tasks {
			.icon-a('clock-o');
		}
		&.staff, &.groups {
			.icon('users');
		}
		&.resources {
			.icon('book');
		}
		&.production, &.products {
			.icon('shopping-bag');
		}		
		&.production-types {
			.icon('tags');
		}	
		&.measures {
			.icon('balance-scale')
		}

		&.administrators {
			.icon('user');
			.icon-add('key');
		}
		&.config {
			.icon('cogs');
		}
		&.config.smtp {
			.icon('envelope');
		}		

		&.staff-deps {
			.icon-a('sitemap');
		}		
		&.staff-emps {
			.icon-a('group');
		}		
		&.identity {
			.icon-a('user-circle-o');
		}
		&.identity-changepass {
			.icon-a('key');
		}
		&.admin {
			.icon-a('cogs');
		}

		&.processes {
			.icon-a('random');
		}

		&.processes-active {
			.icon-a('random');
		}
		&.processes-closed {
			.icon-a('stop');
		}

		&.processes-start {
			.icon-a('play');
		}

		&.store {
			.icon-a('cubes');
		}
		&.purchase {
			.icon-a('truck');
		}
		&.product-production {
			.icon-a('industry');
		}
		&.board {
			.icon-a('trello');
		}		
	}


	&.card {

		&.no-icon {
			> .icon {
				display: none;
			}
			.card-caption {
				display: none;
			}
			> .card-header {
				padding: 0;
				margin: 0;
				height:60px!important;
				font-size: 14px;
			}
		}

	}

}

/* ./src/js/pages/processes/process/process-page.less*/ 
//@import 
.process-page {
	.main-info-block {
		&.successed {
			background-color: darkseagreen;
		}
		&.canceled {
			background-color: gainsboro;
		}
		&.failured {
			background-color: darksalmon;
		}
	}
	.card.lined.goals-block {
		> header.header-with-buttons {
			> span {
				flex-grow: 0;
				flex-shrink: 0;
				width: auto;
			}
			> a {
				cursor: pointer;
				font-size: 9px;
				text-decoration: underline;
				padding: 3px;
				border-radius:2px;
				&:hover {
					background-color: rgba(0,0,0,.1);
				}
				&.active {
					background-color: darkcyan;
					color: white;
				}
			}
			> :not(i) {
				flex-grow: 0;
				flex-shrink: 0;
				align-self: center;
			}
			> i {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

	}
}

.goals-block {
	> .header-with-buttons {
		> span {
			flex-grow: 0;
			flex-shrink: 0;
			width: auto!important;
		}
		> a {
			cursor: pointer;
			font-size: 9px;
			text-decoration: underline;
			padding: 3px;
			border-radius:2px;
			&:hover {
				background-color: rgba(0,0,0,.1);
			}
			&.active {
				background-color: darkcyan;
				color: white;
			}
		}
		> :not(i) {
			flex-grow: 0!important;
			flex-shrink: 0;
			align-self: center;
		}
		> i {
			flex-grow: 1;
			flex-shrink: 1;
		}		
	}

	.goal-item {
		display: flex;
		flex-direction: row;
		&.closed {
			> button.checker {
				i {
					.icon-b('check-square-o');
					color: green;
				}
			}
			> div > span {
				text-decoration: line-through;
				color: #aaa;
				font-weight: normal;
			}
		}

		&.canceled, &.failed {
			> button.checker {
				i {
					.icon-b('ban');
					color: rgba(0, 0, 0, 0.2);
				}
			}				
			> div {
				> span, > i {
					color: #ccc;
				}
				> b {
					//color: tomato;
				}
			}
			> button.checker {
				&:Hover {
					color: rgba(0, 0, 0, 0.2);
					> i {
						color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}

		&.optional {
			> div > span {
				color: #aaa;
				font-weight: normal;
			}
		}
		&.has-joined {
			&:not(.canceled):not(.failed) {
				> div > span {
					color: royalblue;
					//font-weight: normal;
					cursor: pointer;
				}

			}
			> div > span {
				cursor: pointer;
			}
			> button.checker.checker {
				cursor: not-allowed;
				&:Hover {
					background-color: transparent;
					color: rgba(0, 0, 0, 0.2)!important;
					> i {
						color: rgba(0, 0, 0, 0.2)!important;
					}
				}
			}
		}
		button[data-role="skip:optional"] {
			i {
				.icon-b('times');
				color: red;
			}
		}
		button[data-role="accept:optional"] {
			i {
				.icon-b('check');
				color: green;
			}
		}
		button[data-role="create:joined:task"] {
			i {
				.icon-b('clock-o');
				font-size: 18px;
				//color: green;
			}
		}			
	}

}


.process-entity {

	&.successed, &.canceled, &.failured {
		.subprocesses-buttons {
			display: none;
		}
		.entity-page-content .sub-processes {
			padding-top: 0;
		}
	}


	.entity-page-content {
		.goals-block {
			> .header-with-buttons {
				> span:first-child {
					margin-right: 15px;
				}
				> a {
					font-size: 12px;
					margin: 0 4px;
				}
			}
		}


		.sub-processes {
			padding: @gutter 0;
			row-gap: @gutter-half;
			column-gap: @gutter-half;
			display: flex;
			flex-direction: column;
			overflow: auto;
			.scrollable-mixin();
			> .ui-list-item {
				background-color: white!important;
				border-radius: 4px;
			}
		}

		.separate-buttons {
			[data-action=join-exist] {
				display: none;
			}
		}

	}
}

.dashboard-items {

	.media-tablet-up({
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: @gutter;
		column-gap: @gutter;
		padding: @gutter-half;
		margin: 0;
	});

	.ui2-block.dashboard-block {
		width: 100%;
		.media-tablet-up({
			width: calc(50% - 7.5px);
			height: calc((100vh / 3) - 50px);
			margin: 0;
		});
		> section {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			overflow: auto;
			> :first-child {
				overflow: auto;
				.scrollable-mixin();
			}
		}
		> header, > footer {
			flex-grow: 0;
			flex-shrink: 0;
			background-color: azure;
			color: @clr-main-back;
			a {
				color: @clr-main-back;
				//background-color: rgba(0,0,100,.1);
			}
			&:first-child {
				border-bottom: 1px solid rgba(0,0,0,.1);
			}
			&:last-child {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			//fade(, 10);
		}
		> footer {
			text-align: right;
			a {
				display: inline-block;
				padding: @gutter-half @gutter;
				margin: 2px;
				border-radius: 4px;
				&:Hover {
					background-color: rgba(0,0,0,.1);
				}
			}
		}
	}
}

/* ./src/js/pages/processes/process/production/blocks/contragent-components-block.less*/ 
.contragent-components-block {
	.contragent-components-list {
		overflow: auto;
		flex-grow: 1;
		flex-shrink: 1;		
		.contragent-component {
			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			.withdraw {
				> i  {
					.icon('plus')
				}
			}
			.take {
				> i  {
					.icon('minus')
				}
			}
			button.disabled {
				cursor: not-allowed;
				color: rgba(0,0,0,.5);
				opacity: .5;
				&:Hover {
					background-color: transparent;
				}
			}
			button {
				flex-grow: 0;
				flex-shrink: 0;
			}
			.ui-list-item-main {
				flex-grow: 2;
				flex-shrink: 2;
			}
			.amount {
				width: 100px;
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		.contragent-component-2 {
			.amount {
				width: 100px;
			}
			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			.product {
				align-self: center;
			}
		}

	}
}

.accept-production-dialogue {

	.amount-box {
		input.invalid {
			background-color: fade(tomato, 20);
		}
	}

	.batches-list {
		.production-component-details {
			width: 100%;
			padding:5px 0;
			&.invalid  {
				background-color: fade(tomato, 20);
			}
			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			.name {
				.ff-header();
				color: @clr-blue;
				margin-bottom: 5px;
			}
			.info {
				display: flex;
				flex-direction: row;
				width: 100%;
				position: relative;
				> span {
					flex-grow: 1;
					padding: 4px;
					position: relative;
					> span {
						display: block;
						padding-top: 9.5px;
						padding-bottom: 7.5px;
					}
					i {
						position: absolute;
						top: 0;
						left: 4px;
						font-size: 10px;
						font-weight: bold;
						//color: @clr-main-blue;
						font-style: normal;
					}
					span + small {
						display: inline-block;
						margin-left: .2em;
					}
					.available-amount, .writeoff-amount {
						font-size: larger;
					}
					.writeoff-amount-store {
						font-size: smaller;
						color: #555;
					}
				}
				> span:first-child {
					flex-grow: 0;
					width: 150px;
					select {
						width: 100%;
					}
				}
				> span:last-child {
					text-align: right;
					i {
						left: unset;
						right: 4px;
					}
				}
			}
		}
	}
}

/* ./src/js/pages/processes/process/production/blocks/cycle-info-block.less*/ 



.process-page {
    
    .cycle-info-block {
    
        .product-name-line {



        }
    }
}
/* ./src/js/pages/processes/process/production/blocks/eccept-ready-product.less*/ 
.accept-production-modal.yat-modal-wrapper {
    .yat-modal-content-wrapper {
        max-width: 1200px;
        .edit-value-control {
            .accept-ready-product {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .ui-list-item:Hover {
                cursor: unset;
            }
        }
    }
}

.accept-production-modal.flex-modal-wrapper {

	.edit-value-control.in-modal {
		justify-content: center;
		.accept-ready-product {
			overflow-y: auto;
		}
	}
}


.accept-ready-product {
    margin-bottom: @gutter;
    // margin-left: -@gutter;
    // margin-right: -@gutter;

    .view-separator {
        height: @gutter;
        background-color: rgba(0,0,0,.1);
    }

    .produce-amount {
        margin-top: @gutter;
        justify-content: center;
        .ui-list-item-main {
            padding: 0 5px;
            max-width: 400px;
        }
        input {
            width: 100%;
        }
    }


    .component-holder {
        .add-party i {
            .icon('plus');
            color:olivedrab;
        }
        > .numbers {
            flex-grow: 0;
            flex-shrink: 0;
            width: 75px;
            > span {
                font-size: 12px;
            }
        }
    }

    .cycle-components {
        padding: 5px 0;
    }

    .cycle-component {
        padding: 5px 0;

        &:not(:first-child) {
            border-top: 1px solid rgba(0,0,0,.1);
        }

        .parties {
            //padding-left: @gutter2;
            //padding-right: 5px;
        }
        .cycle-component-parties {
            .no-party {
                text-align:center;
                cursor: default;
                .ui-list-item-main span {
                    color:#aaa;
                }
            }
            .cycle-component-party {
                .ui-list-item-main {
                    text-align: right;
                }
								.concrete {
									margin-right: 7.5px;
									// font-weight: 400;
									// font-size: 12px;
									color: darkslategray;
								}
                input {
                    display: block;
                    height: 46px;
                }

                button.remove i {
                    .icon('remove');
                    color: darkred;
                }
            }
        }
    }


    .all-confirmed {
        &.all-confirmed.all-confirmed.all-confirmed.all-confirmed.ui-list-item:Hover {
            background-color: rgba(0,128,0,.1);
            cursor: pointer;
        }
        .ui-list-item-main {
            text-align: right;
        }
        .checkbox-holder {
            text-align: left;
            padding: 15px 0;
            input {
                display: block;
                cursor: pointer;
            }
        }
    }

}


.enter-new-batch-id {
    // display: flex;
    // flex-direction: column;
    
    .input-wrapper {
        // flex-grow:0;
        // flex-shrink:0;
    }
    .exists-wrapper {
        // flex-grow:1;
        // flex-shrink:1;
        // display: flex;
        // flex-direction: column;
        //overflow:auto;
        > :first-child {
            // flex-grow:0;
            // flex-shrink:0;
        }
    }

    //overflow: hidden;
    .exist-batches {
        // flex-grow:1;
        // flex-shrink:1;

        // display: flex;
        // flex-direction: column;
        // overflow: auto;

        > :first-child {
            max-height: calc(100vh - 302px);
            overflow: auto;
            // flex-grow:1;
            // flex-shrink:1;
    
            // display: flex;
            // flex-direction: column;
            // overflow: auto;
        }

        //margin-bottom: @gutter;

        .ui-list-item:not(:first-child) {
            border-top: 1px solid rgba(0,0,0,.1);
        }
    }
}
/* ./src/js/pages/product-production/batches/batches.less*/ 
.production-batches {
	.batch-card {
		.batch {
			.ui2-atom {
				> small {
					font-size: 12px;
				}
			}
		}
	}
}
/* ./src/js/pages/product-production/builds/build/build.less*/ 
.production-build-components {
	.component-item {
		.amount {
			width: 100px;
			> span {
				text-align:right;
				.value-postfix {
					width: 30px;
					display: inline-block;
					text-align: left;
				}
			}
		}
	}
}
/* ./src/js/pages/product-production/departments/department/department.less*/ 

.page-content {

	&.department {
		.content {
			padding: (@gutter / 2);
		}

	}

    > .production-department {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;

			&.sidebar-collapsed {
				.department-links-holder {
					width: 32px;
					.collapsable-block.collapsed {
						header {
							> *:not(.icon) {
								display: none;
							}
						}
					}
				}
			}

        > .department-links-holder {
            flex-grow: 0;
            flex-shrink: 0;
            width: 300px;
            .others-rule({
                margin-right: 20px;
            });
        }

        > .information {
            flex-grow: 1;
            flex-shrink: 1;
        }

        .mobile-rule({
            flex-wrap: wrap;
            > .information, > .department-links-holder {
                width: 100%;
                flex-grow: 1;
                flex-shrink: 1;    
            }
        });

        .three-columns {
            display: flex;
            flex-direction: row;
            .column {
                width: 100% / 3;
                min-width: 250px;
                //max-height: 70vw;
                //overflow: hidden;
                display: flex;
                flex-direction: column;
                > header {
                    flex-shrink: 0;
                    flex-grow: 0;
                    padding: (@gutter / 2) @gutter;
                    .ff-header();
                    font-size: 18px;
                    color: @clr-blue;
                }
                > .production-list {
                    flex-shrink: 1;
                    flex-grow: 1;
                    overflow: auto;
                }
            }

            .media-down(799px, {
                .column {
                    width: 50%;
                }
            });
            .media-down(548px, {
                .column {
                    width: 100%;
                }
            });
        }


        .production-department-product-card {
            .info .name {
                .ff-header();
            }

            .amounts {
                .ui2-atom {
                    > * {
                        text-align:center;
                    }
                    > span  {
                        .ff-header();
                        color: @clr-blue;
                    }
                }
            }
						.actions {
							.product-action-button-color(@bgclr, @bgclr-hover: darken(@bgclr, 10)) {
								background-color: @bgclr;
								&:Hover {
									background-color: @bgclr-hover;
								}
							}
							.product-action-button {
								padding: 5px;
								color: white;
								// background-color: cadetblue;
								line-height: 1;
								margin-right: 5px;
								&:Hover {
									cursor: pointer;
									// background-color: darken(cadetblue, 10);
								}

								&.produce {
									.product-action-button-color(cadetblue);
								}
								&.moveToStore {
									.product-action-button-color(#906090);
								}
								&.acceptAtStore {
									.product-action-button-color(darkgreen);
								}
							}
						}
        }



        .collapsable-block {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 200px);
            overflow: hidden;
            background-color: #f4f4f4;
            margin-bottom: @gutter;
            > header {
                flex-grow: 0;
                flex-shrink: 0;
                .ff-header();
                font-size: 24px;
                > .icon {
                    width: 30px;
                    &:Hover {
                        background-color: rgba(0,0,0,.1);
                        cursor: pointer;

                    }
                }
                > .name {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
                .header-view {
                    flex-grow: 0;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: row;
                    padding: 0 5px;
                    padding-top:8px;
                    button {
                        padding: 0 10px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border-bottom-width: 0px;
                        font-size: 14px;
                    }
                    > .active {
                        button {
                            background-color: @clr-blue;
                            color: white;
                            &:hover {
                                cursor: default;
                            }
                        }
                    }

                }

            }

            &:not(.collapsed) {

                > header {
                    border-bottom: 2px solid rgba(0,0,0,.1);
                    > .icon {
                            .icon('caret-up');
                    }
                }
            }

            > *:not(header) {
                flex-grow: 1;
                flex-shrink: 1;
                flex-wrap: wrap;
                overflow: auto;    
            }


            &.collapsed {
                > header > .icon {
                    .icon('caret-down');
                }
                > *:not(header) {
                    height: 0px;
                }
            }

            &.production {
                > header .icon {
                    width: 30px;
                    .icon('caret-up');
                    
                }
            }

        }


        .product-tree-entry {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
            > .production-department-product-card {
                position: relative;
                width: 175px;
                .info .name {
                    font-size: 11px;
                }
                .amounts {
                    .ui2-atom {
                        > * {
                            font-size: 10px;
                        }
                    }
                }
            }
            > .nesteds {
                margin-left: 15px;
            }

            .product-tree-entry {
                > .production-department-product-card {
                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 11px;
                        height: 4px;
                        left: -9px;
                        top: 12px;
                        background-color: @clr-blue;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    height: calc(100% + 15px);
                    width: 4px;
                    background-color: @clr-blue;
                    left: -13px;                    
                }

                &:first-child:not(:last-child):before {
                    height: calc(100% + 4px);
                    top: 12px;
                }

                &:last-child:before {
                    height: 16px;
                }

                &:last-child:first-child:before {
                    height: 4px;
                    top: 12px;
                }

                &:first-child:after {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 4px;
                    width: 20px;
                    background-color: @clr-blue;
                    left: -18px;
                    top: 12px;
                }

                > .nesteds {
                    position: relative;
                }
            }

        }


        .department-product-process, .department-product-cycle {
            .ff-header();
            > .wrapper {
                border: 1px solid rgba(0,0,0,.1);                
            }
            &.my {
                .ui2-block-bg-mixin(@clr-green);
            }
            .owner, .responsible {
                color: #777;
                flex-grow: 0;
            }
            .product {
                color: lighten(@clr-blue, 10);
            }
        }


        .links-list {
            .link-item {
                .ff-header();

                &:not(:first-child) {
                    border-top: 1px solid rgba(0,0,0,.1);
                }
                border-left: 6px solid rgba(0,0,0,.1);

                text-transform: uppercase;
                > div {
                    padding: 7.5px;
                    padding-left: 15px;
                }
                background-color:antiquewhite;
                color: @clr-blue;
                &:not(.active):Hover {
                    cursor: pointer;
                    background-color:darken(antiquewhite, 10);
                }

                &.active {
                    background-color: @clr-blue;
                    color: white;
                    border-left-color: rgba(0,0,0,.3);
                }

            }
        }


        .logs-transfer-block {

            .ui2-block-mixin();
            .ui2-block-bg-mixin(#f0f0f0);

            overflow: hidden;

            .filters-holder {
                flex-grow:0;
                flex-shrink:0;

                border-bottom: 1px solid rgba(0,0,0,.1);
                padding-bottom: 4px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                // > :first-child {
                //     flex-grow:1;
                //     flex-shrink:1;
                //     overflow: auto;
                // }
            }

            .filter-entry {
                display: flex;
                flex-direction: column;
                > label {
                    .ff-header();
                }
                > select {
                    padding: 7.5px;
                    border-radius: 3px;
                    .ff-header();
                    width: 100%;
                }
            }

            .filters-entries {
                // flex-grow:1;
                // flex-shrink:1;
                // overflow: auto;
                display: flex;
                flex-direction: row;
                .filter-entry {
                    margin: 4px;
                    select, input, .control-value {
                        font-size: 13px;
                        height: 34px;
                    }
                    .control-value {
                        .ff-header();
                        padding: 7.5px;
                        background-color: white;
                        border-radius: 3px;
                        border:1px solid #767676;
                        line-height: 1.2em;
                        cursor: pointer;
                    }
                }
            }


            .transaction-item {
                padding: 15px;
                background-color: white;
                .ff-header();
                font-size: 12px;
                &:not(:first-child) {
                    border-top: 1px solid rgba(0,0,0,.1);
                }
                > .date {
                    width: 100px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                > .prod {
                    width: 300px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    color: @clr-blue;
                    > span {
                        color: tomato;
                    }
                }
                > .move {
                    big {
                        color: cadetblue;
                    }
                }
                > .amount {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 150px;
                    text-align: right;
                }
                > .actor {
                    width: 150px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    text-align: right;
                }
            }

        }


        &.contragents {
            height: 100%;
        }



			.tab-btn {
				align-self: flex-end;
			}
			.search-by-name {
				position: relative;
				padding: 0px;
				margin: 4px @gutter;
				i {
					font-style: normal;
					.icon('search');
					position: absolute;
					left: 5px;
					top: 8px;
					font-size: 14px;
					color: cadetblue;
				}

				input {
					padding: 0 5px;
					padding-left: 25px;
					font-size: 14px;
					height: unset;
				}
			}
    }
}
/* ./src/js/pages/product-production/departments/department/new-layout/layout.less*/ 
.page-content.page-with-divisions.department {

	.page-with-divisions_content_container {

		@hght: 30px;

		.products-header {
			display: flex;
			flex-direction: row;
			padding: (@gutter / 2);
			flex-grow: 0;
			flex-shrink: 0;
			flex-wrap: wrap;
			> * {
				margin: 5px;
				height: 30px;
			}




			.search-by-name {
				

				position: relative;
				// padding: 0px;
				// margin: 4px @gutter;
				i {
					position: absolute;
					height: @hght;
					width: 30px;
					left: 0;
					top: 0;
					font-style: normal;
					.icon('search');
					// left: 5px;
					// top: 8px;
					font-size: 18px;
					line-height: @hght;
					color: cadetblue;
				}
				input {
					padding:0;
					padding-left: 30px;
					height: @hght;
				}
				// input {
				// 	padding: 0 5px;
				// 	padding-left: 25px;
				// 	font-size: 14px;
				// 	height: unset;
				// }
			}

		}
		.content {
			// padding: @gutter;
			// padding-bottom: 50px;
		}
	}

	.production-department-product-card {
		.wrapper {
			border: 3px solid transparent;
		}

		&.input {
			.wrapper {
				border-color: bisque;
			}
		}

		&.output {
			.wrapper {
				border-color: skyblue;
			}
		}


		.info .name {
				.ff-header();
		}

		.amounts {
				.ui2-atom {
						> * {
								text-align:center;
						}
						> span  {
								.ff-header();
								color: @clr-blue;
						}
				}
		}
		.actions {
			.product-action-button-color(@bgclr, @bgclr-hover: darken(@bgclr, 10)) {
				background-color: @bgclr;
				&:Hover {
					background-color: @bgclr-hover;
				}
			}
			.product-action-button {
				padding: 5px;
				color: white;
				// background-color: cadetblue;
				line-height: 1;
				margin-right: 5px;
				&:Hover {
					cursor: pointer;
					// background-color: darken(cadetblue, 10);
				}

				&.produce {
					.product-action-button-color(cadetblue);
				}
				&.moveToStore {
					.product-action-button-color(#906090);
				}
				&.acceptAtStore {
					.product-action-button-color(darkgreen);
				}
			}
		}

		.wrapper {
			&:Hover {

				border-color: @clr-main-back;
			}
		}

		// &:Hover {
		// 	.wrapper {
		// 		background-color: inherit;
		// 	}
		// }
	}


	.department-product-process, .department-product-cycle  {
		.ui2-atom {
			> span {
				.ff-header();
			}
		}
		.owner {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 200px;
			span {
				color: brown;
			}
		}
		.product {
			span {
				color: darkcyan;
			}
		}
		.process {
			.name {
				span {
					color: #444;
				}
			}
		}
	}

	.department-contragent {
		.ui2-atom {
			> span {
				.ff-header();
			}
		}
	}


	.period-select-view {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
		button {
			font-size: 24px;
			width: 300px;
			margin: (@gutter / 2);
		}
	}

}




.tabs-buttons {
	&.inline {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		border-bottom: 2px solid @clr-main-back;
		height: 30px;
		> * {
			margin: 0 2px;
		}
		.tab-btn:not(.active) {
			button {
				background-color: transparent;
				border-color: transparent;
				&:hover {
					cursor: pointer;
					background-color: #00000010;
				}
			}
		}

	}

	.tab-btn {
		button {
			padding: (@gutter / 2) 5px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		line-height: 1;
		* {
			line-height: 1;
		}
		&.active {
			button {
				background-color: @clr-main-back;
				color: white;
	
			}
		}
	}
}

/* ./src/js/pages/product-production/departments/department/new-layout/production-log/production-log-entry.less*/ 
.printable-table.full-screen {
	.production-log-entry {
		
		.ui2-molecule {
			.table-cell {

					width: unset;
					min-width: unset;

					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: 20%;

					&.created, &.actor, &.amount {
						flex-basis: 5%;
					}

					&.amount.amount.amount {
						b { display: none; }
					}
			}
		}





	}
}
/* ./src/js/pages/product-production/departments/department/new-layout/products/layout.less*/ 
.page-content.page-with-divisions.department {



	&.production {
		.three-columns {
			display: flex;
			flex-direction: row;

			flex-grow: 1;
			flex-shrink: 1;
			overflow: auto;

			.column {
					flex-basis: 100% / 3;
					margin: 0 10px;
					// width: 100% / 3;
					min-width: 250px;
					//max-height: 70vw;
					//overflow: hidden;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					flex-shrink: 1;
					overflow: auto;

					> header {
							flex-shrink: 0;
							flex-grow: 0;
							padding: (@gutter / 2) @gutter;
							.ff-header();
							font-size: 18px;
							color: @clr-blue;
					}
					> .production-list {
							flex-shrink: 1;
							flex-grow: 1;
							overflow: auto;
					}
			}

			.media-down(799px, {
					.column {
							width: 50%;
					}
			});
			.media-down(548px, {
					.column {
							width: 100%;
					}
			});
		}

		.product-tree-entry {
			position: relative;
			display: flex;
			flex-direction: row;
			margin-bottom: 15px;
			> .production-department-product-card {
					position: relative;
					min-width: 250px;
					// width: 175px;
					// .info .name {
					// 		font-size: 11px;
					// }
					.amounts {
							.ui2-atom {
									> * {
											font-size: 10px;
									}
							}
					}
			}
			> .nesteds {
					margin-left: 15px;
			}

			.product-tree-entry {
					> .production-department-product-card {
							&:before {
									content: '';
									position: absolute;
									display: block;
									width: 11px;
									height: 4px;
									left: -9px;
									top: 12px;
									background-color: @clr-blue;
							}
					}
					&:before {
							content: '';
							position: absolute;
							display: block;
							height: calc(100% + 15px);
							width: 4px;
							background-color: @clr-blue;
							left: -13px;                    
					}

					&:first-child:not(:last-child):before {
							height: calc(100% + 4px);
							top: 12px;
					}

					&:last-child:before {
							height: 16px;
					}

					&:last-child:first-child:before {
							height: 4px;
							top: 12px;
					}

					&:first-child:after {
							content: '';
							position: absolute;
							display: block;
							height: 4px;
							width: 20px;
							background-color: @clr-blue;
							left: -18px;
							top: 12px;
					}

					> .nesteds {
							position: relative;
					}
			}

		}

	}

}
/* ./src/js/pages/product-production/departments/department/new-layout/report-entry.less*/ 
.product-before-after-report-entry {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	.ui2-atom {
		> span {
			.ff-header();
		}
	}


	&.ui2-block.with-wrapper {
		.wrapper {
			box-shadow: 0 0 5px 2px #00000010;
			border: 1px solid #00000020;
			flex-wrap: nowrap;
		}
	}


	.ui2-molecule {
		flex-wrap: nowrap;
		align-items: stretch;
		.table-cell {
			&.created, &.arrow, &.source, &.destination, &.actor, &.amount  {
				flex-grow: 0;
				flex-shrink: 0;
			}
			&.product {
				width: 300px;
				> span {
					color: darkslateblue;
				}
			}
			&.amount {
				width: 110px;
				text-align:center;
				> span {
					//text-align:right;
					font-size: 18px;
				}
				small.unit {
					font-weight: 400;
					font-size: 60%;
				}
				// > i, 
				// > b {
				// 	color: #777;
				// }
			}
			&.source-destination {
				width: 350px;
				// flex-shrink: 0;
				.fa.fa-arrow-right {
					margin: 4px;
				}
				.store {
					color: brown;
				}
				.contragent {
					color: darkolivegreen;
				}
				> small {
					color: tomato;
					font-size: 16px;
					font-style: italic;
				}

			}
			&:not(.fixed):not(.amount) {
				min-width: 200px;
			}
			&.actor {
				width: 150px;
				min-width: unset
			}

		}

	}

	.ui2-atom {
		align-items: stretch;
		> span {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		&.amount {
			padding-top: 0;
			padding-bottom: 0;
			> i, > b {
				min-height: 13px;
			}
			> span {
				justify-content: center;
				min-height: 21px;
			}
		}
	}

}



.printable-table.full-screen {
	.product-before-after-report-entry {
		
		.ui2-molecule {
			.table-cell {

					width: unset;
					min-width: unset;

					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: 20%;

					&.created, &.actor, &.amount {
						flex-basis: 5%;
					}

					&.amount.amount.amount {
						b, i { display: none; }
					}
			}
		}





	}
}





.strict-table {
	border-spacing: 0;
	.table-cell {
		padding: 0;
		// padding: 7.5px;

		border: 3px solid transparent;

		.ff-header();
		> * {
			display: block;
			padding-left: (@gutter / 2);
			padding-right: (@gutter / 2);
		}
		
	}
	tr {
		// background-color: white;
		&:not(.table-headers) {

			// position: relative;

			.table-cell {
				> i, > b {
					font-style: normal;
					font-weight: 500;
					.ff-header();
					font-size: 10px;
				}

				&:not(.product) {
					> * {
						white-space: nowrap;
					}
				}
				> span {
					line-height: 1;
					padding-bottom: (@gutter / 2);
				}
				&.amount {
					vertical-align: middle;
					> i, > b, > small {
						display: none;
					}
					> span {
						font-weight: 400;
						text-align: right;
						padding-bottom: 0;
						small.unit {
							font-size: 60%;
							color: #777;
						}
					}
				}
				&.product {
					min-width: 250px;
					> i {
						// color: @clr-main-back;
					}
					> span {
						// padding-top: 7.5px;
						// padding-bottom: 5px;
						line-height: .8;
					}
				}
			}

			&.notUsed .table-cell.product {
				color: #777;
			}

			&.input .table-cell.product {
				color: #000;
			}

			&.intermediate .table-cell.product {
				color: darkcyan;
			}

			&.output .table-cell.product {
				color: @clr-main-back;
			}

			// &:Hover {
			// 	background-color: antiquewhite;
			// 	td {
			// 		color: cornflowerblue;
			// 	}
			// }

		}
		&.table-headers {
			.table-cell {
				position: sticky;
				top: 0;
				color: #777;
				font-size: 12px;
				vertical-align: middle;

				&:not(.product) {
					text-align: center;
				}
				
				&.table-cell.table-cell {
					background-color: @clr-main-back;
				}
				color: white;
				> * {
					color: white;
				}
			}
		}
	}
}

:not(.printable-table) .strict-table {

	@bg: rgb(224,235,244);

	.table-cell {
		border-color: @bg;
		background-color: white;
	}

	.table-cell.product {
		border-right-width: 10px;
	}

	.table-cell:not(.increment):not(.decrement) + .table-cell.increment, 
	.table-cell.increment + .table-cell.decrement, 
	.table-cell.decrement + .table-cell:not(.increment):not(.decrement) {
		border-left-width: 10px;
	}

	.table-cell.increment {
		background-color: #f9fff9;
	}
	.table-cell.decrement {
		background-color: #fff9f9;
	}

	tr:not(.table-headers):hover {
		td {
			background-color: #f4f4ff;
			cursor: default;
		}
		outline: solid 1px #444;
	}

}
/* ./src/js/pages/product-production/departments/department/new-layout/sources-report/sources-report-entry.less*/ 

/* ./src/js/pages/product-production/production-log-entry.less*/ 
.production-log-entry {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	.ui2-atom {
		> span {
			.ff-header();
		}
	}


	&.ui2-block.with-wrapper {
		.wrapper {
			box-shadow: 0 0 5px 2px #00000010;
			border: 1px solid #00000020;
			flex-wrap: nowrap;
		}
	}

	.ui2-molecule {
		flex-wrap: nowrap;
		align-items: center;
		.table-cell {
			&.created, &.arrow, &.source, &.destination, &.actor, &.amount  {
				flex-grow: 0;
				flex-shrink: 0;
			}
			&.product {
				width: 300px;
				> span {
					color: darkslateblue;
				}
			}
			&.amount {
				width: 200px;
				text-align:center;
				> span {
					//text-align:right;
					font-size: 18px;
				}
				// > i, 
				// > b {
				// 	color: #777;
				// }
			}
			&.source-destination {
				width: 350px;
				// flex-shrink: 0;
				.fa.fa-arrow-right {
					margin: 4px;
				}
				.store {
					color: brown;
				}
				.contragent {
					color: darkolivegreen;
				}
				> small {
					color: tomato;
					font-size: 16px;
					font-style: italic;
				}

			}
			&:not(.fixed) {
				min-width: 200px;
			}
			&.actor {
				width: 150px;
				min-width: unset
			}

		}

	}

	.type-icon {
		flex-grow: 0;
		flex-shrink: 0;
		i {
			display: block;
			font-style: normal;
			font-size: 18px;
			color: #aaa;
			text-align:center;
			width: 30px;
		}
	}

	@clr-in: seagreen;
	@clr-out: cornflowerblue;

	&.purchaseAccept {
		.wrapper {
			background-color: lighten(greenyellow, 38);
		}
		.type-icon i {
			.icon('truck');
			color: @clr-in;
		}
	}

	&.produced {
		.wrapper {
			background-color: lighten(#906090, 35);
		}
		.type-icon i {
			.icon('wrench');
			color: #906090;
			color: @clr-in;
		}
	}

	&.defect {
		.wrapper {
			background-color: lighten(tomato, 28);
		}
		.type-icon i {
			.icon('exclamation-triangle');
		}
	}

	&.issueForProduction {
		.wrapper {
			background-color: white;
		}	
		.type-icon i {
			.icon('sign-out');
			color: @clr-out;
		}
	}

	&.revokeFromProduction {
		
		.wrapper {
			background-color: cornsilk;
		}	
		.type-icon i {
			.icon('sign-in');
			transform: rotate(180deg);
			color: @clr-in;
		}
	}
	
	&.usedForProduction {
		.wrapper {
			background-color: lighten(#906090, 48);
		}		
		.type-icon i {
			.icon('cogs');
		}
	}
	&.manualCorrection {
		.wrapper {
			background-color: darksalmon;
		}
		.type-icon i {
			.icon('magic');
			color: white;
		}
		&.increment {
			.type-icon i {
			color: @clr-in;
			}
		}
		&.decrement {
			.type-icon i {
			color: @clr-out;
			}
		}
	}
}
/* ./src/js/pages/product-production/production.less*/ 
.production-trees {
	.production-tree {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		padding: 15px 0;
		border-top: 1px solid rgba(0,0,0,.1);
		&:first-child {
			border-top-color: transparent;
		}
	
		.product {
			margin: 5px;
		}

		.product-cycles-list {
			margin: 5px;

			.product-cycle-info {
				.line-1 {
					display: flex;
					flex-wrap: nowrap;
					> span {
						white-space: nowrap;
					}
				}
			}

		}

		.product-cycle-item {
			display: flex;
			flex-direction: column;
			position: relative;
			> header {
				.ff-header();
				display: inline-block;
				font-size: 12px;
				text-transform: uppercase;
				margin:5px;
				> div {
					position: relative;
					margin-left:5px;
					background-color: @clr-blue;
					box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
					border-radius: 4px;
					color: white;
					display: inline-block;
					&:before {
						position: absolute;
						content: '';
						background-color: @clr-blue;
						height: 2px;
						width: 12.5px;
						left: -12.5px;
						top: 20px;
					}
					&:after {
						position: absolute;
						content: '';
						background-color: @clr-blue;
						width: 2px;
						height: 15px;
						left: 20px;
						bottom: -15px;
					}

					> span:not(:first-child) {
						margin-left: 10px;
					}
					.responsible {
						color: bisque;
						text-decoration: underline;
					}
				}
			}


			.product-cycle-info {
				position: relative;
				> div {
					position: relative;
					> * {
						position: relative;
						display: inline-block;
						padding: 7.5px;
						> b {
							position: absolute;
							text-transform: uppercase;
							color: aquamarine;
							font-size: 10px;
							left: 7.5px;
							bottom: -1px;							
						}
					}
					&:not(:first-child) {
						border-top: 1px solid rgba(255,255,255,.1);
					}
				}
				.start-proc {
					background-color: transparent;
					border: none;
					color: lightgreen;
					&:Hover {
						background-color: rgba(0,0,0,.1);
					}
				}
			}

			&:not(:last-child):before {
				content: '';
				position: absolute;
				width: 2px;
				height: calc(100% + 2px);
				background-color: @clr-blue;
				top: 25px;
				left: -4px;
			}

		}

		.cycle-components-item {
			position: relative;
			display: flex;
			flex-direction: row;
			&:not(:last-child):after {
				position: absolute;
				content: '';
				background-color: @clr-blue;
				width: 2px;
				height: calc(100% - 69px);
				left: 30px;
				bottom: -10px;
			}
		}

		.product-info {
			position: relative;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
			background-color: white;
			.ff-header();
			font-weight: bold;
			//padding: 15px;
			margin: 5px;
			border-radius: 4px;

			&:after {
				position: absolute;
				content: '';
				background-color: @clr-blue;
				height: 2px;
				width: 12.5px;
				top: 20px;
				right: -12.5px;
			}

			> div {
				position: relative;
				> span {
					position: relative;
					display: inline-block;
					padding: 7.5px;
					> b {
						position: absolute;
						text-transform: uppercase;
						color: @clr-blue;
						font-size: 10px;
						left: 7.5px;
						bottom: -1px;
					}
				}
			}

			.product-name-line {
				display: flex;
				flex-wrap: nowrap;
				> span {
					white-space: nowrap;
				}
			}

			.amounts {
				display: flex;
				flex-wrap: nowrap;
				> span {
					white-space: nowrap;
					&:not(:first-child) {
						border-left: 1px solid rgba(0,0,0,.1);
					}
				}
			}

			> * {
				&:not(:first-child) {
					border-top: 1px solid rgba(0,0,0,.1);
				}
			}

		}

		.emptyview {
			position: relative;
			margin: 5px;
			p {
				margin: 0;
			}
			.no-production-cycles {
				background-color: beige;
				padding: 15px;
				.ff-header();
				font-size: 12px;
				font-weight: bold;
				color: darkcyan;
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				border-radius: 4px;
			}
			.origin-purchase {
				position: relative;
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				background-color: white;
				> i {
					.icon('truck');
				}
				padding: 15px;
				color: brown;
				border-radius: 4px;
				line-height: 1.2;
				// &:before {
				// 	position: absolute;
				// 	content: '';
				// 	background-color: @clr-blue;
				// 	height: 2px;
				// 	width: 20px;
				// 	left: -20px;
				// 	top: 23px;
				// }
			}
			.request-api {
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				background-color: white;
				i { .icon('question'); }
				padding: 15px;
				color: @clr-blue;
				border-radius: 4px;
				&:hover {
					background-color: beige;
					cursor: pointer;
				}
			}
			&.fetching .request-api {
				i { 
					.icon('cog');
					animation: fa-spin 2s infinite linear;
				}
			}
			&:before {
				position: absolute;
				content: '';
				background-color: @clr-blue;
				height: 2px;
				width: 12.5px;
				top: 20px;
				left: -12.5px;
			}
		}


		.open-card-button {
			position: relative;
			user-select: none;
			opacity: .3;
			&:first-child {
				border-top-left-radius: 3px;
			}
			&:last-child {
				border-top-right-radius: 3px;
			}
			> i {
				position: relative;
				.icon('eye');
			}
			&:Hover {
				cursor: pointer;
				opacity: 1;
				background-color: rgba(0,0,0,.1);
			}
			&:active i {
				top: 1px;
			}
		}


		.no-products-in-cycle {
			margin: 5px;
			> div {
				box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
				padding: 15px;
				margin: 5px;
				.ff-header();
				border-radius: 4px;
				font-size: 12px;
				background-color: beige;
				color: darkcyan;
			}
		}

	}
}



.production-manage-section {
	display: flex;


	.input-with-checkboxes {
		position: relative;
		background-color: white;
		border-radius: 3px;
		border: 2px solid rgba(0,0,0,.2);
		.input-holder {
			position: relative;
			input {
				border: none;
				background-color: transparent;
				width: 300px;
				padding-bottom: 20px;
			}
		}
		.checkboxes-holder {
			position: absolute;
			bottom: 2px;
			font-size: 12px;
			display: flex;
			label {
				display: inline-block;
				vertical-align: middle;
				display: flex;
				padding: 0 5px;
				input {
					margin: 0;
					display: inline-block;
					margin-right: 5px;
				}
			}
		}
	}

}
/* ./src/js/pages/resources/production/products/product/prod-cycle/prod-cycles.less*/ 

.product-prod-cycles-block {



	.product-cycle-source-item {
		&:not(:first-child) {
			border-top: 1px solid rgba(0,0,0,.1);
		}
		.open-card i {
			.icon('eye');
		}
		&.optional {
			background-color: #ABCDEF40;
			button:not(:hover) {
				background-color: transparent;
			}
		}
		&.footprintless {
			background-color: fade(darkseagreen, 10);
			button:not(:hover) {
				background-color: transparent;
			}

		}
		> div:not(.ui-list-item-main) {
			flex-grow: 0;
			flex-shrink:0;
			//width: 120px;
		}
		> div.needed {
			width: 130px;
		}

		> div.enough {
			width: 100px;
		}
	}

}


.product-prod-cycle-item {
	border-left: 4px solid transparent;

	.list-icon i {
		.icon('edit');
	}

	.start i {
		.icon('play');
		color: darkgreen;
	}
	.stop i {
		.icon('pause');
		color: tomato;
	}

	.delete i {
		.icon('trash');
		color: red;
	}

	.open-page i {
		.icon('eye');
	}

	&:not(:first-child) {
		border-top: 1px solid rgba(0,0,0,.1);			
	}

	&.started {
		@CL: lighten(darkseagreen, 25);
		border-left-color: darkseagreen;
		background-color: @CL;
		button {
			background-color: @CL;
		}
	}

}


// //
/* ./src/js/pages/resources/production/products/product/product-page.less*/ 
.product-page-view {
	.last-date {
		&.clickable {
			&:Hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.1);
			}
		}
	}

	.product-properties-box {
		&.abstract-group {
			background-color: fade(#906090, 40);
		}
		&.archived {
			background-color: fade(tomato, 10);
			.isArchived.card-line {
				background-color: fade(tomato, 10);
				span {
					color: tomato;
				}
			}
		}
		&.deleted {
			background-color: fade(black, 10);
			.isDeleted.card-line {
				background-color: fade(black, 10);
				span {
					color: black;
				}
			}
		}

	}

}

.yat-modal-content .contragent-price-changes {
	padding-bottom: 30px;
	.checkbox-list {
		margin: 15px 0;
	}
	header {
		.ff-header();
		font-size: 24px;
		height: 30px;
		line-height: 30px;
		margin-bottom: 15px;
	}
}

/* ./src/js/pages/resources/production/products/product/store/logs.less*/ 
.product-store-block {
	.store-transaction-list {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
		.transaction-entry {
			padding:4px;
			&.plus {
				background-color: fade(lightgreen, 10);
				.amount {
					color: green;
				}
			}
			&.minus {
				background-color: fade(tomato, 10);
				.amount {
					color: red;
				}
			}

			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}

			.ff-header();
			.created {
				flex-grow: 0;
				flex-shrink: 0;

				color: #555;
				font-size: 12px;
				width: 70px;
				> span:first-child {
					padding-bottom: 0;
				}
				> span:last-child {
					padding-top: 0;
				}
			}
			.amount {
				flex-grow: 0;
				flex-shrink: 0;

				width: 100px;
				overflow: hidden;
				> small {
					display: block;
					font-size: 10px;
					padding-top: 0;
				}
				> span {
					display: block;
					padding-bottom: 0;
				}
			}
			.types {
				color: #555;
				font-size: 12px;
				> span:first-child {
					padding-bottom: 0;
				}
				> span:last-child {
					padding-top: 0;
				}
			}
			.batch {
				color: #555;
				font-size: 12px;
				flex-grow: 2;
				flex-shrink: 2;
				display: flex;
				flex-direction: column;
				> span {
					padding-top:0;
					padding-bottom: 0;
				}
				.id {
					color: royalblue;
				}
				.comment {
					color: olivedrab;
					font-style: italic;
				}
			}
			.store {
				display: flex;
				flex-direction: column;
				.actor {
					font-size: 12px;
					color: #555;
					padding-bottom: 0;
					text-align:right;
				}
				.ballance {
					padding-top: 0;
					color: #906090;
					text-align:right;
				}
			}
		}
	}
}

/* ./src/js/pages/resources/production/products/product/store/parties.less*/ 

// .parties-list {
// 	.party-item {
// 		// display: flex;
// 		// flex-direction: row;
// 	}
// }


.product-store-block {
	.content {
		display: flex;
		flex-direction: column;

		// header {
		// 	border-bottom: 1px solid rgba(0,0,0,.1);
		// }
		// footer {
		// 	border-top: 1px solid rgba(0,0,0,.1);
		// }






		> *:not(.parties-list) {
			flex-grow: 0;
			flex-shrink: 0;
		}
		> .parties-list {
			flex-grow: 1;
			flex-shrink: 1;
		}

	}

	.list-header {
		display: flex;
		flex-direction: row;
		padding: 0;
		background-color: rgba(0,0,0,.1);		
		> * {
			flex-grow: 1;
			flex-shrink: 1;
			box-sizing: border-box;
			padding:0;
			padding-left: (@gutter / 2);
			padding-right: 3px;
			margin: 0;
			&:first-child  {
				max-width: calc((100% - 17px) * .4);
			}
			.ff-header();
			&:Hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.2);
			}
		}
	}

	.elastic-content {
		.ui-list-item.party-item {
			> div:first-child {
				max-width: 40%;
			}
			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			.increase > i {
				.icon('plus');
				color: darkgreen;
			}
			.decrease > i {
				.icon('minus');
				color: tomato;
			}
		}

	}

	.ui-list-item.party-item.total-amount {
		display: flex;
		flex-direction: row;
		padding-left: 0;
		background-color: rgba(0, 0, 255, 0.05);
		> *:first-child  {
			max-width: calc((100% - 17px) * .4);
		}

		button.transparent {
			background-color: transparent;
			white-space:nowrap;
			&:hover {
				background-color: rgba(0,0,0,.1);
			}
		}

	}

}




/* ./src/js/pages/staff/emps/staff-emps.less*/ 
/*
.staff-emps{
	height:100%;
	.content-region{
		height:100%;
	}
}

.employee-list.flex-dynamic-column
{
	> * {
		min-width:300px;
		margin:(@gutter / 2)!important;
	}
}
*/

/* ./src/js/pages/store/edit-amounts/edit-amount.less*/ 
//@import '~less/include.less';

.page-content > .products-edit-amounts:first-child {
    max-width: unset;
    margin: -15px;
    overflow: auto;
    height: calc(100% + 30px);
    width: calc(100% + 30px);
}
.products-edit-amounts {

    display: flex;
    flex-direction: column;
    overflow: hidden;

    >:not(.production-container) {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .production-container {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
    }


    .product-line-item {

        position: relative;
        > .product-info {

            padding: (@gutter / 2) 0;

            align-items: baseline;
            flex-wrap: wrap;
            //margin: 1px 0;
            &:Hover {
                background-color: rgba(0,0,80,.1);
            }
            > button {
                border-color: transparent;
                background-color: unset;
                &:Hover {
                    cursor: pointer;
                    background-color: rgba(0,0,0,.1);
                }
            }
            .icon {
                //width: 20px;
                i {
                    display: block;
                    width: 20px;
                    text-align:center;
                }
            }
            .main {
                div {
                    color: darken(@clr-main-bg, 20);
                    font-size: 20px;
                }
            }
            .open-page {
                button {
                    background-color: transparent;
                    border-color: transparent;
                    font-size: 18px;
                    &:Hover {
                        background-color:#00000010;
                        cursor: pointer;
                    }
                }
            }
        }

        &.origin-purchase .icon.origin i {
            .icon('truck');
            color: tomato;
        }
        &.origin-production .icon.origin i {
            .icon-b('industry');
            color: darkolivegreen;
        }

        &:not(.collapsed) .icon.caret i {
            .icon-b('caret-down');
        }

        &.purpose-sale .icon.purpose i {
            .icon-b('money');
            color: orange;
        }
        &.purpose-production .icon.purpose i {
            .icon-b('industry');
            color: darkolivegreen;
        }

        .ui2-atom {
            > div {
                .ff-header();
            }
        }

        &:not(:first-child) {
            //margin-top:1px;
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px dotted black;
            }
        }


        .batches-count {
            span:first-child {
                margin: 0 5px;
            }
        }

        .batches-container {
            padding-left: 60px;
            display: flex;
            flex-direction: row;
            .batches-lines {
                display: flex;
                flex-direction: column;
            }

            .batch-line-item {
                position: relative;
                width: unset;
                padding: (@gutter / 2) 0;
                align-items: center;
                &:not(:first-child) {
                    border-top: 1px solid rgba(0,0,0,.1);
                    //margin-top:1px;
                    // &::before {
                    //     content: '';
                    //     display: block;
                    //     width: 100%;
                    //     height: 1px;
                    //     border-top: 1px dotted black;
                    // }
                }

                .identifier {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 250px;
                    .batch-identifier {
                        white-space: pre-wrap;
                    }
                }
                .amount {
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 150px;
                    text-align:right;
                }
                .created {
                    width: 70px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                .icon  {
                    width: 40px;
                    text-align:center;
                }

                .newamount-delta {
                    width: 100px;
                    flex-grow: 0;
                    flex-grow: 0;
                }

                .user-input {
                    position: relative;
                    .refresh-timer {
                        position: absolute;
                        //right: -20px;
                        font-size: 20px;
                        padding: 13.25px;
                        top: 0;
                        right: 0;
                        color: #ccc;
                    }
                }
            }

        }


        &.collapsed {
            .icon.caret i {
                .icon-b('caret-right');
            }
            .batches-container {
                display: none;
            }
        }
    }


    .actions-container {
        padding: @gutter;
        background-color: white;

        > .actions-items {
            > *:not(:first-child) {
                margin-left: 5px;
            }
        }

        .filter-set-item {
            border-radius: 4px;
            border: 2px solid transparent;
            &.active{
                &:Hover {
                    background-color: unset;
                    cursor: pointer;
                }
                border: 2px solid @clr-main-back;
            }
        }   

        // button:not(:first-child) {
        //     margin-left: (@gutter / 2);
        // }
    }

    .filters-container {
        padding: @gutter;
        background-color: white;


        .filtered-amount {
            span {
                height: 28px;
                line-height: 28px;
            }
        }

    }

}
/* ./src/js/pages/tasks/manager/tasks-switcher.less*/ 
.entries-manager .card.lined.switcher{
	flex-flow: row;
	.line {
		align-items: center;
		width: auto;
		flex-grow: 1;		
		&:not(:first-child){
			border-left:1px solid rgba(0,0,0,.1);
		}
		> div {
			padding: 0 4px;
			> span {
				text-align:center;
			}
		}
		&.active{
			background-color: @clr-main-bg;
			> div {
				> i { color: darkorange; }
				> span { color: #fff; }
			}
		}
		> button {
			//width: 100%;
			height: 100%;
		}

	}



}
.entries-manager .card.lined.switcher.tasks-switcher {
	&.responsible .line[data-id=responsible], 
	&.checker .line[data-id=checker], 
	&.creator .line[data-id=creator], 
	&.observer .line[data-id=observer], 
	&.all .line[data-id=all] {
		background-color:#906090;
		> div {
			> i { color: darkorange; }
			> span { color: #fff; }
		}		
	}
}

.entries-manager .card.lined.switcher.state-switcher {
	&.active .line[data-id=active], &.completed .line[data-id=completed] {
		background-color: @clr-main-bg;
		> div {
			> i { color: darkorange; }
			> span { color: #fff; }
		}		
	}
}



.entries-manager .card.lined.switcher.date-switcher {
	&.no-controls .line.controls { display:none }
	&.no-backward .line.controls > button.backward { display: none }
	.line.controls {
		flex-grow:0;
		flex-basis:50px;
		> button {
			width: 100%;
			//height: 100%;
		}		
		> button.forward > i { .icon('chevron-right'); }
		> button.backward > i { .icon('chevron-left'); }		
	}
	.line > button.edit > i { .icon('sliders'); }
}

/* ./src/js/pages/tasks/task/task.less*/ 
.tasks-task {
	.card.lined .toDo.card-line {
		> div > span {
			font-size:24px;
			color:teal;
		}
	}
	.card.lined .datesDeadline.card-line {
		&.past > div > span {
			color:tomato;
		}
		&.near > div > span {
			color:teal;
		}
		> div > span {
			font-size:24px;
		}
	}
	
	.card.lined .state.card-line {
		&.closed.canceled, &.closed.failed {
			> div > * {
				color: tomato!important;
			}
		}
		&.closed.succeeded {
			> div > * {
				color: teal!important;
			}
		}
		&.onHold {
			> div > * {
				color: darkorange!important;
			}
		}		
	}

}


.transportrequest-container.card.lined.with-border {
	> .transportpoints {
		flex-direction: column;
		> *  + * {
			border-top: 3px solid rgba(0,0,0,.1);
		}
	}
}


.tasks-list {
	.task.line {
		width: 100%;
	}
}
/* ./src/js/pages/tasks/tasks-list.less*/ 
//.page-content > .fdc-container:first-child 
#tasks-list {

	&.fixed-manager {
		> .entries-manager {
			position: fixed;
		}
		> .line.task {
			margin-left: 350px!important;
		}
	}

}

//.page-content > .fdc-container:first-child .flex-dynamic-column > *
/* ./src/js/pages/test.less*/ 
.test-container{
	height:100%;
	.left, .right{
		width:40%;
		float:left;
		height:100%;
		overflow:auto;
		padding:30px;
	}

	.clearfix();
	
}

// ssss
/* ./src/js/pages/view-with-menu.less*/ 
.page-with-menu.flex-dynamic-column {
	.menu-aside {
		.menu-block  {
			.ui-list-item {
				&.active {
					background-color:#337ab7;
					* {
						color: white;
					}
				}
			}
		}
		> * {
			margin-bottom: 15px!important;
		}
	}
}

/* ./src/js/pages/_old_store/store-module.less*/ 
